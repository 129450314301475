import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import { StyledButtonContainer } from "index.styles";
import React from "react";
import HeaderContainer from "styles/components/headerContainer";
import useMediaQueries from "utils/mediaQueries.utils";

const DefaultHeader = ({ lightText, header, headerAccent, headerTagline, headerTaglineFontSize, content, cta }) => {
  const { isLg } = useMediaQueries();
  return (
    <>
      <HeaderContainer
        textColor={lightText ? "#fff" : "#092860"}
        highlightColor="#75CDC5"
        element="h1"
        variant="h1"
        title={header}
        highlightText={headerAccent}
        cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
        newLine={true}
      />
      <MKTypography
        sx={{
          minWidth: { xs: "100%", lg: "800px" },
          maxWidth: "800px",
          margin: "auto",
          color: lightText ? "#fff !important" : `${theme.palette.primary.main} !important`,
          fontSize: headerTaglineFontSize || "16px",
        }}
      >
        <em>{headerTagline}</em>
      </MKTypography>

      <MKTypography
        component="p"
        variant={isLg ? "body1" : "body2"}
        sx={{
          maxWidth: "800px",
          margin: "auto",
          color: lightText ? "#fff !important" : `${theme.palette.primary.main} !important`,
          // fontSize: headerTaglineFontSize || "16px",
        }}
      >
        {content}
      </MKTypography>
      {cta && <StyledButtonContainer>{cta}</StyledButtonContainer>}
    </>
  );
};

export default DefaultHeader;
