import React from "react";
import { Link } from "@mui/material";

export const replaceKeywordWithLink = (text, keywordOrKeywords, url) => {
  if (!keywordOrKeywords) return text;

  let keywordsArray = [];

  if (Array.isArray(keywordOrKeywords)) {
    keywordsArray = keywordOrKeywords;
  } else if (typeof keywordOrKeywords === "string" && url) {
    keywordsArray = [{ keyword: keywordOrKeywords, embeddedLink: url }];
  } else {
    return text;
  }

  const pattern = keywordsArray.map(({ keyword }) => `(${keyword})`).join("|");
  const regex = new RegExp(pattern, "gi");
  return text.split(regex).map((part, index) => {
    if (part && typeof part === "string") {
      const matchedKeyword = keywordsArray.find(({ keyword }) => keyword.toLowerCase() === part.toLowerCase());
      return matchedKeyword ? (
        <Link
          key={`${matchedKeyword.keyword}-${index}`}
          target="_blank"
          rel="noreferrer"
          href={matchedKeyword.embeddedLink}
          style={{ color: matchedKeyword.linkColor ? matchedKeyword.linkColor : "inherit" }}
        >
          {part}
        </Link>
      ) : (
        part
      );
    }
    return part;
  });
};
