import React, { useRef, useState, useEffect, useContext } from "react";
import { Grid, Box } from "@mui/material";
// Styled Components
import { StyledPaginationSectionContainer, StyledProfileCardsSectionContainer } from "./index.styles";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Components
import ProfileCard from "../profileCard";
import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";
import PageHeader from "components/CBPageHeader";
import CBLoading from "components/CBLoading";
import TAPagination from "components/TAPagination";
// Theme
import theme from "assets/theme";
// Context
import { DirectorySearchBar } from "utils/context/directorySearchBar.context";
import HeaderContainer from "styles/components/headerContainer";

const ProfileCardContainer = ({ filteredResults, total, isLoading, isError, error }) => {
  const containerRef = useRef(null);
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    if (isLoading) return;
    setCurrentPage(1);
  }, [filteredResults, isLoading]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentData = filteredResults?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


  const SearchTitle = () => {
    let title = `Almadelic Directory`;
    // searchTerms.length > 0 || Object.keys(filterOptions).length > 0 ? title = `${filteredResults.total} Results` : title = "Recomended Therapists";
    return (
      <HeaderContainer cssProps={{textAlign: "center", marginBottom: "3rem !important"}} title={title} textColor={theme.palette.primary.main} component="h2" variant="h1"  />
    )
  }

  

  return (
    <>
      {isError ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.error.main} !important`, textAlign: "center" }}>
            There was an error fetching the data: {error?.message}. Please try again later
          </MKTypography>
        </Container>
      ) : (
        <>
          {isLoading ? (
            <Container sx={{ marginBlock: "4rem" }}>
              <CBLoading loadingItem={"therapist profiles"} />
            </Container>
          ) : (
            <StyledSectionContainer sx={{padding: "0rem !important"}}>
              <SearchTitle />
              
              <StyledProfileCardsSectionContainer columnGap={3} rowGap={3} container ref={containerRef} component="section" id="profile-cards-section">
                
                {filteredResults?.length === 0 ? (
                  <MKTypography>No results found</MKTypography>
                ) : (
                  currentData?.map((item, index) => <Grid item xs={10} sm={10} md={5} lg={3.5} xl={2.5}><ProfileCard key={item.id} profileData={item} index={index} /></Grid>)
                )}
                
              </StyledProfileCardsSectionContainer>
              
            </StyledSectionContainer>
          )}
          <StyledPaginationSectionContainer sx={{ display: "flex", justifyContent: "center" }}>
                <TAPagination 
                  totalItems={total} 
                  itemsPerPage={itemsPerPage} 
                  onPageChange={onPageChange} 
                  containerRef={containerRef} 
                /> 
          </StyledPaginationSectionContainer>
        </>
      )}
    </>
  );
};

export default ProfileCardContainer;
