import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import { Grid } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import ProductList from "components/Ecom/components/productList";
import OrderSummary from "components/Ecom/components/orderSummary";
import CouponsComponent from "../couponsComponent";
// Styled components
import { StyledCartSideBarContainer, StyledPromoCodeContainer } from "./index.styles";
import { StyledButtonContainer } from "index.styles";
// Utils
import { CartContext } from "utils/context/cart.context";
import useMediaQueries from "utils/mediaQueries.utils";

const CartSidebar = () => {
  const { isLg } = useMediaQueries();
  const location = useLocation();
  const [isCheckoutPage, setIsCheckoutPage] = useState(false);

  const navigate = useNavigate();
  const { cartItems, cartTotal, originalSubtotal, discount, stripeCouponObj } = useContext(CartContext);

  const handleNavigate = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    if (location.pathname === "/checkout") {
      setIsCheckoutPage(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} lg={4} order={isLg ? 1 : 0}>
      <StyledCartSideBarContainer>
        <StyledPromoCodeContainer>
          <MKTypography component="h3" variant="h6" sx={{ color: "#fff !important" }}>
            Enter Promo Code
          </MKTypography>
          <CouponsComponent couponComponentType={"checkout"} />
        </StyledPromoCodeContainer>
        <CBDivider />
        <ProductList cartItems={cartItems} />
        <OrderSummary cartItems={cartItems} cartTotal={cartTotal} discount={discount} originalSubtotal={originalSubtotal} stripeCouponObj={stripeCouponObj} />
        {!isCheckoutPage && (
          <StyledButtonContainer>
            <MKButton onClick={handleNavigate} color="secondary" fullWidth>
              Proceed To Checkout
            </MKButton>
          </StyledButtonContainer>
        )}
      </StyledCartSideBarContainer>
    </Grid>
  );
};

export default CartSidebar;
