import React from "react";
// Components
import FAQContentView from "components/CBFAQ/components/FAQContentView";
// Styled components
import { StyledSectionContainer } from "styles/components";
// Data
import shopFaqData from "./shopFaq.data.json";
import faqData from "../../../Faq/faq.data.json";
// animations
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInReverseVariants } from "styles/animations/sectionFadeIn.animations";

const allFaqData = [...shopFaqData, ...faqData];
const AnimatedStyledSectionContainer = withSectionAnimation(StyledSectionContainer, fadeInReverseVariants);

const ShopFaq = () => {
  return (
    <AnimatedStyledSectionContainer id="shop-faq-section">
      <FAQContentView initialCategory={"shop"} textColor="#fff" faqData={allFaqData} component="h2" variant="h2" />
    </AnimatedStyledSectionContainer>
  );
};

export default ShopFaq;
