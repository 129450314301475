import { List, ListItem, Stack } from "@mui/material";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useContext } from "react";
import { CartContext } from "utils/context/cart.context";

const ProductList = ({ cartItems, marginBottom, textColor }) => {
  const { removeItemFromCart } = useContext(CartContext);
  return (
    <List sx={{ marginBottom: marginBottom ? "2rem" : "0" }}>
      {cartItems?.map((cartItem, index) => {
        const { name, quantity, images, price, metadata } = cartItem;
        return (
          <ListItem key={index}>
            {images ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing="1rem"
                sx={{
                  border: `1px solid ${rgba(theme.palette.grey[400], 0.5)}`,
                  width: "100%",
                  padding: ".5rem",
                  marginBottom: "1rem",
                  borderRadius: "12px",
                }}
              >
                <MKTypography
                  component="p"
                  variant="body2"
                  gutterBottom
                  sx={{ color: textColor === "light" && `${theme.palette.white.main} !important` }}
                >
                  {quantity}x
                </MKTypography>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <MKBox component="img" src={images[0]} sx={{ height: "50px", width: "50px" }}></MKBox>
                  <Stack>
                    <MKTypography
                      component="p"
                      variant="body2"
                      sx={{
                        color: textColor === "light" ? `${theme.palette.white.main} !important` : `${theme.palette.primary.main} !important`,
                        fontWeight: "bold",
                        fontSize: "14px",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {name}
                    </MKTypography>
                    <MKTypography component="small" variant="body2" sx={{ fontSize: "12px" }}>
                      {metadata?.priceValue}
                      {/* {`$${(price / 100).toFixed(2)}`} */}
                    </MKTypography>
                  </Stack>
                </Stack>
                <button
                  onClick={() => removeItemFromCart(cartItem)}
                  style={{
                    margin: 0,
                    padding: "1rem",
                    marginLeft: "auto",
                    borderRadius: "50%",
                    color: theme.palette.secondary.main,
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    border: `none`,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: rgba(theme.palette.secondary.main, 0.1),
                    },
                  }}
                >
                  X
                </button>
              </Stack>
            ) : (
              <MKTypography component="p" variant="body2" gutterBottom>
                {quantity}x {name}
              </MKTypography>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default ProductList;
