import styled from "@emotion/styled";
import { Container, Stack, Grid, Link } from "@mui/material";

export const StyledFormContainer = styled(Grid)(({ theme }) => ({
    opacity: 0.8, 
    padding: "1rem !important",
    marginBlock: "1rem",
    fontWeight: "bold",
    backgroundColor: theme.palette.white.main,
    borderRadius: "1rem",
}));

export const StyledInputContainer = styled(Container)(({ theme }) => ({
    marginTop: "1rem",
}));

export const StyledFormLink = styled(Link)(({ theme }) => ({
    textDecoration: "underline !important",
    color: `${theme.palette.accent.main}`,
    cursor: "pointer",
  }));