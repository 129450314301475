import styled from "@emotion/styled";
import { Stack, Container } from "@mui/material";


export const StyledStackContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
}));

export const StyledTherapyServicesContainer = styled(Container)(({ theme }) => ({
    padding: "0rem !important",
    fontWeight: "bold",
    margin: "0",
}));


export const StyledSpecialistItem = styled(Stack)(({ theme }) => ({
    
    minWidth: "5rem",
    textAlign: "center",
    justifyContent: "center",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.accent.main,
    padding: "0.4rem 1.5rem",
    margin: "0.2rem",
    borderRadius: "1.3rem",
    fontSize: "0.9rem",
}));

