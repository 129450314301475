import { styled } from "@mui/material/styles";
import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Container } from "@mui/material";
import MKBox from "components/MKBox";


export const StyledEllipsisTypographyLink = styled(Link)(({ theme }) => ({
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    width: "100%",
    color: theme.palette.accent.main,
    fontSize: "14px",
}));

export const StyledProfileCardImage = styled(MKBox)(({ theme, activeColor }) => ({
    borderRadius: "1rem",
    minWidth: "200px",
    height: "250px",
    objectFit: "cover",
    padding: ".3rem",
    border: `3px solid ${activeColor}` ,
    boxShadow: `rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px`,
    backgroundColor: theme.palette.white.main,
  }));

  export const StyledHeaderContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    color: "#fff",
    gap: "2rem",
    alignItems: "center",
    padding: "2rem",
    borderRadius: "0px",
    position: "relative",
    overflow: "hidden",
    "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "url(https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg) 0 0 repeat",
        zIndex: -1,
        transform: "rotate(180deg)",
        borderRadius: "0px",
    },
  }));