import React, { useContext, useEffect } from "react";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import useFetchStripeTransaction from "./hook/useFetchStripeTransaction";

import { unixToDateConversion } from "utils/helpers/unixToDateConversion";
import { StyledMainPageContainer, StyledSectionBackgroundWrapper } from "index.styles";
import CBLoading from "components/CBLoading";
import useCombinedPaymentIntent from "./hook/useCombineMultiplePaymentIntent";
// import { StyledButtonContainer } from "index.styles";
import MKButton from "components/MKButton";
import { CartContext } from "utils/context/cart.context";
import PageHeader from "components/CBPageHeader";
import OrderConfirmationSummary from "./components/orderConfirmationSummary";
import CBDivider from "components/CBDivider";
import AdditionalInformation from "./components/addtionalInformation";

const OrderConfirmation = () => {
  const location = useLocation();
  const { subscriptionPaymentIntentId, addonPaymentIntentId } = location.state;
  const { clearCart } = useContext(CartContext);
  const { data: subscriptionPaymentIntent, error: subscriptionError } = useFetchStripeTransaction(subscriptionPaymentIntentId);
  const { data: addonPaymentIntent, error: addonError } = useFetchStripeTransaction(addonPaymentIntentId);

  const { loading, error, paymentIntent } = useCombinedPaymentIntent(subscriptionPaymentIntent, addonPaymentIntent, subscriptionError, addonError);

  useEffect(() => {
    if (paymentIntent) {
      clearCart();
    }
  }, [paymentIntent]);

  if (loading) {
    return (
      <Container sx={{ marginBlock: "4rem" }}>
        <CBLoading loadingItem={"order confirmation"} />
      </Container>
    );
  }

  if (error) {
    return <Container sx={{ marginBlock: "4rem" }}>{error}</Container>;
  }
  // const StripeAccountAccess = () => {
  //   return (
  //     <StyledButtonContainer sx={{ justifyContent: "center", alignContent: "center", display: "flex", margin: "0rem 0rem 2rem" }}>
  //       <MKButton href="https://billing.stripe.com/p/login/test_9AQ3cO1rU1kwgfKeUU" variant="contained" color="secondary" size="large" align="center">
  //         Access Stripe Account
  //       </MKButton>
  //     </StyledButtonContainer>
  //   );
  // };

  const address = JSON.parse(paymentIntent?.metadata.billingAddress).state.toLowerCase();
  const clinicalEvaluationLink =
    address === "colorado" || address === "co"
      ? "https://clinical.almadelic.com/get-started-clinical-evaluation-colorado"
      : address === "ohio" || address === "oh"
      ? "https://clinical.almadelic.com/get-started-clinical-evaluation-ohio"
      : address === "florida" || address === "fl"
      ? "https://clinical.almadelic.com/get-started-clinical-evaluation-florida"
      : "https://clinical.almadelic.com/get-started-clinical-evaluation";

  return (
    <StyledMainPageContainer id="order-confirmation-container">
      <StyledSectionBackgroundWrapper>
        <PageHeader
          lightText={true}
          header="Thank you for your order! \n What's next?"
          headerAccent="What's next?"
          content="The next critical step is to schedule your Initial Clinical Evaluation. This 45-minute appointment with a licensed mental health specialist is key to tailoring your therapy to your unique needs."
          cta={
            <MKButton href={clinicalEvaluationLink} variant="contained" color="secondary" size="large" align="center">
              Schedule Your Clinical Evaluation
            </MKButton>
          }
        />
      </StyledSectionBackgroundWrapper>
      <OrderConfirmationSummary
        firstName={paymentIntent?.metadata.firstName}
        lastName={paymentIntent?.metadata.lastName}
        billingAddress={paymentIntent?.metadata.billingAddress}
        shippingAddress={paymentIntent?.metadata.shippingAddress}
        phone={paymentIntent?.metadata.phone}
        email={paymentIntent?.metadata.email}
        orderId={paymentIntent?.id}
        discount={paymentIntent?.metadata.couponAmount}
        couponId={paymentIntent?.metadata.couponId}
        originalSubtotal={paymentIntent?.metadata.subtotal}
        cartTotal={paymentIntent?.amount}
        createdAt={unixToDateConversion(paymentIntent?.createdAt || paymentIntent.created)}
        paymentId={paymentIntent?.id}
        paymentMethod={paymentIntent?.payment_method_types}
        cartItems={paymentIntent?.metadata.combinedCartItems || paymentIntent?.metadata.cartItems}
        nextPayment={paymentIntent?.metadata?.nextPaymentAmount}
      />
      <CBDivider />
      <AdditionalInformation />
      {/* <NextSteps shippingAddress={paymentIntent?.metadata.billingAddress} /> */}
    </StyledMainPageContainer>
  );
};

export default OrderConfirmation;
