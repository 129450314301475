import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import useMediaQueries from 'utils/mediaQueries.utils';
import { StyledAdditionalButterfly, StyledSurveyWrapperContainer } from './index.styles';

function TAModal({body, handleClick, showForm}) {
    const { isMd } = useMediaQueries();


  return (
    
      <Modal open={showForm} onClose={handleClick}>
        
        <Box sx={{ 
          maxWidth: isMd ? "65%" : "85%", 
          overflowY: 'auto',
          maxHeight: isMd ? "80%" : "90%",
          bgcolor: 'background.paper', 
          margin: 'auto', 
          marginTop: '5%', 
          padding:"1rem", 
          position: "relative", 
          borderRadius: "1rem",
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url('https://d3a0rs3velfrz.cloudfront.net/logos/blue-butterfly.svg')",
           }}>
          {body}
          <Button onClick={handleClick} sx={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}>Close</Button>
          
        </Box>
      </Modal>
  );
}

export default TAModal;