
import React from "react";
import { Stack } from "@mui/material";
import { StyledThreeStackCard } from "./index.styles";

const ThreeStackCard = ({topComponent, midComponent, botComponent, cssProps, handleClick = null,id=null, ...props}) => {
  const { layoutId, initial, animate, exit, transition } = props;
  const animationProps = {
    layoutId,
    initial,
    animate,
    exit,
    transition,
  };
  
  return (
    
    <StyledThreeStackCard layoutId={layoutId} id={id} cssProps={cssProps} onClick={handleClick} {...props} >
            <Stack direction="column" >
              {topComponent}
              {midComponent}
              {botComponent}
            </Stack>
    </StyledThreeStackCard>
  );
}

export default ThreeStackCard;