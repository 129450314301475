import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "assets/theme";

// Components
import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ItemizedCartItems from "../itemizedCartItems";

// Styled components
import { StyledButtonContainer } from "index.styles";

const CartOrderSummary = ({ isCartEmpty, conflictingItems, discount, originalSubtotal, cartTotal, textColor, setIsCartOpen, cartItems }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/checkout");
    setIsCartOpen(false);
  };
  return (
    <MKBox sx={{ marginBottom: "1rem" }}>
      <ItemizedCartItems cartItems={cartItems} textColor={textColor} />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography component="p" variant="h6" sx={{ color: textColor === "accent" && `${theme.palette.accent.main} !important` }}>
          Subtotal
        </MKTypography>
        <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
          {`$${(originalSubtotal / 100).toFixed(2)}`}
        </MKTypography>
      </Stack>
      {discount > 0 && (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
          <MKTypography component="p" variant="h6" sx={{ color: textColor === "accent" && `${theme.palette.accent.main} !important` }}>
            Discount
          </MKTypography>
          <MKTypography sx={{ color: `${theme.palette.success.main} !important` }} component="small" variant="body2">
            {`-$${(discount / 100).toFixed(2)}`}
          </MKTypography>
        </Stack>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography component="p" variant="h6" sx={{ color: textColor === "accent" && `${theme.palette.accent.main} !important` }}>
          Total
        </MKTypography>
        <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
          ${(cartTotal / 100).toFixed(2)}
        </MKTypography>
      </Stack>
      <StyledButtonContainer>
        <MKButton disabled={conflictingItems.length > 0 || isCartEmpty} onClick={handleNavigate} color="secondary" fullWidth>
          Proceed To Checkout
        </MKButton>
      </StyledButtonContainer>
    </MKBox>
  );
};

export default CartOrderSummary;
