import React, {useContext, useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import { StyledSearchBarContainer, StyledSearchBarIconContainer, StyledSearchBarInputField,StyledSelectOptionsField } from "./index.styles";
import PrioritySelectOptions from "./components/prioritySelectOptions";
import MKButton from "components/MKButton";
import {DirectorySearchBar} from "utils/context/directorySearchBar.context";
import { GHLUsersContext } from "utils/context/ghlUsers.context";
import SearchFilter from "./components/searchFilter";
import useMediaQueries from "utils/mediaQueries.utils";
import borders from "assets/theme/base/borders";

const TADirectorySearchBar = ({ placeholder = "Enter search here...", filter = false, color }) => {
    const { isLoading } = useContext(GHLUsersContext);
    const { setSearchTerms,searchTerms, inputRef, page, setPage } = useContext(DirectorySearchBar);
    const [localSearchTerms, setLocalSearchTerms] = useState(searchTerms);
    const { isLg } = useMediaQueries();
    
    const handleOnSearchChange = (event) => {
        const searchField = event.target.value.toLocaleLowerCase();
        setLocalSearchTerms(searchField);
    }
    
    const handleSearch = () => {
        setSearchTerms(localSearchTerms);
        setPage(1)
    }

    const handleKeyPress = (event) => {
        if(event.key === "Enter"){
            handleSearch();
        }
    }


    return(
        <StyledSearchBarContainer>
            <Grid container direction={"row"} rowGap={1}>
                {filter && <Grid item xs={12} sm={12} lg={2} order={isLg ? 0 : 1}>
                    <PrioritySelectOptions disabled={isLoading}/>
                </Grid>}
                <Grid item xs={11} sm={11}lg={filter ? 9 : 11}>
                    <StyledSearchBarInputField type="search" 
                        onChange={handleOnSearchChange} 
                        onKeyPress={handleKeyPress}
                        sx={{
                            borderTopLeftRadius: isLg ? "0":"0.5rem !important",
                            borderBottomLeftRadius: isLg ? "0":"0.5rem !important",
                        }} 
                        defaultValue={searchTerms} 
                        placeholder={"Enter Name Here..."} 
                        ref={inputRef} 
                    />
                </Grid>
                <Grid item xs={1} sm={1} lg={1}>
                    <StyledSearchBarIconContainer disabled={isLoading} onClick={handleSearch} >
                        <SearchIcon sx={{ color: "#fff" }}  />
                    </StyledSearchBarIconContainer>
                </Grid>
            </Grid>
            {filter && <SearchFilter disabled={isLoading} />}
        </StyledSearchBarContainer>
    )
}

export default TADirectorySearchBar;