import React, { createContext, useContext } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { generateEndpoint } from "utils/helpers/generateEndpoint";

const StripeContext = createContext({
  products: [],
  packages: [],
  addons: [],
  loading: false,
  error: null,
});

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_LIVE_STRIPE_PUBLIC_KEY : process.env.REACT_APP_STRIPE_PUBLIC_KEY
);

const fetchProducts = async () => {
  const endpoint = generateEndpoint(
    process.env.REACT_APP_AWS_DEV_GET_STRIPE_PRODUCTS_ENDPOINT,
    process.env.REACT_APP_AWS_PROD_GET_STRIPE_PRODUCTS_ENDPOINT
  );
  const response = await axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const fetchedProducts = JSON.parse(response.data.body);

  const packageItems = fetchedProducts.filter((product) => product.metadata?.type === "package");
  const addonItems = fetchedProducts.filter((product) => product.metadata?.type === "addon");

  return {
    products: fetchedProducts,
    packages: packageItems,
    addons: addonItems,
  };
};

const StripeProvider = ({ children }) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });

  const contextValue = {
    products: data?.products || [],
    packages: data?.packages || [],
    addons: data?.addons || [],
    loading: isLoading,
    error: isError ? error : null,
  };

  return (
    <StripeContext.Provider value={contextValue}>
      <Elements stripe={stripePromise}>{children}</Elements>
    </StripeContext.Provider>
  );
};

export const useStripeProducts = () => useContext(StripeContext);

export default StripeProvider;
