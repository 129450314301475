import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import { Container, Stack, Grid,TextField } from "@mui/material";
import MKButton from "components/MKButton";
import HeaderContainer from "styles/components/headerContainer";
import theme from "assets/theme";
import axios from "axios";
import CBLoading from "components/CBLoading";
import { StyledFormContainer } from "./index.styles";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";



const ModalContactForm = ({userData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);


  const handleFetch = async (url, body) => {
    try {
      const postResponse = await axios.post(url, body);
      console.log("response", postResponse);
      if (postResponse.status === 200) {
        console.log("response", postResponse);
        return true;
      }
    } catch (error) {
      setIsError(true);
      setError(error);
      return false;
    }
  }
  
  const handlRequests = async (values) => {
    setIsLoading(true);
    const { firstName, lastName, email, message, therapistEmail } = values;
    const bodyMessage = {
      message_content: {
        first_name: firstName,
        last_name: lastName,
        contact_email: email,
        message: message,
      },
      email: therapistEmail,
    }
  
    const postMessageURL = "https://services.leadconnectorhq.com/hooks/pTpZs5Up0T2OAJPqtcLs/webhook-trigger/eb6b4357-947b-4a61-b44a-8459372e2c96"
    const postRequest = await handleFetch(postMessageURL, bodyMessage);
    console.log("first request", postRequest)
    
    if (postRequest) {
      console.log("second request")
      const createContactURL = "https://services.leadconnectorhq.com/hooks/pTpZs5Up0T2OAJPqtcLs/webhook-trigger/cdd6ad10-efd8-4925-bd4e-a94031734b13"
      const contact = {
        first_name: firstName,
        last_name: lastName,
        email: email,
      }
      const createContactFetch = await handleFetch(createContactURL, contact);
      if (createContactFetch) {
        setSuccess(true);
      }
    }
  
    setIsLoading(false);
  }
  




    const FormBody = () => {
      return(
        <Container >
            <HeaderContainer component="h2" variant="h2" cssProps={{ textAlign: "center", justifyContent: "center" }} title={`Contact ${userData.name}`} textColor={theme.palette.primary.main} />
            
            <Formik
              initialValues={{ firstName: "",lastName:"", email: "", message: "", therapistEmail: userData.email }}
              onSubmit={(values) => {
                handlRequests(values);
              }}
              
            >
              <Form>
              <StyledFormContainer container columnGap={1} rowGap={2} >
                <Grid item xs={12} md={6}>
                  <MKTypography variant="h6" component="p" align="left" color="textPrimary" gutterBottom>
                    First Name
                  </MKTypography>
                  <Field
                    as={TextField} 
                    name="firstName" 
                    type="text" 
                    placeholder="First Name"
                    inputProps={{
                      style: { color: 'black', backgroundColor: theme.palette.white.main }
                    }}
                    sx={{width: "100%", backgroundColor: theme.palette.white.main}}
                  />
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <MKTypography variant="h6" component="p" align="left" color="textPrimary" gutterBottom>
                    Last Name
                  </MKTypography>
                  <Field 
                    as={TextField}
                    name="lastName"  
                    type="text" 
                    placeholder="Last Name" 
                    inputProps={{
                      style: { color: 'black', backgroundColor: theme.palette.white.main }
                    }}
                    sx={{width: "100%", backgroundColor: theme.palette.white.main}}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MKTypography variant="h6" component="p" align="left" color="textPrimary" gutterBottom>
                    Email
                  </MKTypography>
                  <Field 
                    as={TextField}
                    name="email" 
                    type="email" 
                    placeholder="Email"
                    inputProps={{
                      style: { color: 'black', backgroundColor: theme.palette.white.main }
                    }}
                    sx={{width: "100%", backgroundColor: theme.palette.white.main}} 
                    />
                </Grid>

                <Grid item xs={12}>
                  <MKTypography variant="h6" component="p" align="left" color="textPrimary" gutterBottom>
                    Message
                  </MKTypography>
                  <Field
                    as={TextField} 
                    name="message" 
                    multiline={true} 
                    rows={3}
                    sx={{width: "100%", backgroundColor: theme.palette.white.main}}
                    type="text" 
                    placeholder="Message" 
                    inputProps={{
                      style: { color: 'black', backgroundColor: theme.palette.white.main }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MKButton type="submit" variant="contained" color="secondary">
                    Submit
                  </MKButton>
                </Grid>
                <Grid item xs={12} >
                  <MKBox sx={{display: "flex", justifyContent: "space-between"}}>
                    
                    <MKTypography
                      sx={{ color: theme.palette.primary.main, width: "95%" }}
                      component="p"
                      variant="body2"
                      textTransform="capitalize"
                      gutterBottom
                    >
                      By pressing submit, you agree to receive automated reminders and promotional messages from Almadelic Management Services, LLC. You also
                      agree to our <a href="/terms">Terms Of Service</a>
                      &nbsp; and &nbsp;<a href="/privacy-policy">Privacy Policy</a>. This agreement isn't a condition of any
                      purchase. Msg & Data rates may apply. Reply STOP to end or HELP for help.
                    </MKTypography>
                  </MKBox>
                </Grid>
              </StyledFormContainer>
              </Form>
            </Formik>
        </Container>
      )
    }

    const SussesMessage = () => {
      return(
        <StyledFormContainer>
          <HeaderContainer component="h2" variant="h2" sx={{ textAlign: "center", justifyContent: "center" }} title={`Thank you for contacting ${userData.name}`} textColor={theme.palette.primary.main} />
          <MKTypography>
            Your message has been sent and You should recieve a confirmation email soon. They will be in touch with you.
          </MKTypography>
        </StyledFormContainer>
      )
    }

    const ErrorMessage = () => {
      return(
        <StyledFormContainer>
          <HeaderContainer component="h2" variant="h2" sx={{ textAlign: "center", justifyContent: "center" }} title={`There was an error sending your message`} textColor={theme.palette.error.main} />
          <MKTypography>
            Please try again later.
          </MKTypography>
        </StyledFormContainer>
      )
    }
    return(
      <>
        {isError ? <ErrorMessage /> : null}
        {isLoading ? <CBLoading /> : success ? <SussesMessage /> : <FormBody />}
      </>
      )
}

export default ModalContactForm;