import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import PaymentSummary from "../paymentSummary";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
import useMediaQueries from "utils/mediaQueries.utils";
import { StyledVerticalDivider } from "styles/components";
const commonHeaderStyles = {
  fontWeight: "bold",
  color: `${theme.palette.accent.main} !important`,
  borderBottom: `2px solid ${theme.palette.accent.main}`,
  paddingBottom: ".5rem",
  margin: "auto auto 2rem auto",
};
const OrderConfirmationSummary = ({ ...props }) => {
  const { isMd } = useMediaQueries();
  return (
    <StyledSectionContainer>
      <MKTypography variant="h2" component="h2" gutterBottom sx={{ marginBottom: "2rem" }}>
        Order Summary
      </MKTypography>
      <MKBox
        sx={{
          padding: { xs: "1rem", lg: "2rem" },
          borderRadius: "12px",
          border: `2px solid ${rgba(theme.palette.accent.main, 0.5)}`,
          boxShadow: `rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px`,
        }}
      >
        {/* {props.cartItems !== undefined ||
          (props.cartItems !== null && (
            <>
              <MKTypography variant="h4" component="h3" gutterBottom>
                Products Purchased
              </MKTypography>
              <CartItems cartItemType="order-confirmation" cartItems={props.cartItems} conflictingItems={[]} />
            </>
          ))} */}
        <MKTypography variant="h4" component="h3" gutterBottom sx={commonHeaderStyles}>
          Shipping & Billing Details
        </MKTypography>
        <Stack direction={isMd ? "row" : "column"} mt={4} mb={4} spacing="2rem">
          <MKBox sx={{ width: "100%" }}>
            <MKTypography component="h4" variant="body2" gutterBottom sx={{ fontWeight: "bold" }}>
              Shipping Address
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.shippingAddress).firstName} {JSON.parse(props.shippingAddress).lastName}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.shippingAddress).addressLine}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.shippingAddress).city.charAt(0).toUpperCase() + JSON.parse(props.shippingAddress).city.slice(1).toLowerCase()},{" "}
              {JSON.parse(props.shippingAddress).state} {JSON.parse(props.shippingAddress).postalCode}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.shippingAddress).phone}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {props.email}
            </MKTypography>
          </MKBox>
          <StyledVerticalDivider color={rgba(theme.palette.grey[300], 0.5)} marginInline={"true"} />
          <MKBox sx={{ width: "100%" }}>
            <MKTypography component="h4" variant="body2" gutterBottom sx={{ fontWeight: "bold" }}>
              Billing Address
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.billingAddress).firstName} {JSON.parse(props.billingAddress).lastName}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.billingAddress).addressLine}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.billingAddress).city.charAt(0).toUpperCase() + JSON.parse(props.billingAddress).city.slice(1).toLowerCase()},{" "}
              {JSON.parse(props.billingAddress).state} {JSON.parse(props.billingAddress).postalCode}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {JSON.parse(props.billingAddress).phone}
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              {props.email}
            </MKTypography>
          </MKBox>
        </Stack>
        <MKTypography variant="h4" component="h3" gutterBottom sx={commonHeaderStyles}>
          Payment Details
        </MKTypography>
        <PaymentSummary {...props} />
      </MKBox>
    </StyledSectionContainer>
  );
};

export default OrderConfirmationSummary;
