
// Major
import React, {useState} from "react";

// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import TAEmbeddedYT from "components/TAEmbeddedYT";
import CBLoading from "components/CBLoading";
import EditForm from "../../components/EditForm";
import EditButton from "../EditButton";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import useMediaQueries from "utils/mediaQueries.utils";
import usePutTherapistByID from 'utils/hooks/usePutTherapistByID';
import filterBadWords from "utils/helpers/filterBadWords";

// Styles
import { StyledBodyContainer} from "./index.styles";
import { Container, Stack} from "@mui/material";
import theme from "assets/theme";


const AboutMeEdit = ({userData, handleUpdateUserData}) => {
    const { email, phone, state, city, name, customFields } = userData || [];
    const longBio = findCustomFieldValue("Long Bio", customFields);
    const youtubeURL = findCustomFieldValue("YouTube Link", customFields);
    const [showMore, setShowMore] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const { updateContactInfo, data, isLoading, isError, error, } = usePutTherapistByID();
    const { isLg, isMd } = useMediaQueries();
    
    const maxChars = isLg ? 1000 : 400;
    const isLongText = longBio?.length > maxChars;
    const displayText = showMore ? longBio : `${longBio?.slice(0, maxChars)}${isLongText ? "... " : "  "}`;


    const ReadMoreButton = () => {
        if (longBio?.length < maxChars) return null;
        return (
            <MKButton color="secondary" size="small" sx={{ width: "120px", margin: "1.0rem" }} onClick={handleReadMore}>
                {showMore ? "Read Less" : "Read More"}
            </MKButton>
        )
    }

    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

    const isValidYoutubeURL = (url) => {
        return youtubeRegex.test(url);
    }

    

    const handleSubmit = async (values) => {

        const payload = {
            ...values,
        }

        // filtering out bad words
        const filterPayload = (payload) => {
            const filteredPayload = { ...payload };
            const keysToFilter = ["Long Bio"];

            Object.keys(filteredPayload).forEach((key) => {
                if (typeof filteredPayload[key] === "string" && keysToFilter.includes(key)) {
                    filteredPayload[key] = filterBadWords(filteredPayload[key]);
                }
            });

            return filteredPayload;
        };
        // filtering payload of bad words
        const filteredPayload = filterPayload(payload);

        // check if youtube link is valid
        if(filteredPayload["YouTube Link"] && !isValidYoutubeURL(filteredPayload["YouTube Link"])){
            alert("Invalid YouTube URL")
            return;
        }

        const response = await updateContactInfo(filteredPayload, userData.id);
        if(response){
            handleUpdateUserData(response);
        }
    }

    const handleReadMore = () => {
        setShowMore(!showMore);
    };
    


    const AboutMeBody = () => {
        if(isLg){
            return (
                <Stack direction={isLg ? "row" : "column"} spacing={ 2 } >
                    <Container sx={{padding: isLg ? "1rem 0rem !important" : "0rem !important"}}>
                        <MKTypography component="h2" variant="h5" gutterBottom>
                            About Me <EditButton showForm={showForm} setShowForm={setShowForm}/>
                        </MKTypography>
                        <MKTypography component="p" variant={"body2"} sx={{padding: "0rem 0.5rem"}}>
                                {displayText}
                                {longBio?.length < maxChars ? null : <span
                                    role="button"
                                    tabIndex={0}
                                    onClick={handleReadMore}
                                    onKeyDown={handleReadMore}
                                    style={{ color: theme.palette.info.main, cursor: "pointer" }}
                                >
                                    {showMore ? "Read Less" : "Read More"}
                                </span>}
                        </MKTypography> 
                    </Container>
                    
                    {youtubeURL ? <TAEmbeddedYT youtubeURL={youtubeURL} /> : null}
                    
                </Stack>)   
            } else{
                return(
                    <Stack direction={isLg ? "row" : "column"} spacing={ 2 } >
                        {youtubeURL ? <TAEmbeddedYT youtubeURL={youtubeURL} /> : null}
                        <Container sx={{padding: isLg ? "1rem" : "0rem !important"}}>
                            <MKTypography component="h2" variant="h5" gutterBottom>
                                About Me <EditButton showForm={showForm} setShowForm={setShowForm}/>
                            </MKTypography>
                            <MKTypography component="p" variant={"body2"} sx={{padding: "0rem 0.5rem"}}>
                                    {displayText}
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={handleReadMore}
                                        onKeyDown={handleReadMore}
                                        style={{ color: theme.palette.info.main, cursor: "pointer" }}
                                    >
                                        {showMore ? "Read Less" : "Read More"}
                                    </span>
                            </MKTypography> 
                        </Container>
                    </Stack>
                    )
        }
    }

    const questions = [
        {
            name: "Long Bio",
            answer: longBio || '',
            placeholder: "Bio",
            type: "outline",
            variant: "outlined",
            rows: 4,
        },
        {
            name: "YouTube Link",
            answer: youtubeURL || '',
            placeholder: "YouTube Link",
            type: "outline",
            variant: "outlined",
            rows: 1,
        }
    ]


    const ErrorMessage = () => {
        return (
            <MKTypography component="h3" variant="h3" sx={{ color: "red" }}>
                There was an error updaing your data: {error?.message}. Please try again later
            </MKTypography>
        )
    }

    

    return (
        <StyledBodyContainer id="therapist-bio-about-me-edit-page">
            {isLoading ? <CBLoading/> :
                <>
                    {isError ? <ErrorMessage/> : null}
                    <AboutMeBody />
                    {showForm ? <EditForm handleSubmit={handleSubmit} questions={questions}/> : null}
                </>
            }
        </StyledBodyContainer>
    );
}

export default AboutMeEdit;