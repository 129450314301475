import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";

export const StyledHeroSectionContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  overflow: "visible",
  paddingBlock: "4rem",
  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "4rem",
    minHeight: "650px",
    alignItems: "center",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    minHeight: "850px",
  },
}));

export const StyledHeroTextGridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    marginTop: "12rem",
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: "8rem",
  },
}));

export const StyledHeroImageGridItem = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  minHeight: "400px",
}));

export const StyledHeroImage = styled(MKBox)(({ theme }) => ({
  filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
  objectFit: "cover",
  overflow: "visible !important",
  position: "absolute",
  zIndex: "-1",
  inset: "-20px 0 0 10px",
  transform: "scale(1.05)",
  "@media (min-width: 768px)": {
    inset: "0 0 0 -80px",
    transform: "scale(1.2)",
  },
  "@media (min-width: 991px)": {
    inset: "0 0 0 -25px",
    transform: "scale(1.3)",
  },

  "@media (min-width: 1200px)": {
    inset: "-20px 0 0 -25px ",
    transform: "scale(1.4)",
  },
  "@media (min-width: 1400px)": {
    inset: "0px 0 0 -80px ",
    transform: "scale(1.6)",
  },
  "@media (min-width: 1600px)": {
    inset: "0px 0 0 -100px ",
    transform: "scale(1.8)",
  },
}));
