import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Styles
import theme from "assets/theme";
import { CustomCollapse, DropdownBox, NavBox, NavLink, StyledMenuBox, StyledMKTypography } from "./index.styles";

// components
import { Collapse, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import CBDivider from "components/CBDivider";
import LoginAndLogOut from "components/CBLoginLogOut";
import ShoppingCart from "components/CBShoppingCart";
import useMediaQueries from "utils/mediaQueries.utils";
import { AuthContext } from "utils/context/authentication.context";
import { CartContext } from "utils/context/cart.context";
import { DirectorySearchBar } from "utils/context/directorySearchBar.context";

const MobileNavList = ({ routes, mobileNavToggle, setMobileNavToggle }) => {
  const { isMd } = useMediaQueries();
  const [open, setOpen] = useState(null);
  const { setSearchTerms, setFilterOptions, setPage } = useContext(DirectorySearchBar);
  const { isAuthorized } = useContext(AuthContext);
  const { cartItems } = useContext(CartContext);
  const menuRef = useRef(null);

  const handleItemClick = (index) => {
    setOpen(open === index ? null : index);
  };

  const closeMenuOnOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMobileNavToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeMenuOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closeMenuOnOutsideClick);
    };
  }, [menuRef]);

  const handleClick = (name) => {
    
    if(name === "Find A Therapist") {
      setFilterOptions([]);
      setSearchTerms("");
      setPage(1);
    }
    
  }

  return (
    <CustomCollapse in={Boolean(mobileNavToggle)} timeout={"auto"} unmountOnExit>
      <StyledMenuBox ref={menuRef}>
        <Stack direction={"row"} gap={2} justifyContent={"space-between"} sx={{ maxWidth: "40%", margin: "auto" }}>
          {!isMd && <ShoppingCart />}
          <LoginAndLogOut setMobileNavToggle={setMobileNavToggle} mobileNavToggle={mobileNavToggle} />
        </Stack>
        {!isMd && (cartItems.length > 0 || isAuthorized) && (
          <MKBox sx={{ maxWidth: "90%", margin: "auto" }}>
            <CBDivider />
          </MKBox>
        )}
        {routes.map((item, index) => (
          <NavBox key={item.name} onClick={() => handleItemClick(index)}>
            <NavLink variant="button" color="inherit" component={item.route ? Link : "span"} to={item.route || null}>
              <MKTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                sx={{ fontSize: { xs: "18px", lg: "16px" }, color: theme.palette.primary.main, fontWeight: "100%", textDecoration: "none" }}
              >
                {item.name}
              </MKTypography>
            </NavLink>
            {item.collapse && (
              <Collapse in={Boolean(open === index)} timeout={"auto"} unmountOnExit>
                <DropdownBox>
                  {item.collapse.map(({ key, route, name }) => (
                    <StyledMKTypography
                      key={key}
                      onClick={() => {
                        setOpen(null);
                        handleClick(name);
                      }}
                      variant="button"
                      py={0.625}
                      px={2}
                      textTransform="capitalize"
                    >
                      <MKTypography onClick={() => setMobileNavToggle(!mobileNavToggle)} variant="body2" component={Link} to={route}>
                        {name}
                      </MKTypography>
                    </StyledMKTypography>
                  ))}
                </DropdownBox>
              </Collapse>
            )}
          </NavBox>
        ))}
      </StyledMenuBox>
    </CustomCollapse>
  );
};

export default MobileNavList;
