import MKTypography from "components/MKTypography";
import OrderSummary from "components/Ecom/components/orderSummary";
import React from "react";
import { StyledVerticalDivider } from "styles/components";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import { Stack } from "@mui/material";
import rgba from "assets/theme/functions/rgba";
import useFetchCoupons from "pages/Shop/hooks/useFetchCoupons";

const PaymentSummary = ({ discount, originalSubtotal, cartTotal, createdAt, paymentId, paymentMethod, cartItems, nextPayment, couponId }) => {
  const { coupons } = useFetchCoupons();
  const currentCoupon = coupons.find((coupon) => coupon.id === couponId);
  return (
    <MKBox
      sx={{
        borderRadius: "12px",
        border: `2px solid ${rgba(theme.palette.accent.main, 0.5)}`,
        padding: "1rem",
        marginBlock: "2rem",
        boxShadow: `rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px`,
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        mb={"2rem"}
        sx={{
          backgroundColor: theme.palette.grey[100],
          padding: ".5rem 1rem",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <Stack sx={{ width: "33%" }}>
          <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Date
          </MKTypography>
          <MKTypography component="p" variant="body2">
            {createdAt}
          </MKTypography>
        </Stack>
        <StyledVerticalDivider color={theme.palette.grey[400]} />
        <Stack
          sx={{
            width: "33%",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.grey[300],
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.grey[200],
            },
          }}
        >
          <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Confirmation Number
          </MKTypography>
          <MKTypography component="p" variant="body2">
            {Array.isArray(paymentId) && paymentMethod.length > 0 ? paymentId[0] : paymentId}
          </MKTypography>
        </Stack>
        <StyledVerticalDivider color={theme.palette.grey[400]} />
        <Stack sx={{ width: "33%" }}>
          <MKTypography component="small" sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Payment
          </MKTypography>
          <MKTypography component="p" variant="body2">
            {Array.isArray(paymentMethod) && paymentMethod.length > 0
              ? paymentMethod[0].charAt(0).toUpperCase() + paymentMethod[0].slice(1)
              : paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}
          </MKTypography>
        </Stack>
      </Stack>

      <OrderSummary
        maxWidth={"400px"}
        cartTotal={cartTotal}
        discount={discount}
        originalSubtotal={originalSubtotal}
        nextPayment={nextPayment}
        stripeCouponObj={currentCoupon}
        couponId={couponId}
      />
    </MKBox>
  );
};

export default PaymentSummary;
