import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import MKBox from "components/MKBox";

export const StyledAddressRecommendation = styled(motion(MKBox))(({ theme, isSelected }) => ({
  padding: "1rem",
  border: isSelected ? `1px solid ${theme.palette.grey[300]}` : "none",
  borderRadius: "4px",
  boxShadow: isSelected ? `0px 0px 15px 0px ${theme.palette.grey[300]}` : "none",
}));
