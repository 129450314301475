import styled from "@emotion/styled";
import MKBox from "components/MKBox";

export const OrderSummaryContainer = styled(MKBox)(({ theme }) => ({
  padding: "1rem",
  backgroundColor: theme.palette.grey[200],
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
  borderRadius: "12px",
}));
