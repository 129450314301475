import React, { useContext, useState } from "react";

// Components
import { CircularProgress, Stack, TextField } from "@mui/material";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import CloseIcon from "@mui/icons-material/Close";
// Styled components
import {
  inputStyles,
  inputStylesCheckout,
  StyledErrorAlertInvalidPromo,
  StyledPromoCodeInputContainer,
  StyledPromoCodeTypography,
} from "./index.styles";
// Utils
import { CartContext } from "utils/context/cart.context";
import useFetchCoupons from "pages/Shop/hooks/useFetchCoupons";

const CouponsComponent = ({ ...props }) => {
  const { couponComponentType } = props;
  const { applyPromoCode, promoCode, couponNotValid, removePromoCode, setCouponNotValid } = useContext(CartContext);

  const { coupons, loading, error } = useFetchCoupons();

  const [code, setCode] = useState("");

  const handleApplyPromoCode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    applyPromoCode(code, coupons);
  };

  const handleRemoveCoupon = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removePromoCode();
    setCode("");
  };

  const isCheckoutPage = couponComponentType === "checkout";

  if (loading)
    return (
      <MKBox sx={{ padding: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress color="secondary" size={20} />
      </MKBox>
    );
  if (error)
    return (
      <StyledErrorAlertInvalidPromo isCheckoutPage={isCheckoutPage} severity="error">
        Error fetching coupons
      </StyledErrorAlertInvalidPromo>
    );

  return (
    <Stack>
      {couponNotValid && (
        <StyledErrorAlertInvalidPromo
          severity="error"
          isCheckoutPage={isCheckoutPage}
          onClose={() => {
            setCouponNotValid(false);
            setCode("");
          }}
        >
          This promo code is not valid.
        </StyledErrorAlertInvalidPromo>
      )}
      {!promoCode && (
        <StyledPromoCodeInputContainer isCheckoutPage={isCheckoutPage} direction={"row"} justifyContent={"space-between"} spacing={2}>
          <TextField
            sx={{
              width: "100%",
              ...inputStyles,
              ...(isCheckoutPage && {
                ...inputStylesCheckout,
              }),
            }}
            placeholder="Enter your promo code..."
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            name="promoCode"
          />
          <MKButton onClick={handleApplyPromoCode} sx={{ marginTop: "1rem" }} color="secondary" variant="contained">
            Apply
          </MKButton>
        </StyledPromoCodeInputContainer>
      )}
      {promoCode && (
        <Stack sx={{ width: "100%", marginTop: "1.5rem", justifyContent: "space-between" }} direction={"row"} alignItems={"center"} spacing={2}>
          <StyledPromoCodeTypography isCheckoutPage={isCheckoutPage} component="p" variant="body2">
            Promo code applied:
          </StyledPromoCodeTypography>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <MKButton variant="outlined" color="secondary" onClick={handleRemoveCoupon} endIcon={<CloseIcon />}>
              {promoCode}
            </MKButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CouponsComponent;
