import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import SEO from "components/CBSEO";
import ProfileCardContainer from "pages/FindATherapist/components/profileCardContainer";
import CBLoading from "components/CBLoading";
import PageHeader from "components/CBPageHeader";
import CBDivider from "components/CBDivider";
import ExpandYourPractice from "./components/expandYourPractice";
import SchemaComponent from "components/CBSchema";

// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import { capitalizeFirstLetterOfEachWord } from "utils/helpers/capitalizeFirstLetterOfEachWord";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import { SearchBar } from "utils/context/searchBar.context";
import { SearchBarProvider } from "utils/context/searchBar.context";
// Hooks
import useFetchGHLContacts from "pages/FindATherapist/hooks/useFetchContacts.hooks";
// Data
import stateContent from "./pageContent.data.json";
import { GHLUsersContext } from "utils/context/ghlUsers.context";
import { DirectorySearchBar } from "utils/context/directorySearchBar.context";
import { therapistFilterFunction } from "utils/helpers/searchBarFilters/therapist.filter";
import Page404 from "pages/404";

const TherapyStateProviders = () => {
  // const { users, isLoading, isError, error } = useFetchGHLContacts();
  const { users, isLoading, isError, error } = useContext(GHLUsersContext);
  const { setFilterOptions, setSearchTerms, setPage } = useContext(DirectorySearchBar);
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [state, setState] = useState("");
  const [stateInfo, setStateInfo] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const foundState = stateContent.find((stateObj) => location.pathname.includes(stateObj.state));
    
    if (foundState) {
      setState(foundState.state);
      setStateInfo(foundState);
      let capitalizedState = capitalizeFirstLetterOfEachWord(foundState.state);
      setFilterOptions({ "Licensed To Practice In": capitalizedState });
      setSearchTerms("");
      setPage(1);
    } else {
      setStateInfo(null);
    }
  }, [location.pathname]);

  

  useEffect(() => {
    if (users?.contacts.length >= 0 && state) {
      // const filteredProvidersByStates = users.contacts.filter((item) => item["state"]?.toLowerCase() === state.toLowerCase());
      // setProviders(filteredProvidersByStates);
      setProviders(users.contacts);
    }
  }, [state, users]);

  if (!stateInfo) return <Page404 />;

  return (
    <SearchBarProvider initialData={providers} filterFunction={therapistFilterFunction}>
      <TherapyStateProvidersContent state={state} providers={providers} total={users?.total} isLoading={isLoading} isError={isError} error={error} />
    </SearchBarProvider>
  );
};

const TherapyStateProvidersContent = ({ state, providers, total, isLoading, isError, error }) => {
  const location = useLocation();
  const { filteredData } = useContext(SearchBar);
  const [containerId, setContainerId] = useState("ketamine-therapy-state-provider-container");
  const [schemaData, setSchemaData] = useState([]);
  const capitalizedState = capitalizeFirstLetterOfEachWord(state);

  useEffect(() => {
    if (state) {
      const dynamicSchema = constructSchemaData(`https://www.almadelic.com${location.pathname}`, [
        { name: "Home", url: "/" },
        { name: "Ketamine Therapy", url: "/ketamine-therapy" },
        { name: `Ketamine Therapy For ${capitalizedState}`, url: `/ketamine-therapy/${state}` },
        { name: `Ketamine Therapy Providers In ${capitalizedState}`, url: `/ketamine-therapy/${state}/providers` },
      ]);
      setContainerId(`ketamine-therapy-${state}-provider-container`);
      setSchemaData(dynamicSchema);
    }
  }, [state, location.pathname]);

  return (
    <StyledMainPageContainer id={containerId}>
      <StyledMainPageContainer id={containerId}>
        <SchemaComponent schemas={schemaData} />
        <SEO
          title={`Search & Connect with Ketamine Therapy Providers in ${capitalizedState} `}
          description={`Browse the licensed clinicians behind Almadelic's at home ketamine-assisted therapy in ${capitalizeFirstLetterOfEachWord(
            state
          )}. Our network of expert partner providers in ${capitalizeFirstLetterOfEachWord(
            state
          )} are here to guide you through your personalized ketamine treatment.`}
          canonicalUrl={`https://www.almadelic.com/ketamine-therapy/${state}/providers`}
        />
        {/* Page Header */}
        <StyledSectionBackgroundWrapper>
          <PageHeader
            header="Ketamine Therapy Providers \n"
            headerAccent={`in ${capitalizedState}`}
            lightText="true"
            headerTagline="Find the right therapist for you!"
            headerContent={`If you're seeking ketamine-assisted therapy to support your mental health journey, our curated list of licensed providers in ${capitalizedState} can help you get started. Ketamine therapy has been shown to effectively treat conditions such as depression, anxiety, PTSD, and more. Whether you're looking for at-home treatment or in-person sessions, this directory connects you with local ${capitalizedState} professionals who offer personalized, compassionate care. Explore our ${capitalizedState} providers and take the next step toward safe, medically supervised ketamine therapy.`}
            headerType="directory"
            filter={false}
          />
        </StyledSectionBackgroundWrapper>
        {/* Providers Section */}
        {providers ? (
          <ProfileCardContainer filteredResults={filteredData} total={total} isLoading={isLoading} isError={isError} error={error} />
        ) : (
          <CBLoading loadingItem={`${state} providers`} />
        )}
        <CBDivider />
        {/* Expand your practice - Section #3 */}
        <ExpandYourPractice currentState={state} />
      </StyledMainPageContainer>
    </StyledMainPageContainer>
  );
};

export default TherapyStateProviders;
