import React, { useState, useContext } from "react";
// Components
import MKButton from "components/MKButton";
// Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { DirectorySearchBar } from "utils/context/directorySearchBar.context";
const TAPagination = ({ totalItems, itemsPerPage, onPageChange, containerRef }) => {
    const { page, setPage } = useContext(DirectorySearchBar);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const { isSm } = useMediaQueries();

    const handleClick = (page) => {
        setPage(page);
        // onPageChange(page);
        setTimeout(() => {
        if (containerRef.current) {
            window.scrollTo({ top:600,behavior: "smooth" });
        }
        }, 1500);
    };

    const renderPageButtons = () => {
        const pagesToShow = isSm ? 2 : 4;
        const startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

        const pageButtons = [];
        for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
            <MKButton
            variant="outlined"
            color="secondary"
            key={i}
            onClick={() => handleClick(i)}
            disabled={page === i}
            style={{ marginRight: "5px" }}
            aria-label={`page ${i}`}
            >
            {i}
            </MKButton>
        );
        }
        return pageButtons;
    };

    return (
        <div>
        <MKButton aria-label="Previous Page" onClick={() => page > 1 && handleClick(page - 1)} disabled={page === 1}>
            <ChevronLeftIcon />
        </MKButton>
        {renderPageButtons()}

        <MKButton aria-label="Next Page" onClick={() => page < totalPages && handleClick(page + 1)} disabled={page === totalPages}>
            <ChevronRightIcon />
        </MKButton>
        </div>
    );
};

export default TAPagination;