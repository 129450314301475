import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const addCartItem = (cartItems, productToAdd) => {
  const isPackage = productToAdd.type === "package";
  const isAddOn = productToAdd.type === "add-on";

  const existingCartItem = cartItems.find((cartItem) => cartItem.id === productToAdd.id);
  const existingPackageItem = cartItems.find((cartItem) => cartItem.type === "package");
  const existingAddOnItem = cartItems.find((cartItem) => cartItem.type === "add-on" && cartItem.id === productToAdd.id);

  if (existingCartItem || (isPackage && existingPackageItem) || (isAddOn && existingAddOnItem)) {
    return null;
  }

  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

const removeCartItem = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id);

  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id);
  }

  return cartItems.map((cartItem) => (cartItem.id === cartItemToRemove.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem));
};

const validatePromoCode = (code, coupons) => {
  const coupon = coupons?.find((coupon) => coupon.name.toLowerCase() === code.toLowerCase());
  return coupon;
};

export const CartContext = createContext({
  cartItems: [],
  addItemToCart: () => {},
  removeItemFromCart: () => {},
  cartTotal: 0,
  cartCount: 0,
  existsInCart: false,
  setExistsInCart: () => {},
  promoCode: null,
  applyPromoCode: () => {},
  originalSubtotal: 0,
  couponNotValid: false,
  stripeCouponObj: {},
  setStripeCouponObj: () => {},
  isCartOpen: false,
  setIsCartOpen: () => {},
  setCouponNotValid: () => {},
  isCartEmpty: false,
  setIsCartEmpty: () => {},
  clearCart: () => {},
});

export const CartProvider = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [promoCode, setPromoCode] = useState(null);
  const [stripeCouponObj, setStripeCouponObj] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [originalSubtotal, setOriginalSubtotal] = useState(0);
  const [couponNotValid, setCouponNotValid] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const location = useLocation();
  // Load cart data from localStorage on initial load
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    const storedPromoCode = JSON.parse(localStorage.getItem("promoCode"));
    const storedDiscount = JSON.parse(localStorage.getItem("discount"));
    const storedStripeCouponObj = JSON.parse(localStorage.getItem("stripeCouponObj"));

    if (storedCartItems) setCartItems(storedCartItems);
    if (storedPromoCode) setPromoCode(storedPromoCode);
    if (storedDiscount) setDiscount(storedDiscount);
    if (storedStripeCouponObj) setStripeCouponObj(storedStripeCouponObj);
  }, []);

  // Save cart data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Save promoCode and discount to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("promoCode", JSON.stringify(promoCode));
    localStorage.setItem("discount", JSON.stringify(discount));
    localStorage.setItem("stripeCouponObj", JSON.stringify(stripeCouponObj));
  }, [promoCode, discount, stripeCouponObj]);

  // Update cart count and original subtotal when cartItems state changes
  useEffect(() => {
    const newCartCount = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
    const newOriginalSubtotal = cartItems.reduce((total, cartItem) => total + cartItem.quantity * cartItem.price, 0);
    setCartCount(newCartCount);
    setOriginalSubtotal(newOriginalSubtotal);
  }, [cartItems]);

  // Update discount and cart total when cartItems or discount state changes
  useEffect(() => {
    let discountValue = 0;
    if (promoCode && stripeCouponObj) {
      if (stripeCouponObj.amount_off) {
        discountValue = stripeCouponObj.amount_off;
      } else if (stripeCouponObj.percent_off) {
        discountValue = (stripeCouponObj.percent_off / 100) * originalSubtotal;
      }
    }
    setDiscount(discountValue);
    const newTotal = originalSubtotal - discountValue;
    setCartTotal(newTotal);
  }, [cartItems, promoCode, stripeCouponObj, originalSubtotal]);

  const addItemToCart = (productToAdd) => {
    const updatedCartItems = addCartItem(cartItems, productToAdd);
    if (updatedCartItems) {
      setCartItems(updatedCartItems);
    }
  };

  const removeItemFromCart = (cartItemToRemove) => {
    const updatedCartItems = removeCartItem(cartItems, cartItemToRemove);
    setCartItems(updatedCartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    setPromoCode(null);
    setDiscount(0);
    setStripeCouponObj(null);
  };

  useEffect(() => {
    setIsCartEmpty(cartItems.length === 0);
  }, [cartItems]);

  const applyPromoCode = (code, coupons) => {
    const coupon = validatePromoCode(code, coupons);
    if (coupon) {
      setStripeCouponObj(coupon);
      setPromoCode(code);
      setCouponNotValid(false);
    } else {
      setStripeCouponObj(null);
      setPromoCode(null);
      setDiscount(0);
      setCouponNotValid(true);
    }
  };

  const removePromoCode = () => {
    setPromoCode(null);
    setDiscount(0);
  };

  // Reset couponNotValid when navigating away from the shop page or closing the cart
  useEffect(() => {
    return () => {
      setCouponNotValid(false);
    };
  }, [location, isCartOpen]);

  const value = {
    addItemToCart,
    cartItems,
    cartCount,
    removeItemFromCart,
    cartTotal,
    originalSubtotal,
    applyPromoCode,
    promoCode,
    removePromoCode,
    couponNotValid,
    discount,
    stripeCouponObj,
    isCartOpen,
    setIsCartOpen,
    setCouponNotValid,
    isCartEmpty,
    setIsCartEmpty,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
