import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { CartContext } from "utils/context/cart.context";

const ShoppingCart = ({ paddingLeft }) => {
  const buttonStyle = {
    backgroundColor: "transparent",
    border: "1px solid transparent",
    cursor: "pointer",
    padding: paddingLeft === "true" && "0 0 0 10px",
    position: "relative",
    zIndex: "1",
  };

  const cartCountStyle = {
    position: "absolute",
    top: "-10px",
    right: "-5px",
    backgroundColor: "#F77C3A",
    border: "1px solid orange",
    padding: ".1rem",
    width: "1.2rem",
    height: "1.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    color: "#fff",
    zIndex: "-1",
  };

  const { cartItems, setIsCartOpen } = useContext(CartContext);

  return (
    <AnimatePresence>
      {cartItems.length > 0 && (
        <motion.button
          style={buttonStyle}
          onClick={() => setIsCartOpen(true)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <motion.div
            style={cartCountStyle}
            animate={{
              scale: [1, 1.2, 1, 1.1, 1.2, 1.1, 1],
              rotate: [0, 10, -10, 0, 10, -10, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatDelay: 5,
            }}
          >
            {cartItems.length}
          </motion.div>
          <ShoppingCartOutlinedIcon sx={{ color: "#F77C3A", height: "28px", width: "28px" }} />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default ShoppingCart;
