import React from "react";
import { useLocation } from "react-router-dom";
// Components
import { StyledMainPageContainer } from "index.styles";
import SEO from "components/CBSEO";
import { Container } from "@mui/material";
import CBLoading from "components/CBLoading";
import SchemaComponent from "components/CBSchema";
// Utils
import useFetchProducts from "pages/Shop/hooks/useFetchProducts";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import seoData from "./seo.data.json";
import productSchemaData from "./schema.organization.json";
import ProductDetails from "./components/product-details/newIndex";

const getSeoDataForProduct = (productName) => {
  return seoData[productName] || {};
};

const getSchemaDataForProduct = (productName) => {
  return productSchemaData[productName] || {};
};

const getProductNameFromUrl = (location) => {
  const pathArray = location.pathname.split("/");
  return pathArray[pathArray.length - 1];
};

const filterProductsByUrlSlug = (products, urlSlug, urlPath) => {
  const matchedProduct = products.find((product) => {
    const slugParts = urlSlug.split("-").join(" ").toLowerCase();
    return product.name.toLowerCase().includes(slugParts);
  });

  if (matchedProduct) {
    return {
      id: matchedProduct.id,
      name: matchedProduct.name,
      description: matchedProduct.metadata.long_description,
      image: matchedProduct.images[0],
      price: (matchedProduct.price / 100).toFixed(2),
      url: `https://www.almadelic.com${urlPath}`,
    };
  } else {
    return null;
  }
};

const ProductPage = () => {
  const { products, loading, error } = useFetchProducts();
  const location = useLocation();
  const productName = getProductNameFromUrl(location);

  const seoDataObject = getSeoDataForProduct(productName);
  const schemaDataObject = getSchemaDataForProduct(productName);
  const matchedProductObject = filterProductsByUrlSlug(products, productName, location.pathname);

  const schemaData = constructSchemaData(schemaDataObject.url, schemaDataObject.breadcrumbs, null, null, matchedProductObject);
  return (
    <StyledMainPageContainer id="product-page">
      {error ? (
        <Container sx={{ marginBlock: "4rem" }}></Container>
      ) : (
        <>
          {loading ? (
            <Container sx={{ marginBlock: "4rem" }}>
              <CBLoading loadingItem={"products"} />
            </Container>
          ) : (
            <>
              <SchemaComponent schemas={schemaData} />
              <SEO title={seoDataObject.title} description={seoDataObject.description} canonicalUrl={seoDataObject.canonicalUrl} />
              <ProductDetails products={products} />
            </>
          )}
        </>
      )}
    </StyledMainPageContainer>
  );
};

export default ProductPage;
