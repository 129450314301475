import React, { useEffect, useState, useRef } from "react";
import theme from "assets/theme";
// Framer Motion
import { motion, AnimatePresence } from "framer-motion";
// Components
import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import testimonialsData from "pages/Shop/data/testimonials.data.json";

// Animation objects
const fadeAnimation = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  transition: { duration: 2 },
};

const Testimonials = () => {
  const testimonialKeyRef = useRef(0);
  const [testimonialStep, setTestimonialStep] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTestimonialStep((prevStep) => (prevStep + 1) % testimonialsData.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);
  const { isMd, isLg } = useMediaQueries();

  return (
    <Stack
      key={testimonialKeyRef.current}
      direction={"row"}
      alignItems={"center"}
      justifyContent={isLg ? "flex-start" : "center"}
      spacing={isLg ? 4 : 2}
      marginTop={isLg ? "0rem" : isMd ? "2rem" : "4rem"}
      marginLeft={isLg ? "3rem" : 0}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={`testimonial-${testimonialStep}`}
          {...fadeAnimation}
          style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}
        >
          <MKBox
            component="img"
            sx={{
              objectFit: "contain",
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              boxShadow: "rgba(0, 0, 0, 0.3) 2px 4px 12px",
            }}
            src={testimonialsData[testimonialStep].image}
            alt={`${testimonialsData[testimonialStep].name} profile`}
          />
          <Stack spacing={1.5} sx={{ width: { xs: "100%", md: "75%", lg: "50%" } }}>
            <MKTypography
              component="p"
              variant={"caption"}
              sx={{
                fontSize: { xs: "12px", md: "16px", lg: "16px" },
                fontStyle: "italic",
                color: theme.palette.grey[800],
                opacity: 0.8,
                marginBottom: ".5rem !important",
              }}
            >
              "{testimonialsData[testimonialStep].quote}"
            </MKTypography>
            <MKTypography
              component="p"
              variant="body2"
              sx={{
                fontSize: "70%",
                color: theme.palette.accent.main,
                fontWeight: "bold",
              }}
            >
              - {testimonialsData[testimonialStep].name}
            </MKTypography>
          </Stack>
        </motion.div>
      </AnimatePresence>
    </Stack>
  );
};

export default Testimonials;
