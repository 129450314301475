import { useState, useEffect } from "react";
import axios from "axios";
import { generateEndpoint } from "utils/helpers/generateEndpoint";

const useFetchCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      const endpoint = generateEndpoint(
        process.env.REACT_APP_AWS_DEV_GET_STRIPE_COUPONS_ENDPOINT,
        process.env.REACT_APP_AWS_PROD_GET_STRIPE_COUPONS_ENDPOINT
      );
      try {
        const response = await axios.get(endpoint);
        setCoupons(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  return { coupons, loading, error };
};

export default useFetchCoupons;
