import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Alert, AlertTitle, List, ListItem, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { convertCamelCaseToWords } from "utils/helpers/convertCamelCase";
import theme from "assets/theme";
import { StyledAddressRecommendation } from "./index.styles";
import { StyledButtonContainer } from "index.styles";

const animationSettings = (isSelected) => ({
  initial: { opacity: 0.5 },
  animate: { opacity: isSelected ? 1 : 0.5 },
  transition: { duration: 0.3 },
});

const ListSection = ({ items, title, titleColor, handleAddressSelection }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    if (items.length > 0 && items[0].currentAddress) {
      handleAddressSelectionInternal("current", items[0].currentAddress);
    }
  }, [items]);

  const handleAddressSelectionInternal = (addressType, address) => {
    const structuredAddress = {
      shippingAddress: address.firstAddressLine || address.address,
      shippingAppartment: address.premise || "",
      shippingCity: address.city,
      shippingState: address.state,
      shippingZipCode: address.zipCode + "-" + address.zipCodeExtension || address.zipcode + "-" + address.zipCodeExtension,
      shippingZipCodeExtension: address.zipCodeExtension || "",
    };
    setSelectedAddress({ type: addressType, address: structuredAddress });
  };

  return (
    <>
      <AlertTitle>
        <MKTypography component="h2" variant="h4" sx={{ marginBottom: "1rem", color: `${titleColor} !important` }}>
          {title}
        </MKTypography>
      </AlertTitle>
      <Stack direction={"row"} spacing={4} sx={{ marginBottom: "2rem" }}>
        {items.length > 0 && (
          <List sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
            {items.map((item, index) => (
              <ListItem key={index}>
                <Stack spacing={1} direction={"column"} sx={{ width: "100%" }}>
                  {/* <MKTypography component="h3" variant="h6">
                    {item.message}
                  </MKTypography> */}
                  {item.action && (
                    <MKTypography component="p" variant="body2">
                      {item.action}
                    </MKTypography>
                  )}

                  {item.missingComponents && (
                    <Stack spacing={1}>
                      {item.missingComponents.map((component, index) => (
                        <MKTypography key={index} component="p" variant="body2">
                          Invalid or incomplete: <strong>{component}</strong>
                        </MKTypography>
                      ))}
                    </Stack>
                  )}
                  {item.unconfirmedComponents && (
                    <Stack spacing={1}>
                      {item.unconfirmedComponents.map((component, index) => (
                        <MKTypography key={index} component="p" variant="body2">
                          Unable to confirm: <strong>{component}</strong>
                        </MKTypography>
                      ))}
                    </Stack>
                  )}
                  {item.currentAddress && (
                    <StyledAddressRecommendation
                      isSelected={selectedAddress?.type === "current"}
                      {...animationSettings(selectedAddress?.type === "current")}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <input
                          type="radio"
                          name="addressSelection"
                          checked={selectedAddress?.type === "current" && selectedAddress.address.shippingAddress === item.currentAddress.address}
                          onChange={() => handleAddressSelectionInternal("current", item.currentAddress)}
                        />
                        <MKTypography component="p" variant="body2" sx={{ fontWeight: "bold" }}>
                          Your Current Address
                        </MKTypography>
                      </Stack>
                      <MKTypography component="p" variant="body2">
                        {item.currentAddress.address},<br />
                        {convertCamelCaseToWords(item.currentAddress.city)}, {item.currentAddress.state} {item.currentAddress.zipcode}
                      </MKTypography>
                    </StyledAddressRecommendation>
                  )}
                  {item.suggestedAddress && (
                    <StyledAddressRecommendation
                      isSelected={selectedAddress?.type === "suggested"}
                      {...animationSettings(selectedAddress?.type === "suggested")}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <input
                          type="radio"
                          name="addressSelection"
                          checked={
                            selectedAddress?.type === "suggested" &&
                            selectedAddress.address.shippingAddress === item.suggestedAddress.firstAddressLine
                          }
                          onChange={() => handleAddressSelectionInternal("suggested", item.suggestedAddress)}
                        />
                        <MKTypography component="p" variant="body2" sx={{ fontWeight: "bold" }}>
                          Recommended Address
                        </MKTypography>
                      </Stack>
                      <MKTypography component="p" variant="body2">
                        {item.suggestedAddress.firstAddressLine},<br />
                        {convertCamelCaseToWords(item.suggestedAddress.city)}, {item.suggestedAddress.state} {item.suggestedAddress?.zipCode}-
                        {item.suggestedAddress.zipCodeExtension}
                      </MKTypography>
                    </StyledAddressRecommendation>
                  )}
                  {item.buttonLabel && (
                    <StyledButtonContainer>
                      <MKButton
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAddressSelection(selectedAddress?.address)}
                        disabled={!selectedAddress}
                        sx={{ marginTop: "1rem" }}
                      >
                        {item.buttonLabel}
                      </MKButton>
                    </StyledButtonContainer>
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        )}
      </Stack>
    </>
  );
};

const CustomAlert = ({ errors, suggestions, handleAddressSelection }) => {
  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        marginBottom: "1rem",
        boxShadow:
          "0rem 0.1875rem 0.1875rem 0rem rgba(0, 0, 0, 0.15), 0rem 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2), 0rem 0.0625rem 0.3125rem 0rem rgba(0, 0, 0, 0.15)",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ backgroundColor: "#FDF0EE" }}>
        <Alert
          severity="error"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MKTypography component="span" sx={{ fontSize: "13px" }}>
            We noticed an error with your address while checking out
          </MKTypography>
        </Alert>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingBlock: "1rem", "&.MuiAccordionDetails-root": { boxShadow: "none !important" } }}>
        {errors.length > 0 && (
          <ListSection items={errors} title="Error Details" titleColor={theme.palette.error.main} handleAddressSelection={handleAddressSelection} />
        )}
        {suggestions.length > 0 && (
          <ListSection
            items={suggestions}
            title="Suggestions to Fix the Error"
            titleColor={theme.palette.success.main}
            handleAddressSelection={handleAddressSelection}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAlert;
