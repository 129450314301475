import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import MKBox from "components/MKBox";

export const StyledCartSideBarContainer = styled(MKBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: "1rem",
  borderRadius: "12px",
}));

export const StyledPromoCodeContainer = styled(Stack)(({ theme }) => ({
  marginBottom: "2rem",
  backgroundColor: theme.palette.primary.main,
  padding: "1rem",
  borderRadius: "8px",
}));
