import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CBDivider from "components/CBDivider";

const ProductAccordion = ({ titles, descriptions }) => {
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  return (
    <List>
      <ListItem sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
        {titles.map((title, index) => (
          <Accordion
            key={index}
            expanded={expandedAccordion === index}
            onChange={handleAccordionChange(index)}
            sx={{
              background: "transparent",
              boxShadow: "none",
              width: "100%",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                border: "none",
                padding: 0,
                "&.Mui-expanded": {
                  minHeight: "35px !important",
                  maxHeight: "35px",
                },
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <MKBox
                  component="img"
                  sx={{ width: "24px" }}
                  src="https://shop.almadelic.com/wp-content/uploads/2024/03/wbsite-icon.png"
                  alt="Almadelic butterfly bullet point icon"
                />
                <MKTypography className="bullet-point-title" component="p" variant="body2">
                  {title}
                </MKTypography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingBlock: 0 }}>
              <CBDivider />
              <MKTypography className="bullet-point-description" component="p" variant="body2">
                {descriptions[index]}
              </MKTypography>
            </AccordionDetails>
          </Accordion>
        ))}
      </ListItem>
    </List>
  );
};

export default ProductAccordion;
