import React, { useContext, useMemo, useState } from "react";
import theme from "assets/theme";
// Components
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
import CBLoading from "components/CBLoading";
import ProductSection from "./components/productSection";
import HowItWorks from "components/CBHowItWorks";
import ShopFaq from "./components/FaqSection";
import ShopBanner from "./components/Slider";
import Addons from "./components/Addons";
import CBDivider from "components/CBDivider";

// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Animations
import { listVariants } from "styles/animations/staggeredList.animations";
import { listItemShopBannerVariants } from "styles/animations/staggeredList.animations";

// Data
import seoData from "./seo.data.json";
import shopSchemaData from "./schema.organization.json";
import howItWorksData from "./data/howItWorks.data.json";
// Utils & Hooks
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import useImagePreloader from "utils/hooks/useImagePreloader";
import useFetchProducts from "./hooks/useFetchProducts";
import { CartContext } from "utils/context/cart.context";

const ShopPage = () => {
  const schemaData = constructSchemaData(shopSchemaData.url, shopSchemaData.breadcrumbs);
  const { packages, addons, loading, error } = useFetchProducts();
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Preload the banner image
  useImagePreloader(["https://d3a0rs3velfrz.cloudfront.net/shop-assets/shop-banner.webp"]);

  const sortedPackages = useMemo(() => {
    const newPatientPackages = [];
    const otherPackages = [];

    packages.forEach((pkg) => {
      if (pkg.metadata.forPatientType?.toLowerCase() === "new patients") {
        newPatientPackages.push(pkg);
      } else {
        otherPackages.push(pkg);
      }
    });

    return [...newPatientPackages, ...otherPackages];
    // return [...newPatientPackages, ...otherPackages, ...addons];
  }, [packages]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (loading) {
    return (
      <StyledSectionContainer cssProps={{ minHeight: "60vh" }}>
        <CBLoading loadingItem={"products"} />
      </StyledSectionContainer>
    );
  }

  return (
    <StyledMainPageContainer sx={{ position: "relative" }} id="shop-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <ShopBanner />
      <ProductSection selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} sortedPackages={sortedPackages} />
      <Addons addons={addons} addonType={"info-card"} />
      <StyledSectionContainerColorWrapper flipBackground={true}>
        <HowItWorks
          motionProps={{
            variants: {
              listItem: listItemShopBannerVariants,
              list: listVariants,
              animate: "show",
              initial: "hidden",
            },
          }}
          content={howItWorksData}
        />
        <CBDivider marginZero={true} color={theme.palette.accent.main} />
        <ShopFaq />
      </StyledSectionContainerColorWrapper>
    </StyledMainPageContainer>
  );
};

export default ShopPage;
