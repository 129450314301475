import React, { Fragment, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import { OrderSummaryContainer } from "./index.styles";
const OrderSummary = ({ discount, originalSubtotal, cartTotal, textColor, cartItems, nextPayment, stripeCouponObj, couponId }) => {
  const [discountedNextPayment, setDiscountedNextPayment] = useState();
  const applyCouponDiscount = (amount, stripeCouponObj) => {
    if (!stripeCouponObj) return amount;

    const { amount_off, percent_off } = stripeCouponObj;

    if (amount_off) {
      return amount - amount_off;
    } else if (percent_off) {
      return amount - amount * (percent_off / 100);
    }

    return amount;
  };

  const cartItemsTotal = cartItems
    ?.filter((item) => item.metadata?.type === "package")
    .reduce((total, item) => total + item.price * item.quantity, 0);

  const originalNextPayment = cartItemsTotal || nextPayment;

  useEffect(() => {
    setDiscountedNextPayment(applyCouponDiscount(originalNextPayment, stripeCouponObj));
  }, [stripeCouponObj]);

  return (
    <Fragment>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography
          component="p"
          variant="h6"
          sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
        >
          Subtotal
        </MKTypography>
        <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
          ${(originalSubtotal / 100).toFixed(2)}
        </MKTypography>
      </Stack>
      {discount !== null && discount !== 0 && (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
          <MKTypography
            component="p"
            variant="h6"
            sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
          >
            Discount
          </MKTypography>
          <MKTypography component="small" variant="body2" sx={{ color: theme.palette.success.main }}>
            -${(discount / 100)?.toFixed(2)}
          </MKTypography>
        </Stack>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginBottom: "1rem" }}>
        <MKTypography component="p" variant="h6" sx={{ color: textColor === "accent" && `${theme.palette.accent.main} !important` }}>
          Today's Total
        </MKTypography>
        <MKTypography
          sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important`, fontWeight: "800" }}
          component="small"
          variant="body2"
        >
          ${(cartTotal / 100).toFixed(2)}
        </MKTypography>
      </Stack>
      <CBDivider />

      {(cartItemsTotal || nextPayment) && (
        <OrderSummaryContainer>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
            <MKTypography
              component="p"
              variant="h6"
              sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
            >
              Next Payment
            </MKTypography>

            <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
              {`$${((stripeCouponObj ? discountedNextPayment : originalNextPayment) / 100).toFixed(2)}`}
            </MKTypography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
            <MKTypography
              component="p"
              variant="h6"
              sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important` }}
            >
              Due Next Month
            </MKTypography>
            <MKTypography sx={{ color: textColor === "accent" && `${theme.palette.white.main} !important` }} component="small" variant="body2">
              {(() => {
                const today = new Date();
                const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
                return nextMonth.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
              })()}
            </MKTypography>
          </Stack>
        </OrderSummaryContainer>
      )}
    </Fragment>
  );
};

export default OrderSummary;
