import { Grid, TextField, MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import MKTypography from "components/MKTypography";
import countryList from "react-select-country-list";
import StateOptions from "utils/helpers/stateOptions";
import useGooglePlacesAutocomplete from "pages/Checkout/hooks/useGooglePlacesAutocomplete";

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: "1rem",
    },
  },
};
const inputStyles = {
  // Common styles for input elements
  input: {
    color: "black !important",
  },
};

const selectStyles = {
  ".MuiSelect-select": {
    color: "black !important",
  },
  height: "45px",
};

const ContactInformation = () => {
  const { errors, touched, handleChange, setFieldValue } = useFormikContext();
  const countries = countryList().getData();

  const addressInputRef = useGooglePlacesAutocomplete(setFieldValue, {
    address: "shippingAddress",
    city: "shippingCity",
    state: "shippingState",
    zip: "shippingZipCode",
  });

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pb: "2rem" }}>
      <Grid container item>
        <Grid item xs={11.5}>
          <MKTypography component="h4" variant="h4" sx={{ display: "block" }}>
            1. Contact Information
          </MKTypography>

          <Field
            sx={{ ...inputStyles }}
            as={TextField}
            label="Email address"
            name="email"
            type="email"
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field
            sx={{ ...inputStyles, width: { xs: "100%", md: "50%" }, paddingRight: { xs: 0, md: "8px" } }}
            as={TextField}
            label="First name"
            name="shippingFirstName"
            type="text"
            error={touched.shippingFirstName && !!errors.shippingFirstName}
            helperText={touched.shippingFirstName && errors.shippingFirstName}
            variant="outlined"
            margin="normal"
          />
          <Field
            sx={{ ...inputStyles, width: { xs: "100%", md: "50%" } }}
            as={TextField}
            label="Last name"
            name="shippingLastName"
            type="text"
            error={touched.shippingLastName && !!errors.shippingLastName}
            helperText={touched.shippingLastName && errors.shippingLastName}
            variant="outlined"
            margin="normal"
          />
          <Field
            sx={{ ...inputStyles }}
            as={TextField}
            label="Phone"
            name="shippingPhone"
            type="text"
            error={touched.shippingPhone && !!errors.shippingPhone}
            helperText={touched.shippingPhone && errors.shippingPhone}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field
            sx={{ ...inputStyles }}
            as={TextField}
            label="Shipping Address"
            name="shippingAddress"
            type="text"
            error={touched.shippingAddress && !!errors.shippingAddress}
            helperText={touched.shippingAddress && errors.shippingAddress}
            variant="outlined"
            fullWidth
            margin="normal"
            inputRef={addressInputRef} //! google autocomplete ref required to use autocomplete on field
          />

          <Field
            sx={{ ...inputStyles }}
            as={TextField}
            label="Shipping Appartment, suite, etc. (optional)"
            name="shippingAppartment"
            type="text"
            error={touched.shippingAppartment && !!errors.shippingAppartment}
            helperText={touched.shippingAppartment && errors.shippingAppartment}
            variant="outlined"
            fullWidth
            optional="true"
            margin="normal"
          />
          <Field
            sx={{
              ...inputStyles,
            }}
            name="shippingCountry"
          >
            {({ field }) => (
              <FormControl fullWidth variant="outlined" margin="normal" error={touched.shippingCountry && !!errors.shippingCountry}>
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  sx={{
                    ...selectStyles,
                  }}
                  labelId="country-label"
                  label="Country"
                  {...field}
                  onChange={handleChange(field.name)}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Field>
          <Field
            sx={{ ...inputStyles }}
            as={TextField}
            label="Shipping City"
            name="shippingCity"
            type="text"
            error={touched.shippingCity && !!errors.shippingCity}
            helperText={touched.shippingCity && errors.shippingCity}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Field sx={{ ...inputStyles }} name="shippingState">
            {({ field }) => (
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                error={touched.shippingState && !!errors.shippingState}
                sx={{
                  width: { xs: "100%", md: "50%" },
                  paddingRight: { xs: 0, md: "8px" },
                }}
              >
                <InputLabel id="state-label">Shipping State</InputLabel>
                <Select
                  MenuProps={menuProps}
                  sx={{
                    ...selectStyles,
                  }}
                  labelId="state-label"
                  label="Shipping State"
                  {...field}
                  onChange={field.onChange}
                >
                  {StateOptions.map((state) => (
                    <MenuItem key={state.name} value={state.abbreviation}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.shippingState && errors.shippingState && <FormHelperText>{errors.shippingState}</FormHelperText>}
              </FormControl>
            )}
          </Field>
          <Field
            sx={{ ...inputStyles, width: { xs: "100%", md: "50%" } }}
            as={TextField}
            label="Shipping Zip Code"
            name="shippingZipCode"
            type="text"
            error={touched.shippingZipCode && !!errors.shippingZipCode}
            helperText={touched.shippingZipCode && errors.shippingZipCode}
            variant="outlined"
            margin="normal"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
