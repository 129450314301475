import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import InfoAddonCard from "./infoCard";
import ProductAddonCard from "./productCard";
// utils
import { CartContext } from "utils/context/cart.context";
import { scrollToTop } from "utils/helpers/scrollToTop";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";

const cardStyleMap = {
  "product-card": ProductAddonCard,
  "info-card": InfoAddonCard,
};

const AddOnsCard = ({ index, addons, name, price, id, description, cardStyle, images, metadata, selectedId, setSelectedId, allAddonsHidden }) => {
  const navigate = useNavigate();
  const { addItemToCart, cartItems, setIsCartOpen } = useContext(CartContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isItemInCart, setIsItemInCart] = useState(false);
  const checkForConflicts = () => {
    const conflictingItems = cartItems.filter((item) => item.name === name);
    setShowAlert(true);
    setAlertMessage(`Already added to cart.`);
    return conflictingItems.length > 0;
  };

  useEffect(() => {
    const test = checkForConflicts();
    setIsItemInCart(test);
  }, [cartItems]);

  const handleReadMoreClick = (index, e) => {
    e.stopPropagation();
    setExpandedItems((prev) => {
      const newExpandedItems = [...prev];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };
  const handleAddToCart = (e, index) => {
    e.stopPropagation();
    addItemToCart(addons[index]);
    setIsCartOpen(true);

    const cartItemsContainer = document.getElementById("cart-items-container");
    const cartContainer = document.getElementById("cart-container");

    if (cartItemsContainer && cartContainer) {
      const offsetTop = cartItemsContainer.offsetTop;
      const offset = -100;

      cartContainer.scrollTo({
        top: offsetTop + offset,
        behavior: "smooth",
      });
    }
  };

  const handleNavigateTo = (e, name) => {
    setIsCartOpen(false);
    navigate(`/ketamine-therapy/packages/${formatTitleForURL(name)}`, { state: { productId: e.currentTarget.id } });
    scrollToTop();
  };

  const cardProps = {
    index,
    name,
    price,
    id,
    description,
    isItemInCart,
    handleAddToCart,
    showAlert,
    setShowAlert,
    alertMessage,
    handleReadMoreClick,
    expandedItems,
    handleNavigateTo,
    addons,
    images,
    metadata,
    selectedId,
    setSelectedId,
    allAddonsHidden,
  };

  const CardComponent = cardStyleMap[cardStyle] || ProductAddonCard;

  return <CardComponent {...cardProps} />;
};

export default AddOnsCard;
