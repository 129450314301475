import shopFaq from "./shopFaq.json";
import generalFaq from "./faq.json";

const allFaqs = [...shopFaq, ...generalFaq];

export { shopFaq, generalFaq, allFaqs };

export const getFaqByCategory = (category) => {
  switch (category.toLowerCase()) {
    case "shop":
      return shopFaq;
    case "general":
      return generalFaq;
    default:
      return allFaqs;
  }
};

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export const searchFaqs = (query, category = "all") => {
  const faqsToSearch = category === "all" ? allFaqs : getFaqByCategory(category);
  // Normalize query to an array of strings
  const queries = Array.isArray(query) ? query : [query];
  // Map FAQs to include match count
  const matchedFaqs = faqsToSearch.flatMap((category) =>
    category.FAQs.map((faq) => {
      const matchCount = queries.reduce((count, q) => {
        // if (faq.question?.toLowerCase()?.includes(q.toLowerCase())) count++;
        // if (faq?.answer?.some((a) => a.toLowerCase().includes(q.toLowerCase()))) count++;
        if (faq.keyTerms?.some((term) => term.toLowerCase().includes(q.toLowerCase()))) count++;
        return count;
      }, 0);

      return { ...faq, matchCount };
    })
  );

  // Filter out FAQs with no matches and sort by match count
  const filteredFaqs = matchedFaqs.filter((faq) => faq.matchCount > 0).sort((a, b) => b.matchCount - a.matchCount);

  // If no results are found, return 5 random FAQs
  if (filteredFaqs.length === 0) {
    return shuffleArray(faqsToSearch.flatMap((category) => category.FAQs)).slice(0, 5);
  }

  // If results are less than 5, add random FAQs until we have 5
  if (filteredFaqs.length < 5) {
    const remainingFaqs = faqsToSearch.flatMap((category) => category.FAQs).filter((faq) => !filteredFaqs.some((f) => f.question === faq.question));

    const randomFaqs = shuffleArray(remainingFaqs);
    const uniqueRandomFaqs = [];

    for (const faq of randomFaqs) {
      if (!filteredFaqs.some((f) => f.question === faq.question) && !uniqueRandomFaqs.some((f) => f.question === faq.question)) {
        uniqueRandomFaqs.push(faq);
        if (filteredFaqs.length + uniqueRandomFaqs.length === 5) break;
      }
    }

    return [...filteredFaqs, ...uniqueRandomFaqs];
  }

  // If we have 5 or more results, return the top 5
  return filteredFaqs.slice(0, 5);
};
