import React from "react";
import { Link } from "react-router-dom";
// Components
import MKButton from "components/MKButton";
import { Grid } from "@mui/material";
import ListComponent from "components/CBList";
import MKTypography from "components/MKTypography";
// Sytled Components
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";
import { StyledSectionContainer } from "styles/components";
import { StyledButtonContainer } from "index.styles";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const data = [
  "Increase visibility with patients looking for psychedelic providers",
  "Share your background, specialties, and treatment approaches",
  "Control your availability for virtual appointments",
  "Receive inquiries directly from prospective patients",
];

const LeftComponent = () => {
  const { isLg, isMd } = useMediaQueries();

  return (
    <Grid item xs={10} lg={4} order={isLg ? 1 : 0}>
      <ImageContainer
        cssProps={{
          height: isMd ? "470px" : "100%",
        }}
        image="https://almadelic-assets.s3.us-east-2.amazonaws.com/findATherapist/Become+a+KAP+provier.webp"
        alt="Patient meeting and shaking the hand of their professional therapist."
      />
    </Grid>
  );
};

const RightComponent = () => {
  const { isLg } = useMediaQueries();

  return (
    <Grid item xs={10} lg={7} order={isLg ? 0 : 1}>
      <HeaderContainer
        textColor="#1D3A6D"
        highlightColor="#75CDC5"
        component="h2"
        variant="h2"
        title="Are you a therapist Or Facilitator? \n Expand Your Practice At Almadelic"
        highlightText={["Expand Your Practice At Almadelic"]}
        cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
      />
      
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
      Are you interested in providing psychedelic therapy or facilitation? Take advantage of our directory to create your free profile on Almadelic to reach more patients.
      </MKTypography>
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
        By listing your practice on our platform, you can:
      </MKTypography>

      <ListComponent listItems={data} />
      <StyledButtonContainer>
        <MKButton aria-label="Learn more" component={Link} to="/partners" color="secondary">
          Learn More
        </MKButton>
      </StyledButtonContainer>
    </Grid>
  );
};

const AreYouATherapist = () => {
  return (
    <StyledSectionContainer id="are-you-a-therapist-section">
      <TwoColumnSection
        rowSpacing={0}
        columnSpacing={0}
        rowGap={6}
        columnGap={6}
        leftComponent={<LeftComponent />}
        rightComponent={<RightComponent />}
      />
    </StyledSectionContainer>
  );
};

export default AreYouATherapist;
