import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "assets/theme";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";

// Components
import { Alert, Container, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import CBLoading from "components/CBLoading";
import ContactInformation from "./components/contactInformation";
import BillingAddress from "./components/billingAddress";
import PaymentOptions from "./components/paymentOptions";
import CartSidebar from "pages/Shop/components/Cart/components/cartSidebar";
import CustomAlert from "./components/addressValidationAlert";
// Styled components
import { StyledMainPageContainer } from "index.styles";
import { StyledCheckoutContainer } from "./index.styles";
// Formik
import { Formik, Form } from "formik";
// Utils & Hooks
import { CartContext } from "utils/context/cart.context";
import { CheckoutContext } from "utils/context/checkout.context";
import { constructPurchaseMadeData } from "utils/helpers/constructPurchaseMadeData";
import { scrollToTop } from "utils/helpers/scrollToTop";
import useMediaQueries from "utils/mediaQueries.utils";
import useStripeTransaction from "pages/Checkout/hooks/useStripeTransaction";
import usePurchaseMade from "./hooks/usePurchaseMade";
import useCreateStripeAccount from "./hooks/useCreateStripeAccount";
import useGoogleAddressValidationSimplified from "./hooks/useGoogleAddressValidationSimplified";
import { initialValues, validationSchema, validationSchema2 } from "./formSchema";

const Checkout = () => {
  const navigate = useNavigate();
  const { isLg } = useMediaQueries();
  const { validateAddress, addressValidationLoading, addressValidationError } = useGoogleAddressValidationSimplified();
  const { stripeAccountLoading, stripeAccountError, createStripeAccount } = useCreateStripeAccount();
  const { processPayment, stripeTransactionLoading, stripeTransactionError } = useStripeTransaction();
  const { purchaseMade, purchaseAutomationLoading, purchaseAutomationError } = usePurchaseMade();
  const [checkoutErrorStatus, setCheckoutErrorStatus] = useState(null);
  // ! state values for address validation alert component
  const [addressErrors, setAddressErrors] = useState([]);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const { cartItems, cartTotal, stripeCouponObj, discount, originalSubtotal } = useContext(CartContext);

  const { showBillingAddressFormFields } = useContext(CheckoutContext);
  const hasPackageItem = cartItems.some((item) => item.metadata?.type === "package");
  const handleSubmit = async (values) => {
    scrollToTop();
    setCheckoutErrorStatus(null);
    try {
      //* Google Address Validation
      const addressValidationResponse = await validateAddress(values);
      if (!addressValidationResponse) {
        setCheckoutErrorStatus(addressValidationError || "Error validating address.");
        return;
      }
      const { isValid, errors, suggestions } = addressValidationResponse;

      if (!isValid) {
        setAddressErrors(errors);
        setAddressSuggestions(suggestions);
        return;
      } else if (isValid) {
        setAddressErrors(null);
        setAddressSuggestions(null);
      }

      //* Stripe Account Creation
      const purchaseMadeDetails = constructPurchaseMadeData(values, cartItems, cartTotal, originalSubtotal);
      const stripeAccountResponse = await createStripeAccount(purchaseMadeDetails);
      if (!stripeAccountResponse) {
        setCheckoutErrorStatus(stripeAccountError || "Error creating account.");
        return; // stop form submission if payment fails
      }
      // //! Add error handling here
      //* Stripe paymentIntent'
      const paymentResponse = await processPayment(values, cartTotal, stripeCouponObj, discount, originalSubtotal, cartItems, stripeAccountResponse);
      const { paymentResults, subscription } = paymentResponse;
      if (!paymentResults && !subscription) {
        setCheckoutErrorStatus(stripeTransactionError || "Error processing payment.");
        return;
      }

      //* Transaction Automation
      const purchaseMadeAutomationDetails = constructPurchaseMadeData(values, cartItems, cartTotal, originalSubtotal, paymentResults, subscription);
      const automationResponse = await purchaseMade(purchaseMadeAutomationDetails);
      if (!automationResponse) {
        setCheckoutErrorStatus(purchaseAutomationError || "An error occurred while notifying the server about the purchase.");
      } else {
        navigate("/order-confirmation", {
          state: {
            subscriptionPaymentIntentId: subscription?.latest_invoice?.payment_intent?.id,
            addonPaymentIntentId: paymentResults?.id,
          },
        });
      }
    } catch (error) {
      setCheckoutErrorStatus(error.message);
    } finally {
      setCheckoutErrorStatus(null);
    }
  };

  const handleNavigate = () => {
    navigate("/ketamine-therapy/packages");
  };

  const getLoadingMessage = () => {
    if (addressValidationLoading) return "validating your address";
    if (stripeAccountLoading) return "creating your account";
    if (stripeTransactionLoading) return "processing your payment";
    if (purchaseAutomationLoading) return "sending transaction details to crm";
    return null;
  };
  const getErrorMessage = () => {
    if (addressValidationError) return addressValidationError?.message || "Error validating address. Please try again.";
    if (stripeAccountError) return stripeAccountError;
    if (stripeTransactionError) return stripeTransactionError;
    if (purchaseAutomationError) return purchaseAutomationError?.message || "An error occurred while notifying the server about the purchase.";
    return null;
  };

  return (
    <StyledMainPageContainer id="checkout-page">
      <Helmet>
        <script
          async
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`}
        ></script>
      </Helmet>
      {(addressValidationLoading || stripeAccountLoading || stripeTransactionLoading || purchaseAutomationLoading || checkoutErrorStatus) && (
        <Container sx={{ marginBlock: "4rem" }}>
          <CBLoading loadingItem={getLoadingMessage()} type="checkout" />
          {/* {checkoutErrorStatus && <Alert severity="error">{checkoutErrorStatus}</Alert>} */}
        </Container>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={showBillingAddressFormFields ? validationSchema2 : validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ isSubmitting, errors, touched, validateForm, setFieldValue, submitForm }) => {
          const handleAddressSelection = (selectedAddress) => {
            if (selectedAddress) {
              const addressFields = {
                shippingAddress: selectedAddress.shippingAddress,
                shippingAppartment: selectedAddress.shippingAppartment,
                shippingCity: selectedAddress.shippingCity,
                shippingState: selectedAddress.shippingState,
                shippingZipCode: selectedAddress.shippingZipCode,
                shippingZipCodeExtension: selectedAddress.shippingZipCodeExtension,
              };

              Object.keys(addressFields).forEach((key) => {
                setFieldValue(key, addressFields[key]);
              });
              setFieldValue("isAddressManuallyConfirmed", true);
              submitForm();
            }
          };
          return (
            <Form
              style={{
                display: addressValidationLoading || stripeAccountLoading || stripeTransactionLoading || purchaseAutomationLoading ? "none" : "block",
              }}
            >
              <StyledCheckoutContainer>
                <MKTypography component="h1" variant="h1" sx={{ mb: 2 }}>
                  Checkout
                </MKTypography>
                <Grid container spacing={2}>
                  <CartSidebar cartTotal={cartTotal} cartItems={cartItems} />
                  <Grid item xs={12} lg={8} order={isLg ? 0 : 1}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.grey[400]}`,
                        padding: "1rem",
                        borderRadius: "12px",
                      }}
                    >
                      <Grid item>
                        {!hasPackageItem && (
                          <Alert sx={{ marginBottom: "1rem" }} severity="warning">
                            You must have a package in your cart to checkout.{" "}
                            <HashLink
                              style={{ color: theme.palette.secondary.main }}
                              scroll={(el) => {
                                const yOffset = -100;
                                const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
                                window.scrollTo({ top: y, behavior: "smooth" });
                              }}
                              to={"/ketamine-therapy/packages#product-details-section"}
                            >
                              <br /> Click here to view packages.
                            </HashLink>
                          </Alert>
                        )}
                        {(addressErrors?.length > 0 || addressSuggestions?.length > 0) && (
                          <CustomAlert errors={addressErrors} suggestions={addressSuggestions} handleAddressSelection={handleAddressSelection} />
                        )}
                        {getErrorMessage() && (
                          <Alert sx={{ marginBottom: "1rem" }} severity="error">
                            {getErrorMessage()}
                          </Alert>
                        )}

                        <ContactInformation />
                      </Grid>
                      <Grid item>
                        <BillingAddress />
                      </Grid>
                      <Grid item>
                        <PaymentOptions />
                      </Grid>
                      <Grid item>
                        <MKBox
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "1p solid red",
                            marginTop: "2rem",
                            maxWidth: "717px",
                          }}
                        >
                          <MKButton onClick={handleNavigate} variant="contained" color="secondary">
                            Continue Shopping
                          </MKButton>

                          <MKButton disabled={isSubmitting || !hasPackageItem} type="submit" variant="contained" color="secondary">
                            {isSubmitting ? "Processing..." : "Place Order"}
                          </MKButton>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledCheckoutContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledMainPageContainer>
  );
};

export default Checkout;
