import { useState } from "react";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { constructPaymentData, constructBillingDetails } from "../../../utils/helpers/constructPaymentData";
import { generateEndpoint } from "utils/helpers/generateEndpoint";

const useStripeTransaction = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [stripeTransactionLoading, setStripeTransactionLoading] = useState(false);
  const [stripeTransactionError, setStripeTransactionError] = useState(null);

  const createAndProcessTransaction = async (paymentData) => {
    setStripeTransactionLoading(true);
    setStripeTransactionError(null);
    try {
      const endpoint = generateEndpoint(
        process.env.REACT_APP_AWS_DEV_POST_STRIPE_TRANSACTIONS_ENDPOINT,
        process.env.REACT_APP_AWS_PROD_POST_STRIPE_TRANSACTIONS_ENDPOINT
      );
      const response = await axios.post(endpoint, paymentData);
      const { paymentIntent, subscription } = response.data;
      return { paymentIntent, subscription };
    } catch (error) {
      setStripeTransactionError(error.response.data.error);
    } finally {
      setStripeTransactionLoading(false);
    }
  };

  const confirmCardPayment = async (clientSecret, billingDetails) => {
    if (!stripe || !elements) {
      setStripeTransactionError("Stripe is not properly initialized");
      return null;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });

    if (result.error) {
      setStripeTransactionError(result.error.message);
      return null;
    }

    return result.paymentIntent;
  };

  const processPayment = async (values, cartTotal, stripeCouponObj, discount, originalSubtotal, cartItems, customerId) => {
    const paymentData = constructPaymentData(values, cartTotal, stripeCouponObj, discount, originalSubtotal, cartItems, customerId);

    const transactionResponse = await createAndProcessTransaction(paymentData);

    if (!transactionResponse?.paymentIntent && !transactionResponse?.subscription) {
      return null;
    }

    let paymentResults = null;

    if (transactionResponse?.paymentIntent) {
      const billingDetails = constructBillingDetails(values);
      paymentResults = await confirmCardPayment(transactionResponse.paymentIntent.client_secret, billingDetails);
    }

    // Handle subscription separately if needed
    if (transactionResponse?.subscription) {
      // console.log("Subscription created:", transactionResponse.subscription);
    }
    // ! need to return back state stripeTransactionLoading and error
    return { paymentResults, subscription: transactionResponse.subscription };
  };

  return { processPayment, stripeTransactionLoading, stripeTransactionError };
};

export default useStripeTransaction;
