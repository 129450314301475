import React from "react";
import { motion } from "framer-motion";
// Components
import { Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CBBulletPoint from "components/CBBulletPoint";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";

const motionLiStyles = {
  display: "flex",
  alignItems: "start",
  marginBottom: ".5rem",
};

const ListComponentContent = ({ children, listItemSpacing, listType, icon, motionProps, cssListProps }) => {
  const { isLg } = useMediaQueries();
  return (
    <MKBox
      component={motionProps ? motion.li : "li"}
      custom={motionProps?.custom}
      initial={motionProps?.initial}
      animate={motionProps?.animate}
      exit={motionProps?.exit}
      variants={motionProps?.variants?.listItem}
      disableGutters
      width={"auto"}
      padding={0}
      sx={!motionProps ? { alignItems: "start", marginBottom: listItemSpacing ? listItemSpacing : ".5rem", ...cssListProps } : {}}
      style={motionProps ? motionLiStyles : {}}
    >
      {!listType &&
        (icon ? (
          <MKBox sx={{ marginRight: ".4rem" }}>{icon}</MKBox>
        ) : (
          <MKBox sx={{ marginTop: ".4rem" }}>{isLg ? <CBBulletPoint med="true" /> : <CBBulletPoint small="true" />}</MKBox>
        ))}
      {children}
    </MKBox>
  );
};
const ListItemContent = ({ item, color, listVariant, textColor }) => {
  // const updatedContent = replaceKeywordWithLink(item, keywords);
  color = textColor ? textColor : color;
  return (
    <MKTypography component="p" variant={listVariant ? listVariant : "body2"} sx={{ color: color ? `${color} !important` : "" }}>
      {item}
    </MKTypography>
  );
};
const ListComponent = ({
  listItems,
  color,
  listItemSpacing,
  titleColor,
  listType,
  icon,
  listVariant,
  textColor,
  motionProps,
  content,
  cssListProps,
}) => {
  const { isLg } = useMediaQueries();
  const listItemsTest = content ? content : listItems;
  return (
    <MKBox {...cssListProps} component={motionProps ? motion.ul : "ul"} initial="hidden" animate="show" variants={motionProps?.variants?.list}>
      {listItemsTest.map((item, index) => {
        if (typeof item === "string") {
          return (
            <ListComponentContent
              key={index}
              motionProps={{ ...motionProps, custom: index }}
              icon={icon}
              index={index}
              listItemSpacing={listItemSpacing}
              listType={listType}
            >
              <ListItemContent
                item={item}
                color={color}
                index={index}
                listItemSpacing={listItemSpacing}
                listVariant={listVariant}
                textColor={textColor}
              />
            </ListComponentContent>
          );
        } else if (typeof item === "object" && item?.content) {
          let updatedContent = item?.content;
          if (item?.embeddedLink) {
            const { url, keyWord } = item?.embeddedLink;
            const itemContent = item.content || item.content.content;
            updatedContent = replaceKeywordWithLink(itemContent, keyWord, url);
          }

          return (
            <ListComponentContent
              key={index}
              motionProps={{ ...motionProps, custom: index }}
              icon={icon}
              index={index}
              listItemSpacing={listItemSpacing}
              listType={listType}
            >
              {item?.title ? (
                <Stack mb="1rem">
                  <MKTypography
                    sx={{
                      color: titleColor ? `${titleColor} !important` : textColor ? `${textColor} !important` : "",
                      fontWeight: "normal",
                    }}
                    component="h6"
                    variant="h6"
                    gutterBottom
                  >
                    {item?.title}
                  </MKTypography>
                  {Array.isArray(updatedContent) && listType === "faq" ? (
                    updatedContent.map((contentItem, idx) => (
                      <Stack direction="row">
                        {icon ? (
                          <MKBox>{icon}</MKBox>
                        ) : (
                          <MKBox sx={{ marginTop: ".4rem" }}>{isLg ? <CBBulletPoint med="true" /> : <CBBulletPoint small="true" />}</MKBox>
                        )}
                        <ListItemContent key={idx} item={contentItem} color={color} textColor={textColor} listVariant={listVariant} />
                      </Stack>
                    ))
                  ) : (
                    <ListItemContent item={updatedContent || item} color={color} textColor={textColor} listVariant={listVariant} />
                  )}
                </Stack>
              ) : (
                <ListItemContent item={updatedContent || item} color={color} textColor={textColor} listVariant={listVariant} />
              )}
            </ListComponentContent>
          );
        }
        return null;
      })}
    </MKBox>
  );
};

export default ListComponent;
