import { Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import theme from "assets/theme";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import StoreIcon from "@mui/icons-material/Store";
import { StyledButtonContainer } from "index.styles";
import MKButton from "components/MKButton";
const borderBottomStyles = {
  borderBottom: `2px solid ${theme.palette.accent.main}`,
  paddingBottom: ".5rem",
  margin: "auto auto 2rem auto",
};

const AdditionalInformation = () => {
  return (
    <StyledSectionContainer>
      <MKBox sx={{ marginBottom: "2rem" }}>
        <MKTypography variant="h2" component="h2" gutterBottom sx={borderBottomStyles}>
          Customer Portal
        </MKTypography>
        <MKTypography component="p" variant="body2" sx={{ marginBottom: "1rem" }}>
          Our Stripe customer portal allows you to log in and check your previous orders, update your shipping and billing details, and manage your
          payment options. It's a convenient way to keep your account information up-to-date and ensure a smooth shopping experience.
        </MKTypography>
        <Stack>
          <Stack direction="row" alignItems="center" gap={1} marginBottom={"1rem"}>
            <StoreIcon sx={{ color: theme.palette.secondary.main }} />
            <MKTypography component="p" variant="body2">
              <Link
                href={
                  process.env.REACT_APP_ENV === "production"
                    ? process.env.REACT_APP_LIVE_STRIPE_CUSTOMER_PORTAL_PATH
                    : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_PATH
                }
                style={{ color: theme.palette.secondary.main }}
              >
                Customer Portal
              </Link>
            </MKTypography>
          </Stack>
        </Stack>
        <MKTypography component="p" variant="body2">
          Note: You can always access your customer portal by clicking <em>"Account Order Information"</em> under the <em>"For Patients"</em> menu in
          the navigation bar
        </MKTypography>
      </MKBox>
      <MKBox>
        <MKTypography variant="h2" component="h2" gutterBottom sx={borderBottomStyles}>
          Have A Question?
        </MKTypography>
        <MKTypography component="p" variant="body2" sx={{ marginBottom: "1rem" }}>
          If you have any questions or need assistance, our support team is here to help. Feel free to reach out to us via phone or email, and we'll
          be happy to assist you.
        </MKTypography>
        <Stack>
          <Stack direction="row" alignItems="center" gap={1} sx={{ marginBottom: "1rem" }}>
            <PhoneIcon sx={{ color: theme.palette.secondary.main }} />
            <MKTypography component="p" variant="body2">
              Phone:{" "}
              <Link href="tel:+17207417205" style={{ color: theme.palette.secondary.main }}>
                +1-720-741-7205
              </Link>
            </MKTypography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <EmailIcon sx={{ color: theme.palette.secondary.main }} />
            <MKTypography component="p" variant="body2">
              Email:{" "}
              <Link href="mailto:support@almadelic.com" style={{ color: theme.palette.secondary.main }}>
                support@almadelic.com
              </Link>
            </MKTypography>
          </Stack>
          <StyledButtonContainer>
            <MKButton component={RouterLink} to="/faq" variant="contained" color="secondary" size="large" align="center">
              See Common FAQs
            </MKButton>
          </StyledButtonContainer>
        </Stack>
      </MKBox>
    </StyledSectionContainer>
  );
};

export default AdditionalInformation;
