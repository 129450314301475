import { styled } from "@mui/material/styles";
import rgba from "assets/theme/functions/rgba";
import MKBox from "components/MKBox";

export const StyledCartItemCard = styled(MKBox)(({ theme, conflicting, cartItemType }) => ({
  display: "flex",
  gap: "1rem",
  padding: "1rem",
  marginBottom: "1rem",
  alignItems: "center",
  cursor: cartItemType === "order-confirmation" ? "default" : "pointer",
  flexDirection: "column",
  backgroundColor: conflicting ? "#ffeeee" : "transparent",
  border: conflicting
    ? `2px dotted ${theme.palette.error.main}`
    : cartItemType === "order-confirmation"
    ? `none`
    : `1px solid ${theme.palette.grey[400]}`,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  // boxShadow: cartItemType === "order-confirmation" ? `rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px` : "none",
  borderRadius: "12px",
}));
