import React, { createContext, useEffect, useRef, useState } from "react";
import useFetchGHLContacts from "pages/FindATherapist/hooks/useFetchContacts.hooks";
export const DirectorySearchBar = createContext({
    setSearchTerms: () => {},
    searchTerms: [],
    page: 1,
    filteredData: [],
    inputRef: null,
    filterOptions: [],
    setFilterOptions: () => {},
    filtersAppliedCount: 0,
    handleClearSearchTerms: () => {},
    handleReFetch: () => {},
    handleSearchButtonPressed: () => {},
});

export const DirectorySearchBarProvider = ({ children, initialData = [], filterFunction }) => {
    const inputRef = useRef(null);
    const [searchTerms, setSearchTerms] = useState("");
    const [filterOptions, setFilterOptions] = useState([]);
    const [filteredData, setFilteredData] = useState(initialData);
    const [filtersAppliedCount, setFiltersAppliedCount] = useState(0);
    const [page, setPage] = useState(1);

    const handleSearchButtonPressed = () => {
        
    }

    const value = {
        setSearchTerms,
        searchTerms,
        filteredData,
        inputRef,
        filterOptions,
        setFilterOptions,
        filtersAppliedCount,
        page,
        setPage,
        handleClearSearchTerms: () => {
            setSearchTerms("");
            setFiltersAppliedCount(0);
        },
        handleReFetch: () => {
            const filteredData = filterFunction(initialData, searchTerms, filterOptions);
            setFilteredData(filteredData);
        },
        handleSearchButtonPressed,
    }

    return <DirectorySearchBar.Provider value={value}>{children}</DirectorySearchBar.Provider>;  
};