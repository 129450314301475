import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "assets/theme";
// Components
import { Link, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import EllipsisText from "components/CBEllipsisText";
// Styled components
import { StyledCartItemCard } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { CartContext } from "utils/context/cart.context";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";

const CartItems = ({ cartItems, conflictingItems, cartItemType }) => {
  const { removeItemFromCart, setIsCartOpen } = useContext(CartContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const navigate = useNavigate();
  const { isMd } = useMediaQueries();
  const conflictingItemIds = conflictingItems?.map((item) => item.id) || null;

  const handleRemoveProduct = (index, e) => {
    e.stopPropagation();
    removeItemFromCart(cartItems[index]);
  };

  const handleReadMoreClick = (index, e) => {
    e.stopPropagation();
    setExpandedItems((prev) => {
      const newExpandedItems = [...prev];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const handleNavigateTo = (e, name) => {
    const productName =
      name === "Welcome Package For New Patients"
        ? "new-patient"
        : name === "Renewal Package (3-Month)"
        ? "3-month-ketamine-renewal"
        : formatTitleForURL(name);
    navigate(`/ketamine-therapy/packages/${productName}`, { state: { productId: e.currentTarget.id } });
    setIsCartOpen(false);
  };

  return (
    <MKBox sx={{ marginBottom: "1rem" }}>
      {cartItems.map(({ id, name, images, metadata, price }, index) => {
        const isConflicting = conflictingItemIds.includes(id);
        return (
          <StyledCartItemCard
            cartItemType={cartItemType}
            onClick={cartItemType === "order-confirmation" ? null : (e) => handleNavigateTo(e, name)}
            id={id}
            key={id}
            conflicting={isConflicting}
          >
            <MKBox
              component="img"
              src={images && images[0]}
              sx={{
                width: "100px",
                height: "116px",
                objectFit: "cover",
              }}
            ></MKBox>
            <Stack direction={isMd ? "row" : "column"} alignItems={"flex-end"} gap={1}>
              <Stack>
                <Stack direction={isMd ? "row" : "column"} spacing={isMd ? 2 : 0} alignItems={isMd ? "center" : "flex-start"}>
                  <MKTypography component="h4" variant="h6" gutterBottom>
                    {name}
                  </MKTypography>
                  <MKTypography component="span" variant="body2" gutterBottom sx={{ fontSize: { xs: "70%", md: "80%" } }}>
                    {metadata.priceValue}
                    {/* {`$${(price / 100).toFixed(2)}`} */}
                  </MKTypography>
                </Stack>
                <EllipsisText
                  text={metadata.product_description}
                  product={"true"}
                  onReadMore={(e) => handleReadMoreClick(index, e)}
                  isExpanded={expandedItems[index]}
                ></EllipsisText>
              </Stack>

              {cartItemType !== "order-confirmation" && (
                <Link
                  onClick={(e) => handleRemoveProduct(index, e)}
                  sx={{
                    fontSize: "14px",
                    marginTop: "16px",
                    textDecoration: "underline",
                    maxWidth: "150px",
                    minWidth: { md: "85px" },
                    color: theme.palette.accent.main,
                  }}
                >
                  Remove Item
                </Link>
              )}
            </Stack>
          </StyledCartItemCard>
        );
      })}
    </MKBox>
  );
};

export default CartItems;
