import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledEmptyCartSectionContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  paddingBlock: "2rem",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));
