import React, { useContext } from "react";
import { Select, FormControl, MenuItem, OutlinedInput, Grid, Stack } from "@mui/material";

import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import MKButton from "components/MKButton";
import { StyledMenuContainer, StyledOptionStack } from "./index.styles";

import { DirectorySearchBar } from "utils/context/directorySearchBar.context";
import CloseIcon from "@mui/icons-material/Close";

const TAFilterMenu = ({ filterMenuOptions, setShowAdvancedSearch, disabled }) => {
    const { filterOptions, setFilterOptions, setPage, page } = useContext(DirectorySearchBar);
    
    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
                marginTop: "1rem",
            },
        },
    };

    const SortOptionSelect = (option) => {
        return (
          <FormControl variant="outline" fullWidth sx={{ maxHeight: "200px" }}>
            <Select
              variant=""
              disabled={disabled}
              label={option.label}
              onChange={(e) => handleMultiSelectChange(e, option)}
              input={<OutlinedInput label="State" />}
              value={filterOptions[option.name] || []}
              sx={{ height: "44px", justifyContent: "center" }}
              displayEmpty
              multiple={option.multi}
              renderValue={(selected) => {
                if (!selected.length) {
                  return (
                    <MKTypography component="p" sx={{ textAlign: "left", fontSize: "12px", color: theme.palette.white.main, fontWeight: "bold" }}>
                      Select your {option.name === "Licensed To Practice In" ? "State" : option.name}
                    </MKTypography>
                  );
                }
                return (
                  <MKTypography sx={{ color: theme.palette.white.main, fontSize: "1.0rem", fontWeight: 400 }}>
                    {Array.isArray(selected) ? selected.join(", ") : selected}
                  </MKTypography>
                );
              }}
              MenuProps={menuProps}
            >
              {option.options.map((selectOption, index) => {
                return (
                  <MenuItem key={index} value={selectOption.value} name={selectOption.label} sx={{ color: theme.palette.primary.main }}>
                    {selectOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      };

      const handleClearFilter = () => {
        setFilterOptions({});
        // setShowAdvancedSearch(false);
        setPage(1);
      };

      const handleMultiSelectChange = (event, option) => {
        const { value } = event.target;
        const { name } = option;
        const updatedFilterOption = filterOptions;
    
        if (value.includes("All") || value.length === 0) {
          delete updatedFilterOption[name];
          setFilterOptions({ ...updatedFilterOption });
        } else if (!filterOptions[name]) {
          setFilterOptions({ ...filterOptions, [name]: value });
        } else if (filterOptions[name] !== value) {
          updatedFilterOption[name] = value;
          setFilterOptions({ ...updatedFilterOption });
        }
        setPage(1);
      };

      return (
        <StyledMenuContainer id="advance-search-filter-menu">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MKTypography variant="h4" component="h4" sx={{ color: `${theme.palette.white.main} !important`, fontWeight: "bold" }}>
              Advanced Filter Options
            </MKTypography>
            <MKButton color="secondary" onClick={() => setShowAdvancedSearch(false)}>
              <CloseIcon />
            </MKButton>
          </Stack>
          <Grid container spacing={2} sx={{ padding: "1rem" }}>
            {filterMenuOptions.map((option, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} xl={4} key={index}>
                  <StyledOptionStack>
                    <MKTypography variant="p" component="p" gutterBottom sx={{ textAlign: "left", color: theme.palette.white.main, fontWeight: "bold" }}>
                      {option.label}
                    </MKTypography>
                    {option.type === "select" ? SortOptionSelect(option) : null}
                  </StyledOptionStack>
                </Grid>
              );
            })}
          </Grid>
          <MKButton
            onClick={handleClearFilter}
            // variant="outlined"
            color="secondary"
            sx={{ borderColor: theme.palette.secondary.main, margin: "2rem 0rem" }}
            disabled={disabled}
          >
            Clear Filters
          </MKButton>
        </StyledMenuContainer>
      );

}

export default TAFilterMenu;