import styled from "@emotion/styled";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";

export const StyledSearchBarContainer = styled(MKBox)(({ theme }) => ({
  position: "relative",
  margin: "auto",
  height: "100%",
  width: "100%",
  marginTop: "2rem",
}));

export const StyledSearchBarIconContainer = styled(MKBox)(({ theme }) => ({
  // position: "absolute",
  // right: 0,
  // top: 22,
  // transform: "translate(0, -50%)",
  minHeight: "44px",
  zIndex: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: `${theme.palette.secondary.main}`,
  borderRadius: "0rem",
  borderTopRightRadius: ".5rem",
  borderBottomRightRadius: ".5rem",
  cursor: "pointer",
}));

export const StyledSearchBarInputField = styled(MKInput)(({ theme }) => ({
  display: "flex",
  margin: "0 auto",
  backgroundColor: "#fff !important",
  borderRadius: "0rem",
  
  "& .MuiOutlinedInput-input": {
    color: "black !important",
    paddingLeft: "65px",
    fontSize: "0.875rem",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "5px",
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontSize: "0.75rem",
  },
}));


