import React from "react";
import theme from "assets/theme";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// Components
import { Grid, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
// Styled components
import { StyledIndexIcon } from "./index.styles";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// animations
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInVariants } from "styles/animations/sectionFadeIn.animations";

const AnimatedStyledSectionContainer = withSectionAnimation(StyledSectionContainer, fadeInVariants);
const HowItWorks = ({ content }) => {
  const { isLg, isMd, isSmUp } = useMediaQueries();
  if (!content) return null;

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const Banner = () => {
    const tealOpaque = hexToRgba(`${theme.palette.accent.main}`, 1);
    const tealTransparent = hexToRgba(`${theme.palette.accent.main}`, 0.1);

    return (
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          background: `linear-gradient(to right, ${tealOpaque} 10%, ${tealTransparent} 90%)`,
          padding: "2.5rem 0rem 2.5rem 2rem",
        }}
      >
        {isMd ? (
          <MKBox sx={{ width: "50px" }}>
            <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/white_butterfly.webp" alt="Image of logo" />
          </MKBox>
        ) : null}
        <HeaderContainer
          title={content.title}
          component={"h2"}
          variant={"h2"}
          textColor={theme.palette.white.main}
          cssProps={{ margin: "0 !important" }}
        />
      </Stack>
    );
  };

  const leftComponent = () => {
    return (
      <Grid item xs={12} md={10} lg={4} order={0}>
        <ImageContainer
          cssProps={{ height: isLg ? "538px" : isMd ? "500px" : isSmUp ? "450px" : "400px", objectFit: "cover" }}
          image={content.image.src}
          alt={content.image.alt}
        />
      </Grid>
    );
  };

  const rightComponent = () => {
    const numberIcon = (number) => {
      const iconSize = isMd || isLg ? 60 : 45; // Adjust size based on screen size

      return (
        <StyledIndexIcon>
          <svg width={iconSize} height={iconSize}>
            <defs>
              <radialGradient id="grad" cx="41%" cy="41%" r="70%" fx="41%" fy="30%">
                <stop offset="0%" style={{ stopColor: "white", stopOpacity: 1 }} />
                <stop offset="70%" style={{ stopColor: "white", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: theme.palette.accent.main, stopOpacity: 1 }} />
              </radialGradient>
            </defs>
            <circle cx={iconSize / 2} cy={iconSize / 2} r={iconSize / 2} fill="url(#grad)" />
            <text x="50%" y="50%" textAnchor="middle" dy=".3em" style={{ fill: theme.palette.accent.main, fontSize: "30px", fontWeight: "bold" }}>
              {number}
            </text>
          </svg>
        </StyledIndexIcon>
      );
    };

    return (
      <Grid
        container
        item
        xs={12}
        md={10}
        lg={6}
        order={1}
        direction="column"
        sx={{ display: "flex", justifyContent: "center", position: "relative" }}
        rowGap={isLg ? 4 : 5}
      >
        {content.steps.map((step, index) => {
          const isObject = typeof step === "object";
          const title = isObject ? step.title : step;
          const content = isObject ? step.content : null;

          return (
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", minHeight: "75px" }}>
              <Stack direction="row" gap={2} key={index}>
                <MKTypography sx={{ color: `${theme.palette.white.main} !important` }} variant="h3" element="h3">
                  {numberIcon(index + 1)}
                </MKTypography>
                <Stack>
                  <Stack direction="row" gap={1} alignItems="center" sx={{ marginBottom: "0.5rem" }}>
                    <MKTypography
                      sx={{
                        color: `${theme.palette.accent.main} !important`,
                        display: "flex",

                        fontSize: isMd ? "105%" : "90%",
                      }}
                      gutterBottom
                      variant="h4"
                      element="h3"
                    >
                      {title}
                    </MKTypography>
                  </Stack>
                  {content && (
                    <MKTypography
                      sx={{
                        color: `${theme.palette.white.main} !important`,
                      }}
                      variant="body2"
                      component="p"
                    >
                      {content}
                    </MKTypography>
                  )}
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Banner />
      <AnimatedStyledSectionContainer id="ketamine-therapy-state-how-it-works">
        <TwoColumnSection
          columnSpacing={0}
          rowSpacing={0}
          columnGap={6}
          rowGap={6}
          leftComponent={leftComponent()}
          rightComponent={rightComponent()}
        />
        {content?.cta && (
          <Stack direction="column" sx={{ marginTop: "4rem" }}>
            <MKTypography
              variant="h5"
              component="p"
              sx={{ color: `${theme.palette.white.main} !important`, textAlign: "center", margin: "2rem 0rem" }}
            >
              {content.cta.text}
            </MKTypography>
            <MKButton
              sx={{ display: "flex", justifyContent: "center", alignSelf: "center" }}
              variant="contained"
              color="secondary"
              size="large"
              component={Link}
              to={content.cta.link}
            >
              Our Program
            </MKButton>
          </Stack>
        )}
      </AnimatedStyledSectionContainer>
    </>
  );
};

export default HowItWorks;
