import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

export const StyledProfileCardsSectionContainer = styled(Grid)(({ theme }) => ({
  paddingBlock: "0rem",
  display: "flex",
  flexWrap: "wrap",
  paddingBlock: "1rem",
  justifyContent: "center",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "0rem",
  },
}));

export const StyledPaginationSectionContainer = styled(Container)(({ theme }) => ({
  paddingBottom: "2rem",

  justifyContent: "center",
  
}));
