import React, { useContext, useState } from "react";
import theme from "assets/theme";
// Styled components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Components
import AddOnsCard from "./components/addonsCard";
import HeaderContainer from "styles/components/headerContainer";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Stack } from "@mui/material";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { CartContext } from "utils/context/cart.context";
// Animations
import withSectionAnimation from "styles/animations/components/withSectionAnimation";
import { fadeInReverseVariants } from "styles/animations/sectionFadeIn.animations";

const infoCardStyle = {
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
  padding: 0,
  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
  },
};

const addonCardSectionStyle = {
  paddingBlock: "2rem !important",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "2rem !important",
  },
};

const AddOnsList = ({ addons, addonType, selectedId, setSelectedId, allAddonsHidden }) => {
  return (
    <>
      {addons?.map(({ name, price, images, id, metadata }, index) => (
        <AddOnsCard
          key={id}
          cardStyle={addonType}
          description={metadata.long_description}
          index={index}
          addons={addons}
          name={name}
          price={price}
          id={id}
          images={images}
          metadata={metadata}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          allAddonsHidden={addonType !== "info-card" ? allAddonsHidden : undefined}
        />
      ))}
    </>
  );
};

const addonInfoCardSectionStyle = {
  //! add padding after feedback, if needed
  // border: "1px solid red",
  paddingBlock: "0rem !important",
  marginBottom: "4rem !important",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "0rem !important",
    marginBottom: "6rem !important",
  },
};
const AnimatedStyledSectionContainer = withSectionAnimation(StyledSectionContainer, fadeInReverseVariants);

const Addons = ({ addons, addonType }) => {
  const { cartItems } = useContext(CartContext);
  const [selectedId, setSelectedId] = useState(null);
  const allAddonsHidden = addons.every((addon) => cartItems.some((item) => item.id === addon.id));

  const { isLg } = useMediaQueries();
  return (
    <AnimatedStyledSectionContainer id="addons-section" cssProps={addonType === "info-card" ? addonInfoCardSectionStyle : addonCardSectionStyle}>
      {addonType === "info-card" && (
        <Stack sx={{ marginBottom: "3rem", textAlign: { xs: "left", sm: "center" } }}>
          <HeaderContainer
            title="Enhance your experience with add-on's"
            textColor={theme.palette.primary.main}
            component="h2"
            variant="h2"
            highlightText={["Add-On's"]}
            highlightColor={theme.palette.accent.main}
          />
          <MKTypography variant={isLg ? "body1" : "body2"}>
            Personalize your healing journey with Almadelic's optional add-ons. From guided meditations to integrative coaching sessions, our add-ons
            are designed to deepen your introspection and maximize the therapeutic benefits of your treatment.
          </MKTypography>
        </Stack>
      )}
      <MKBox
        sx={{
          display: "flex",
          gap: "1rem",
          padding: allAddonsHidden ? "0" : "1rem",
          ...(addonType === "info-card" && infoCardStyle),
          ...(addonType !== "info-card" && { overflow: "auto" }),
          justifyContent: selectedId ? "center !important" : "space-between !important",
        }}
      >
        {addonType !== "info-card" && allAddonsHidden ? (
          <MKTypography
            variant="h4"
            component="h4"
            sx={{
              // border: "2px dotted grey",
              backgroundColor: theme.palette.grey[200],
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            You’ve Added All Available Add-Ons To Your Cart!
          </MKTypography>
        ) : (
          <AddOnsList addons={addons} addonType={addonType} selectedId={selectedId} setSelectedId={setSelectedId} allAddonsHidden={allAddonsHidden} />
        )}
      </MKBox>
    </AnimatedStyledSectionContainer>
  );
};

export default Addons;
