import styled from "@emotion/styled";
import { Box } from "@mui/material";
import rgba from "assets/theme/functions/rgba";

export const StyledShopBannerContainer = styled(Box)(({ theme }) => ({
  minHeight: "300px",
  width: "100%",
  backgroundImage: `url("https://d3a0rs3velfrz.cloudfront.net/shop-assets/shop-banner.webp")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  boxShadow: `inset 0 0 30px 10px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(0, 0, 0, 0.4)`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "1rem",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: rgba("#000", 0.3),
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      backgroundColor: rgba("#000", 0.3),
    },
  },
  "& > *": {
    position: "relative",
    zIndex: 2,
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "400px",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "450px",
  },
}));
