import { useEffect, useRef } from "react";

const useGooglePlacesAutocomplete = (setFieldValue, fieldNames) => {
  const addressInputRef = useRef(null);

  useEffect(() => {
    const handleLoad = () => {
      if (window.google && addressInputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
          types: ["address"],
          componentRestrictions: { country: "us" },
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.address_components) {
            let street = "";
            let city = "";
            let state = "";
            let zip = "";

            place.address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("street_number")) {
                street = component.long_name;
              }
              if (types.includes("route")) {
                street += ` ${component.long_name}`;
              }
              if (types.includes("locality")) {
                city = component.long_name;
              }
              if (types.includes("administrative_area_level_1")) {
                state = component.short_name;
              }
              if (types.includes("postal_code")) {
                zip = component.long_name;
              }
            });

            setFieldValue(fieldNames.address, street.trim());
            setFieldValue(fieldNames.city, city);
            setFieldValue(fieldNames.state, state);
            setFieldValue(fieldNames.zip, zip);
          }
        });
      }
    };

    if (!window.google) {
      window.addEventListener('load', handleLoad);
    } else {
      handleLoad();
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [setFieldValue, fieldNames]);

  return addressInputRef;
};

export default useGooglePlacesAutocomplete;
