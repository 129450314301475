import { useState } from "react";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { generateEndpoint } from "utils/helpers/generateEndpoint";

const useCreateStripeAccount = () => {
  const [stripeAccountLoading, setStripeAccountLoading] = useState(false);
  const [stripeAccountError, setStripeAccountError] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null); // this state isn't updating in time for sending to constructPurchaseData
  const stripe = useStripe();
  const elements = useElements();

  const createStripeAccount = async (payload) => {
    setStripeAccountLoading(true);
    setStripeAccountError(null);

    try {
      const findCustomerEndpoint = generateEndpoint(
        process.env.REACT_APP_AWS_DEV_GET_STRIPE_ACCOUNT_ENDPOINT,
        process.env.REACT_APP_AWS_PROD_GET_STRIPE_ACCOUNT_ENDPOINT
      );
      const searchResponse = await axios.get(findCustomerEndpoint, {
        params: {
          email: payload.purchaseData.email,
        },
      });
      const parsedResponse = JSON.parse(searchResponse.data.body);
      const existingCustomer = parsedResponse.customer;
      let customerId;
      if (existingCustomer) {
        // Customer exists, use their Stripe ID
        customerId = existingCustomer.id;
      } else {
        // If customer does not exist, create a new customer
        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: payload.name,
            email: payload.email,
            address: payload.address,
          },
        });

        if (paymentMethodError) {
          setStripeAccountError(paymentMethodError.message);
          throw new Error(paymentMethodError.message);
        }

        const createCustomerEndpoint = generateEndpoint(
          process.env.REACT_APP_AWS_DEV_POST_STRIPE_ACCOUNT_ENDPOINT,
          process.env.REACT_APP_AWS_PROD_POST_STRIPE_ACCOUNT_ENDPOINT
        );
        const response = await axios.post(createCustomerEndpoint, {
          ...payload,
          paymentMethodId: paymentMethod.id,
        });
        const parsedResponse = JSON.parse(response.data.body);
        customerId = parsedResponse.customerId;
      }

      setStripeCustomerId(customerId);
      return customerId;
    } catch (error) {
      setStripeAccountError(error.response.data.error || error || "Failed to create Stripe account");
    } finally {
      setStripeAccountLoading(false);
    }
  };

  return {
    stripeAccountLoading,
    stripeAccountError,
    stripeCustomerId,
    createStripeAccount,
  };
};

export default useCreateStripeAccount;
