import React from "react";
import theme from "assets/theme";
// Components
import { Alert, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import HorizontalInfoCard from "styles/components/horizontalInfoCard";
import EllipsisText from "components/CBEllipsisText";

const ProductAddonCard = ({
  index,
  name,
  price,
  id,
  description,
  isItemInCart,
  handleAddToCart,
  showAlert,
  setShowAlert,
  alertMessage,
  handleReadMoreClick,
  expandedItems,
  handleNavigateTo,
}) => {
  const BottomCardComponent = () => {
    return (
      <Stack direction={"row"} spacing={2}>
        <MKButton
          disabled={isItemInCart}
          id={id}
          onClick={(e) => handleAddToCart(e, index)}
          size="small"
          color="secondary"
          variant="outlined"
          sx={{ mt: "1rem", display: "flex" }}
        >
          Add To Cart
        </MKButton>
        {isItemInCart && showAlert && alertMessage && (
          <Alert
            severity="info"
            onClose={(e) => {
              e.stopPropagation();
              setShowAlert(false);
            }}
            sx={{
              width: "100%",
              position: "absolute",
              right: 0,
              bottom: 0,
              zIndex: 1,
              borderRadius: "0 0 12px 0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MKTypography component="span" sx={{ fontSize: "13px" }}>
              {alertMessage}
            </MKTypography>
          </Alert>
        )}
      </Stack>
    );
  };

  const MiddleCardComponent = () => {
    return (
      <Stack direction={"row"} justifyContent={"space-between"} mb="1rem">
        <EllipsisText
          text={description}
          product={"true"}
          onReadMore={(e) => handleReadMoreClick(index, e)}
          isExpanded={expandedItems[index]}
        ></EllipsisText>
      </Stack>
    );
  };

  const TopCardComponent = () => {
    return (
      <Stack direction={"row"} justifyContent={"space-between"} mb="1rem">
        <MKTypography component="h6" variant="h6" gutterBottom>
          {name}
        </MKTypography>
        <MKTypography component="small" variant="body2" sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}>
          ${(price / 100).toFixed(2)}
        </MKTypography>
      </Stack>
    );
  };
  return (
    <>
      <HorizontalInfoCard
        id={id}
        handleClick={(e) => handleNavigateTo(e, name)}
        cssProps={{
          display: isItemInCart ? "none" : "flex",
          justifyContent: "center",
          alignItems: "space-between",
          position: "relative",
          minWidth: "250px",
          height: "226px",
          [theme.breakpoints.up("sm")]: {
            minWidth: "300px",
          },
          [theme.breakpoints.up("lg")]: {
            minWidth: "400px",
          },
          // filter: isItemInCart ? "grayscale(100%)" : "none",
          // scale: isItemInCart ? 0.95 : 1,
        }}
        key={id}
        topComponent={<TopCardComponent />}
        midComponent={<MiddleCardComponent />}
        botComponent={<BottomCardComponent />}
      />
    </>
  );
};

export default ProductAddonCard;
