import React from "react";
import MKTypography from "components/MKTypography";
import ListComponent from "components/CBList";
import { Link } from "react-router-dom";
import theme from "assets/theme";

const FAQDetails = ({ item, textColor }) => {
  const { answer, answerList, answerFooter, cta } = item;

  const textColorStyle = textColor ? { color: `${textColor} !important` } : { color: theme.palette.primary.main };
  return (
    <div>
      {/* Answer */}
      {Array.isArray(answer) ? (
        item.answer.map((answer, index) => (
          <MKTypography
            component={"p"}
            variant={"body2"}
            key={index}
            gutterBottom
            sx={{ marginBottom: index === item.answer.length - 1 ? "2rem" : "1rem", ...textColorStyle }}
          >
            {answer}
          </MKTypography>
        ))
      ) : (
        <MKTypography variant={"body2"} component={"p"} sx={{ marginBottom: "2rem", ...textColorStyle }}>
          {answer}
        </MKTypography>
      )}
      {/* List component */}
      {answerList && <ListComponent textColor={textColor} listItems={answerList} listType="faq" />}
      {/* Answer Footer */}
      {Array.isArray(answerFooter) ? (
        answerFooter.map((content, indexsz) => (
          <MKTypography key={indexsz} component="p" variant={"body2"} gutterBottom sx={{ ...textColorStyle }}>
            {content}
          </MKTypography>
        ))
      ) : (
        <MKTypography component="p" variant={"body2"} sx={{ ...textColorStyle }}>
          {answerFooter}
        </MKTypography>
      )}
      {/* CTA and Links */}
      {Array.isArray(cta)
        ? cta.map(({ text, link }, index) => (
            <Link
              style={{
                color: textColor === "#fff" ? theme.palette.accent.main : theme.palette.secondary.main,
                textUnderlineOffset: "5px",
              }}
              to={link}
              key={index}
            >
              <MKTypography
                sx={{
                  color: textColor === "#fff" ? theme.palette.accent.main : theme.palette.secondary.main,
                }}
                component="p"
                variant={"body2"}
                gutterBottom
              >
                {text}
              </MKTypography>
            </Link>
          ))
        : cta}
    </div>
  );
};

export default FAQDetails;
