import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Styled components
import { StyledEllipsisTypography, StyledProfileCard, StyledProfileCardImage } from "./index.styles";
import ThreeStackCard from "styles/components/threeStackCard";
// Components
import MKTypography from "components/MKTypography";
import { Stack, Link as MuiLink, Container } from "@mui/material";
import MKBox from "components/MKBox";
// import ProfileModal from "../profileModal";
// Icons
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { containsTag } from "utils/helpers/checkTag";
import { convertState } from "utils/helpers/stateConverter";
import { ProfileBioContext } from "utils/context/profileBio.context";
import { ensureHttps } from "utils/helpers/ensureHTTPS";
// Theme
import theme from "assets/theme";
import HeaderContainer from "styles/components/headerContainer";

const ProfileCard = ({ profileData, index }) => {
  const { isLg, isMd, isSm } = useMediaQueries();
  const { setCurrentUserData, open } = useContext(ProfileBioContext);

  const { state, name, customFields, tags, city } = profileData || [];
  const [isBusinessListing, setIsBusinessListing] = useState();
  

  const specialties = findCustomFieldValue("Specialties", customFields);
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const experience = findCustomFieldValue("Years Practiced", customFields);
  const licensedIn = findCustomFieldValue("Licensed To Practice In", customFields);

  const navigate = useNavigate();
  let activeColor = tags.includes("partners education complete") ? theme.palette.secondary.main : theme.palette.accent.main;

  const handleCardClick = (event) => {
    if (open || isBusinessListing) return;
    // event.stopPropagation();
    setCurrentUserData(profileData);
    const url = `/partner-providers/${name?.replace(/\s+/g, "-")}`;

    navigate(url);
  };

  useEffect(() => {
    if (containsTag(tags, "active directory business listing")) {
      setIsBusinessListing(true);
    } else {
      setIsBusinessListing(false);
    }
  }, [tags]);



  const Specialties = () => {
    return (
      <Container sx={{padding: "0rem 0.5rem 0rem !important", margin: "0rem"}}>
        <HeaderContainer component="h2" variant="h6" cssProps={{margin: " 0 0 0.5rem"}} sx={{textAlign: "center"}} title={name} textColor={theme.palette.primary.main} />
        <StyledEllipsisTypography component="p" webkitLineClamp={2} width={"100%"}>
          <span style={{fontWeight: "bold"}}>Specialties:</span> {specialties?.length > 0 ? specialties.join(", ") : "No specialties listed"}
        </StyledEllipsisTypography>
        <LicensedIn />
      </Container>
    )
  }

  const LicensedIn = () => {
    return(
      <StyledEllipsisTypography component="p" webkitLineClamp={2} width={"100%"}>
          <span style={{fontWeight: "bold"}}>Licensed In:</span> {licensedIn?.length > 0 ? licensedIn.join(", ") : "Not Licensed in any states"}
        </StyledEllipsisTypography>
      
    )
  }

  const StateAndWebsite = () => {
    return(
      <>
       
        <Stack direction={"column"} spacing={0} sx={{padding: "0.5rem"}}>
          <MKBox sx={{ maxWidth: "75%" }}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <FmdGoodIcon
                sx={{
                  width: { xs: "18px" },
                  height: { xs: "18px" },
                  color: activeColor,
                }}
              />
              <StyledEllipsisTypography component="p" webkitLineClamp={1} width="200px">
                {city && `${city}, `}
                {convertState(state)}
              </StyledEllipsisTypography>
            </Stack>
            {websiteURL && (
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <LanguageIcon style={{ width: "18px", height: "18px", fontSize: "18px", color: activeColor }} />
                <MuiLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: activeColor}}>
                  <StyledEllipsisTypography
                    component="p"
                    webkitLineClamp={1}
                    width="200px"
                    sx={{ color: activeColor}}
                  >
                    {websiteURL}
                    {/* {websiteURL || website} */}
                  </StyledEllipsisTypography>
                </MuiLink>
              </Stack>
            )}
          </MKBox>
          {/* column 2 */}
          {!isBusinessListing && (
            <MKBox sx={{ maxWidth: "75%" }}>
              {/* experience */}
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
                <WorkIcon
                  sx={{
                    // marginLeft: { sm: "1rem" },
                    width: { xs: "18px" },
                    height: { xs: "18px" },
                    color: activeColor,
                  }}
                />
                <StyledEllipsisTypography component="p" webkitLineClamp={1} width="200px">
                  {experience} Years Exp
                </StyledEllipsisTypography>
              </Stack>
            </MKBox>
          )}
        </Stack>
      
      </>
    )  
  }

  function hexToRgba(hex, alpha = 1) {
    let r = 0, g = 0, b = 0;
    
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } 
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  
  const PartnerTag = () => {
    return(
      <MKBox sx={{
        position: "absolute",
        top: 0,
        right: 0,
        textAlign: "center",
        zIndex: 1,
        padding: "0.25rem 0.5rem 0.25rem 1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0rem 2rem 0rem 10rem",
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.9),
      }}>
        <img
            src={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
            style={{ height: "30px", width: "40px" }}
            height="0"
            width="0"
            alt="Almadelic butterfly logo"
          />
        <MKTypography component="p" variant="h6"  sx={{color: `${theme.palette.secondary.main} !important`}}>
          Partner
        </MKTypography>
      </MKBox>
    )
  }

  const ProfileImageAndName = () => {
    return(
      <>
      <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{width: "100%", marginBlock: "1rem"}}>
        <StyledProfileCardImage
          src={profileURL || "https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/1-1_high_res_butterfly.svg"}
          alt={name}
          sx={{
            width: "90%",
            aspectRatio: "1/1",
            borderRadius: "1rem",
            borderColor: activeColor,
            boxShadow: `${activeColor} 0px 2px 4px, ${activeColor} 0px 7px 13px -3px, ${activeColor} 0px 0px 0px inset`,
          }}
        />
      </Stack>
      {tags.includes("partners education complete") ? <PartnerTag /> : null}
      </>
    )
  }

  const Card = () => {
    const topComponent = () => {
      return(
          <ProfileImageAndName />
      )
    }

    const midComponent = () => {
      return(
        <Stack sx={{width: "100%"}}>
          <Specialties />
        </Stack>
      )
    }

    const botComponent = () => {
      return(
        <>
        <Stack sx={{width: "100%", position: "relative"}}>
          <StateAndWebsite />
        </Stack>
        </>
      )
    }


    const props = {
      padding: "0rem !important",
      width: "100%",
      boxShadow: `${activeColor} 0px 2px 4px, ${activeColor} 0px 7px 13px -3px, ${activeColor} 0px 0px 0px inset`,
      opacity: 0,
      position: "relative",
      borderColor: `${activeColor} !important`
    }


    return(
      <ThreeStackCard 
        topComponent={topComponent()} 
        midComponent={midComponent()} 
        cssProps={props} 
        botComponent={botComponent()} 
        handleClick={handleCardClick}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1.0 }}
        whileHover={{ scale: 1.05 }}
        transition={{
          duration: 2,
          ease: [0, 0.71, 0.2, 1.01],
          scale: {
            type: "spring",
            damping: 15,
            delay: 0.05*index,
            stiffness: 100,
            restDelta: 0.0001
          }
        }}
      />
    )
  }

  

  return ( 
    
      <Card aria-label={`${name}'s profile card`} />
      
  );
};

export default ProfileCard;

