import React from "react";
import { StyledHorizontalCardContainer } from "./index.styles";
import { Stack } from "@mui/material";

const HorizontalInfoCard = ({ topComponent, midComponent, botComponent, handleClick = null, cssProps = null, id = null, ...props }) => {
  const { layoutId, initial, animate, exit, transition, whileHover } = props;
  const animationProps = {
    layoutId,
    initial,
    animate,
    exit,
    transition,
    whileHover,
  };
  return (
    <StyledHorizontalCardContainer layoutId={layoutId} id={id} onClick={handleClick} cssProps={cssProps} {...animationProps}>
      <Stack className="horizontal-addon-info-card-stack" direction="column" spacing={2}>
        {topComponent}
        {midComponent}
        {botComponent}
      </Stack>
    </StyledHorizontalCardContainer>
  );
};

export default HorizontalInfoCard;
