// Major
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";

// Components
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import TherapyServices from "./components/TherapyServices";
import ProfessionalInfo from "./components/ProfessionalInfo";
import CostAndInsurance from "./components/CostAndInsurance";
import MetaTags from "./components/MetaTags";
import therapistSchemaData from "./schema.therapistBio.json";
import SchemaComponent from "components/CBSchema";
import CBDivider from "components/CBDivider";
import MKTypography from "components/MKTypography";
import SEO from "components/CBSEO";
import TAModal from "components/TAModal";
import ModalContactForm from "./components/modalContactForm";

// Utils
import { ProfileBioContext } from "utils/context/profileBio.context";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import useFetchTherapistByName from "utils/hooks/useFetchTherapistByName";

// Styles
import theme from "assets/theme";
import { StyledBioBodyContainer } from "./index.styles";
import CBLoading from "components/CBLoading";
import { Container, Input, Stack } from "@mui/material";
import { StyledMainPageContainer } from "index.styles";

const TherapistBio = () => {
  const navigate = useNavigate();
  const { therapistName } = useParams();

  const [userData, setUserData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [storedContactState, setStoredContactState] = useState(false);
  const { currentUserData } = useContext(ProfileBioContext);
  const schemaData = userData
    ? constructSchemaData(therapistSchemaData.url, therapistSchemaData.breadcrumbs, null, userData)
    : constructSchemaData(therapistSchemaData.url, therapistSchemaData.breadcrumbs);
  
  const knewSchemaData = {
      "url": "https://www.almadelic.com/partner-providers",
      "breadcrumbs": [
          { "name": "Home", "url": "/" },
          { "name": "Partner Providers", "url": "/partner-providers" },
          { "name": `${therapistName.replace(/[^a-zA-Z0-9]/g, ' ')} Bio`, "url": `/partner-providers/${therapistName}` }
      ]
  }

  const constructNewSchemaData = userData
  ? constructSchemaData(knewSchemaData.url, knewSchemaData.breadcrumbs, null, userData)
  : constructSchemaData(knewSchemaData.url, knewSchemaData.breadcrumbs);
  
  const { data: therapistBio, isLoading, isError, error } = useFetchTherapistByName(therapistName);
  
  // setting user data states
  useEffect(() => {

    if (therapistName?.replace(/[^a-zA-Z0-9]/g, '') === currentUserData?.name.replace(/[^a-zA-Z0-9]/g, '')) {
      setStoredContactState(true);
      setUserData(currentUserData);
    } else if (therapistBio) {
      therapistBio.contact === null
        ? navigate("/partner-providers")
        : therapistBio.error
        ? navigate("/partner-providers")
        : therapistBio?.contact?.name?.replace(/[^a-zA-Z0-9]/g, '') === therapistName?.replace(/[^a-zA-Z0-9]/g, '')
        ? setUserData(therapistBio.contact)
        : navigate("/partner-providers");
    }
  }, [therapistBio, currentUserData]);

  const DividerBody = () => {
    return (
      <Container sx={{ padding: "0 !important", margin: "2rem 0rem !important" }}>
        <CBDivider isProfile={true} color={theme.palette.accent.main} />
      </Container>
    );
  };

  

  const handleEmailClick = () => {
    setShowForm(!showForm);
  };

  // main page body
  const PageBody = () => {
    const capitalizeName = (name) => {
      return name?.split(" ")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(" ");
    };
    
    return (
      <>
        <MetaTags currentUserData={userData} />
        <SEO
          title={`${capitalizeName(userData?.name)} | ${userData.city ? userData.city + "," : ""} ${userData.state ? userData.state : ""}`}
          description={`Meet ${capitalizeName(
            userData?.name
          )}, a licensed professional clinician in psychedelic assisted therapy and mental health with Almadelic. Discover how ${capitalizeName(
            userData?.name
          )} helps patients achieve mental well-being through personalized treatment.`}
        />
        <SchemaComponent schemas={constructNewSchemaData} />
        <Header currentUserData={userData} handleEmailClick={handleEmailClick} />
        <TAModal body={<ModalContactForm userData={userData}/>} handleClick={handleEmailClick} showForm={showForm} />
        <StyledBioBodyContainer>
          <AboutMe currentUserData={userData} />
          <DividerBody />
          <TherapyServices currentUserData={userData} />
          <DividerBody />
          <ProfessionalInfo currentUserData={userData} />
          <DividerBody />
          <CostAndInsurance currentUserData={userData} />
        </StyledBioBodyContainer>
      </>
    );
  };

  return (
    <StyledMainPageContainer id="therapist-bio-main">
      {storedContactState ? (
        <PageBody />
      ) : userData===null ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <CBLoading loadingItem={"therapist profile"} />
        </Container>
      ) : isError ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.error.main} !important`, textAlign: "center" }}>
            There was an error fetching the data: {error?.message}. Please try again later
          </MKTypography>
        </Container>
      ) : (
        <PageBody />
      )}
    </StyledMainPageContainer>
  );
};

export default TherapistBio;
