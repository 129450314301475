import React from "react";
import { StyledImageContainer } from "./index.styles";

export const ImageContainer = ({ image, alt, cssProps }) => {
  return (
    <StyledImageContainer sx={{width: "100%" }}>
      <img src={image} alt={alt} style={{ borderRadius: "1rem", width: "100%", ...cssProps }} />
    </StyledImageContainer>
  );
};
