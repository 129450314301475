import React from "react";
// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
// Theme
import theme from "assets/theme";
// Styled Components
import HeaderContainer from "styles/components/headerContainer";
import { StyledImage } from "../../../../index.styles";
import { StyledMusicAndTherapyPageHeaderSectionContainer, StyledMusicAndTherapyTextBox } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { StyledSectionContainerColorWrapper, StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const MusicAndTherapyPageHeader = () => {
  const { isMd, isLg, isXl } = useMediaQueries();

  const rightComponent = () => {
    return(
      <Grid item xs={12} md={12} lg={5} xl={5} >
        <HeaderContainer variant={isXl ? "h1" : "h2"} component="h1" title="Music & Psychedelic Therapy" highlightText="Music &" highlightColor={theme.palette.accent.main} cssProps={{ margin: "0 0 1rem 0" }} />
        <MKTypography component="p" variant={isXl ? "body1" : "body2"} sx={{ color: "#fff" }}>
          The incorporation of music in ketamine therapy sessions by therapists serves as a significant influencer of their patients'
          experiences. Consequently, a thoughtful approach is crucial in selecting and introducing music to ensure its positive impact. Just as
          music has the potential to elicit positive emotions, it also has the capacity to act as a catalyst for negative experiences.
          Therefore, a thorough understanding of its implications is imperative before integrating music into ketamine therapy sessions. Music
          used during ketamine therapy can shape the patient’s experience.
        </MKTypography>
      </Grid>
    )
  }

  const leftComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <ImageContainer 
          image={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-One.webp"} 
          alt="Lady with eyes closed and her headphones on relaxing." 
        />
      </Grid>
    )
  }


  return (
    <StyledSectionContainerColorWrapper id="music-and-therapy-page-header-wrapper">
      <StyledSectionContainer id="music-and-therapy-page-header-section">
        <TwoColumnSection 
          leftComponent={leftComponent()} 
          rightComponent={rightComponent()} 
          rowGap={isLg ? 0 : 6} 
          columnGap={isLg ? 6 : 3} 
        />
      </StyledSectionContainer>
    </StyledSectionContainerColorWrapper>
  );
};

export default MusicAndTherapyPageHeader;


{/* <StyledMusicAndTherapyPageHeaderSectionContainer component="section" id="music-and-therapy-page-header-section">
        <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6}>
          <Grid item xs={12} lg={5}>
            <StyledImage
              height="427"
              width="353"
              src={"https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-One.webp"}
              alt="Lady with eyes closed and her headphones on relaxing."
            ></StyledImage>
          </Grid>
          <Grid item xs={12} lg={7}>
            <StyledMusicAndTherapyTextBox>
              <HeaderContainer
                textColor={theme.palette.white.main}
                highlightColor={theme.palette.accent.main}
                component="h1"
                variant="h1"
                title="Music & Psychedelic Therapy"
                highlightText="Music &"
                cssProps={{ margin: "0 0 1rem 0" }}
              />
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: "#fff" }}>
                The incorporation of music in ketamine therapy sessions by therapists serves as a significant influencer of their patients'
                experiences. Consequently, a thoughtful approach is crucial in selecting and introducing music to ensure its positive impact. Just as
                music has the potential to elicit positive emotions, it also has the capacity to act as a catalyst for negative experiences.
                Therefore, a thorough understanding of its implications is imperative before integrating music into ketamine therapy sessions. Music
                used during ketamine therapy can shape the patient’s experience.
              </MKTypography>
            </StyledMusicAndTherapyTextBox>
          </Grid>
        </Grid>
      </StyledMusicAndTherapyPageHeaderSectionContainer> */}