import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FAQDetails from "components/CBFAQ/components/FAQDetails";
// Styled Components
import { StyledFAQHeader, StyledFAQItem, StyledFAQSection, StyledFAQTitle } from "./index.styles";
// Utils
import theme from "assets/theme";
import { searchFaqs } from "utils/data/faqData";
import { Stack } from "@mui/material";
import useMediaQueries from "utils/mediaQueries.utils";
import { useNavigate } from "react-router-dom";
import MKButton from "components/MKButton";
import rgba from "assets/theme/functions/rgba";
// Animations
const faqItemAnimation = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
  transition: { duration: 0.6 },
};

const selectedFAQAnimation = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3 },
};

const defaultStylesFaqLink = {
  letterSpacing: ".05rem",
  textDecoration: "underline",
  textUnderlineOffset: ".25rem",
  color: theme.palette.accent.main,
  "&:hover": {
    textShadow: `0px 0px 10px ${theme.palette.accent.main}`,
  },
};

const defaultStylesFaqButtons = {
  background: "transparent",
  border: "none",
  boxShadow: "none",
  padding: 0,
  "&:hover": { background: "transparent", border: "transparent", boxShadow: `none` },
  "&:focus": { background: "transparent", border: "transparent", boxShadow: `none` },
  "&:focus-visible": { background: "transparent", border: "transparent", boxShadow: `none` },
  "&:active": { background: "transparent", border: "transparent", boxShadow: `none` },
};

const FAQContentView = ({ initialCategory, textColor, component, variant }) => {
  const [currentFaqData, setCurrentFaqData] = useState([]);
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const { isSmUp, isMd, isLg } = useMediaQueries();

  useEffect(() => {
    const initialFaqs = searchFaqs("", initialCategory);
    setCurrentFaqData(initialFaqs);
  }, [initialCategory]);

  useEffect(() => {
    if (selectedFAQ && selectedFAQ.keyTerms) {
      const refinedFaqs = searchFaqs(selectedFAQ.keyTerms, "all");
      setCurrentFaqData(refinedFaqs);
    }
  }, [selectedFAQ]);

  const handleClick = (faq) => {
    if (selectedFAQ?.question === faq.question) {
      setSelectedFAQ(null);
    } else {
      setSelectedFAQ(faq);
    }
  };

  const handleKeyDown = (event, faq) => {
    if (event.key === "Enter" || event.key === " ") {
      setSelectedFAQ(faq);
    }
  };

  const resetFaqs = () => {
    setSelectedFAQ(null);
    const initialFaqs = searchFaqs("", initialCategory);
    setCurrentFaqData(initialFaqs);
  };

  const textColorStyle = textColor ? { color: `${textColor} !important` } : { color: theme.palette.primary.main };

  return (
    <MKBox id={`faq-section-${currentCategoryIndex}`}>
      <Stack sx={{ marginBottom: 3 }} direction={isLg ? "row" : "column"} spacing={isLg ? 4 : 2} alignItems={isLg ? "center" : "flex-start"}>
        <MKTypography
          ref={containerRef}
          variant={variant ? variant : "h3"}
          component={component ? component : "h3"}
          id="faq-section-title"
          sx={{ ...textColorStyle }}
        >
          Frequently Asked Questions
        </MKTypography>
        <Stack direction="row" spacing={2} sx={{ paddingBlock: ".2rem" }} alignItems="center">
          <MKButton size="small" sx={defaultStylesFaqButtons} onClick={() => navigate("/faq")}>
            <MKTypography variant="body2" component="p" sx={defaultStylesFaqLink}>
              View All FAQs
            </MKTypography>
          </MKButton>
          <MKTypography
            variant="body2"
            component="p"
            sx={{
              color: theme.palette.white.main,
              fontWeight: "bold",
              borderLeft: `2px solid ${theme.palette.white.main}`,
              height: "20px",
            }}
          ></MKTypography>
          <MKButton size="small" sx={defaultStylesFaqButtons} onClick={resetFaqs}>
            <MKTypography
              variant="body2"
              component="p"
              sx={{
                ...defaultStylesFaqLink,
              }}
            >
              Reset FAQs
            </MKTypography>
          </MKButton>
        </Stack>
      </Stack>
      <StyledFAQSection>
        {currentFaqData.map(({ question, answer, answerList, answerFooter, cta, keyTerms }, index) => {
          const isSelected = selectedFAQ && selectedFAQ.question === question;
          return (
            <motion.div
              key={question}
              initial={faqItemAnimation.initial}
              animate={faqItemAnimation.animate}
              exit={faqItemAnimation.exit}
              transition={faqItemAnimation.transition}
            >
              <StyledFAQItem
                onClick={() => handleClick({ question, answer, answerList, answerFooter, cta, keyTerms })}
                onKeyDown={(event) => handleKeyDown(event, { question, answer, answerList, answerFooter, cta })}
                role="button"
                tabIndex={0}
                isSelected={isSelected}
                textColor={textColor}
              >
                <StyledFAQHeader>
                  <StyledFAQTitle variant={"h6"} component={"h3"} className="hover-text" textColorStyle={textColorStyle}>
                    {question}
                  </StyledFAQTitle>
                  <KeyboardArrowRightIcon sx={{ color: textColor === "#fff" ? theme.palette.accent.main : theme.palette.secondary.main }} />
                </StyledFAQHeader>
              </StyledFAQItem>
            </motion.div>
          );
        })}
      </StyledFAQSection>
      {selectedFAQ && (
        <motion.div
          key={selectedFAQ.question}
          initial={selectedFAQAnimation.initial}
          animate={selectedFAQAnimation.animate}
          exit={selectedFAQAnimation.exit}
          transition={selectedFAQAnimation.transition}
        >
          <MKTypography
            variant={"h3"}
            component={"h3"}
            marginTop={6}
            marginBottom={3}
            sx={{
              color: `${theme.palette.accent.main} !important`,
              borderBottom: `2px solid ${rgba(theme.palette.accent.main, 0.6)}`,
              paddingBottom: ".5rem",
            }}
          >
            {selectedFAQ.question}
          </MKTypography>
          <FAQDetails item={selectedFAQ} textColor={textColor} />
        </motion.div>
      )}
    </MKBox>
  );
};

export default FAQContentView;
