import { useState } from "react";
import axios from "axios";
import { constructAddressData } from "utils/helpers/constructAddressData";

const componentTypeMap = {
  street_number: "Street Number",
  route: "Street",
  locality: "City",
  administrative_area_level_1: "State",
  postal_code: "Zip Code",
  postal_code_suffix: "Zip Code Suffix",
  country: "Country",
  subpremise: "Apartment Number, Suite Number, etc.",
  premise: "Premise",
};

const useGoogleAddressValidationSimplified = () => {
  const [addressValidationLoading, setAddressValidationLoading] = useState(false);
  const [addressValidationError, setAddressValidationError] = useState(null);

  const validateAddress = async (values) => {
    if (values.isAddressManuallyConfirmed) {
      return { isValid: true, errors: null, suggestions: null };
    }
    setAddressValidationLoading(true);
    const addressData = constructAddressData(values);
    const endpoint = "https://x8e0ewqjdd.execute-api.us-east-1.amazonaws.com/default/validate_address";
    setAddressValidationError(null);
    try {
      const response = await axios.post(endpoint, { addressData });
      const responseBody = JSON.parse(response.data.body);
      const { verdict, address, uspsData } = responseBody.result;
      const errors = [];
      const suggestions = [];
      const addressComparisonData = {
        currentAddress: addressData,
        suggestedAddress: uspsData.standardizedAddress,
      };
      // Determine action based on validationGranularity
      //! additional logic in the future to add to our validation is verdict.hasInferredComponents === false && verdict.hasReplacedComponents === false. These can help identify the trouble issues within each address component more in depth.
      switch (verdict.validationGranularity) {
        case "OTHER":
          errors.push({
            action: "The address appears to have errors/typos. Please review the form below and fix any mistakes on the following fields:",
          });
          break;
        case "ROUTE":
        case "PREMISE":
          if (addressComparisonData?.currentAddress?.zipcode !== addressComparisonData?.suggestedAddress?.zipCode) {
            errors.push({
              action: "The zip code does not match the city/state your provide. Please correct the address and try again.",
            });
          }
          if (
            addressComparisonData?.currentAddress?.city.toLowerCase() !== addressComparisonData?.suggestedAddress?.city.toLowerCase() ||
            addressComparisonData?.currentAddress?.state.toLowerCase() !== addressComparisonData?.suggestedAddress?.state.toLowerCase()
          ) {
            suggestions.push({
              action: "The address seems mostly correct. Please confirm its accuracy.",
              buttonLabel: "Confirm Address & Try again",
              ...addressComparisonData,
            });
          }
          break;
        case "SUB_PREMISE":
          return { isValid: true, error: null, suggestions: null };
        default:
          break;
      }
      // Check for missing components
      if (address?.missingComponentTypes?.length > 0) {
        if (errors.length === 0) {
          errors.push({});
        }
        errors[0].missingComponents = address.missingComponentTypes.map((type) => componentTypeMap[type] || type);
      }
      // Check for unconfirmed and suspicious components
      address?.unconfirmedComponentTypes?.forEach((type) => {
        const matchingComponent = address.addressComponents.find((component) => component.componentType === type);

        if (
          matchingComponent &&
          (matchingComponent.confirmationLevel === "UNCONFIRMED_AND_SUSPICIOUS" ||
            matchingComponent.confirmationLevel === "UNCONFIRMED_BUT_PLAUSIBLE")
        ) {
          if (errors.length === 0) {
            errors.push({});
          }
          if (!errors[0].unconfirmedComponents) {
            errors[0].unconfirmedComponents = [];
          }
          errors[0].unconfirmedComponents.push(componentTypeMap[matchingComponent.componentType] || matchingComponent.componentType);
        }
      });
      // Check for high-quality address
      if (verdict.hasReplacedComponents === false && verdict.hasInferredComponents === false) {
        suggestions.push({ message: "The address is of high quality with no inferred or replaced components. It is ready for use." });
      }
      if (errors.length === 0 && suggestions.length === 0) {
        return { isValid: true, error: null };
      } else {
        return {
          isValid: false,
          errors,
          suggestions,
        };
      }
    } catch (error) {
      setAddressValidationLoading(false);
      setAddressValidationError(error.message);
      return { isValid: false, error: error };
    } finally {
      setAddressValidationLoading(false);
    }
  };
  return { validateAddress, addressValidationLoading, addressValidationError };
};

export default useGoogleAddressValidationSimplified;
