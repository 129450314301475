export const headerContainerTitleShopBannerVariants = {
  hidden: { opacity: 0, y: -20 },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25, type: "spring", stiffness: 300, damping: 20 },
  },
};

export const headerContainerTitleVariants2 = {
  hidden: { opacity: 0, x: -20 },
  show: {
    opacity: 1,
    x: 0,

    transition: { duration: 0.5 },
  },
};
