import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledProductDetailsSectionContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  paddingBlock: "2rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "3rem",
  },
}));
