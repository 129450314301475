import React, { createContext, useContext, useEffect, useState } from "react";
import { DirectorySearchBar } from "./directorySearchBar.context";
import useFetchTherapists from "utils/hooks/useFetchTherapist";
import useFetchGHLContacts from "pages/FindATherapist/hooks/useFetchContacts.hooks";
export const GHLUsersContext = createContext({
  users: [],
  isLoading: true,
  isError: false,
  error: null,
  refetchUsers: () => {},
});

export const GHLUsersProvider = ({ children }) => {
  const { searchTerms, filterOptions, page } = useContext(DirectorySearchBar); // Fetch search terms from context
  
  const [currentSearchTerms, setCurrentSearchTerms] = useState(searchTerms); // State to store search terms for refetch
  const [currentFilterOptions, setCurrentFilterOptions] = useState(filterOptions); // State to store filter options for refetch
  const [currentPage, setCurrentPage] = useState(page); // State to store page for refetch
  const { users, isLoading, isError, error } = useFetchGHLContacts(currentSearchTerms, currentFilterOptions, currentPage); // Fetch data based on current search terms

  // Function to handle refetch when search button is pressed
  const refetchUsers = (newSearchTerms, newFilterOptions, newPage) => {
    setCurrentSearchTerms(newSearchTerms);
    setCurrentFilterOptions(newFilterOptions) // Update search terms, triggers re-fetch
    setCurrentPage(newPage);
  };

  // Re-fetch therapists when searchTerms from context changes
  useEffect(() => {
    if (searchTerms !== currentSearchTerms) {
      refetchUsers(searchTerms, filterOptions, page); // Trigger refetch based on updated searchTerms
    }
    if (filterOptions !== currentFilterOptions) {
      refetchUsers(searchTerms, filterOptions, page); // Trigger refetch based on updated filterOptions
    }
    if (page !== currentPage) {
      refetchUsers(searchTerms, filterOptions, page); // Trigger refetch based on updated page
    }
  }, [searchTerms, filterOptions, page]);

  const value = { users, isLoading, isError, error, refetchUsers };

  return <GHLUsersContext.Provider value={value}>{children}</GHLUsersContext.Provider>;
};
