import { useAnimation, useInView } from "framer-motion";
import { useRef, useEffect } from "react";

const useSectionAnimation = (margin = "-100px 0px", amount = 0.3, once = true) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { margin, amount, once });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return { ref, controls };
};

export default useSectionAnimation;