import styled from "@emotion/styled";
import { motion } from "framer-motion";
//! motion.div - add to make this component a framer motion component but for now left as default to focus on other priorities
export const StyledHorizontalCardContainer = styled(motion.div)(({ theme, cssProps }) => ({
  alignItems: "center",
  justifyContent: "top",
  padding: "1.0rem",
  width: "100%",
  height: "100%",
  margin: "0 !important",
  backgroundColor: `${theme.palette.white.main} !important`,
  borderRadius: "12px",
  borderLeft: `10px solid ${theme.palette.accent.main} !important`,
  boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
  ...cssProps,
}));
