import { useQuery } from "@tanstack/react-query";
import axios from "axios";


const fetchGHLContacts = async (searchTerms, filterOptions, page) => {
  let url = "https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled/ghl-contacts-directory";
  
  const response = await axios.post(url,{
    headers: {
      "Content-Type": "application/json",
    },
    searchTerms,
    filterOptions,
    pageNumber: page,
    limit: 12,
  });
  const data = JSON.parse(response.data.body);
  return data;
};

const useFetchGHLContacts = (searchTerms, filterOptions, page) => {
  const {
    data: users,
    isLoading,
    isError,
    error,
    
  } = useQuery({
    queryKey: ["ghlContacts", searchTerms, filterOptions, page],
    queryFn: () => fetchGHLContacts(searchTerms,filterOptions, page),
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });

  return { users, isLoading, isError, error };
};

export default useFetchGHLContacts;
