import React, { useState } from "react";
// Components
import MKButton from "components/MKButton";
// Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";
const Pagination = ({ totalItems, itemsPerPage, onPageChange, containerRef, scrollY, bgDark }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const { isSm } = useMediaQueries();

  const handleClick = (page) => {
    setCurrentPage(page);
    onPageChange(page);
    setTimeout(() => {
      if (containerRef.current && !scrollY) {
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (containerRef.current && scrollY) {
        const y = containerRef.current.getBoundingClientRect().top + window.scrollY + scrollY;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 100);
  };

  const renderPageButtons = () => {
    const pagesToShow = isSm ? 2 : 4;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const pageButtons = [];
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <MKButton
          variant="outlined"
          color={bgDark ? "accent" : "secondary"}
          key={i}
          onClick={() => handleClick(i)}
          disabled={currentPage === i}
          style={{ marginRight: "5px" }}
          aria-label={`page ${i}`}
        >
          {i}
        </MKButton>
      );
    }
    return pageButtons;
  };

  const darkBgStyles = bgDark ? { backgroundColor: theme.palette.accent.main, color: "#fff" } : {};
  return (
    <div>
      <MKButton
        sx={{ marginRight: "1.2rem", ...darkBgStyles }}
        color={bgDark ? "accent" : "secondary"}
        aria-label="Previous Page"
        onClick={() => currentPage > 1 && handleClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </MKButton>
      {renderPageButtons()}

      <MKButton
        sx={{ marginLeft: "1rem", ...darkBgStyles }}
        color={bgDark ? "accent" : "secondary"}
        aria-label="Next Page"
        onClick={() => currentPage < totalPages && handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon />
      </MKButton>
    </div>
  );
};

export default Pagination;
