import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";

const TitleSection = ({ content, imageURL, imageAlt, cssProps, component }) => {
  const marginMap = {
    // h1: "1.5rem",
    h2: "1.5rem",
    h3: "0.75rem",
    h4: "0.5rem",
    h5: "0.25rem",
    h6: "0.125rem",
  };

  const getMargin = (componentType) => marginMap[componentType] || "1rem";

  return (
    <MKBox sx={{ ...cssProps }}>
      {imageURL ? <ImageContainer image={imageURL} alt={imageAlt} /> : null}
      <MKTypography
        variant={component || "h3"}
        component={component || "h3"}
        sx={{
          color: "#75CDC5",
          marginBlock: getMargin(component || "h3"),
        }}
      >
        {content}
      </MKTypography>
    </MKBox>
  );
};

export default TitleSection;
