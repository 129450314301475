import styled from "@emotion/styled";
import { Box } from "@mui/material";
import rgba from "assets/theme/functions/rgba";
import MKTypography from "components/MKTypography";

export const StyledFAQSection = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  boxShadow: 1,
  padding: "1rem",
  borderRadius: "12px",
}));

export const StyledFAQItem = styled(Box)(({ theme, isSelected, textColor }) => ({
  transition: "all 0.3s ease-in-out",
  border: "none",
  padding: "1rem",
  borderRadius: "12px",
  background: isSelected ? `linear-gradient(to top, ${rgba(theme.palette.accent.main, 0.2)}, ${rgba(theme.palette.accent.main, 0)})` : "transparent",
  "& .hover-text": {
    color: isSelected && `${theme.palette.accent.main} !important`,
  },
  "&:hover": {
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    background:
      textColor === "#fff"
        ? `linear-gradient(to top, ${rgba(theme.palette.grey[100], 0.2)}, ${rgba(theme.palette.grey[100], 0)})`
        : `linear-gradient(to top, ${rgba(theme.palette.secondary.main, 0.2)}, ${rgba(theme.palette.secondary.main, 0)})`,
  },
}));

export const StyledFAQHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "2rem",
});

export const StyledFAQTitle = styled(MKTypography)(({ textColorStyle }) => ({
  fontWeight: 500,
  color: "inherit !important",
  transition: "all 0.3s ease-in-out",
  ...textColorStyle,
}));
