import React, { useContext, useState, useMemo, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Grid, List, ListItem, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { StyledProductDetailsSectionContainer } from "./index.styles";
import { Link, useLocation } from "react-router-dom";
import { StyledButtonContainer } from "index.styles";
import BreadCrumbs from "../bread-crumbs";
import { CartContext } from "utils/context/cart.context";
// import EllipsisText from "components/CBEllipsisText";
// import { parseIncludes } from "utils/helpers/parseIncludesMetadata";
import useMediaQueries from "utils/mediaQueries.utils";
import theme from "assets/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { parseProductDesc } from "utils/helpers/parseIncludesMetadata";
import CBDivider from "components/CBDivider";
import ProductAccordion from "components/Ecom/components/productAccordion";

// addons
const ProductDetails = ({ products }) => {
  const location = useLocation();
  // const [showMore, setShowMore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { addItemToCart, cartItems, setIsCartOpen } = useContext(CartContext);
  const productId = location.state?.productId;
  const { isMd } = useMediaQueries();
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  const product = useMemo(() => {
    if (!products) return null;

    if (productId) {
      return products.find((product) => product.id === productId);
    } else {
      const urlSlug = location.pathname.split("/").pop();
      const slugWords = urlSlug.split("-").map((word) => word.toLowerCase());
      let bestMatch = null;
      let highestMatchPercentage = 0;
      products.forEach((product) => {
        const productNameWords = product.name
          .toLowerCase()
          .split(/\W+/)
          .filter((word) => word.length > 0);

        const matchCount = slugWords.reduce((count, slugWord) => {
          return productNameWords.some((nameWord) => nameWord.includes(slugWord) || slugWord.includes(nameWord)) ? count + 1 : count;
        }, 0);

        const matchPercentage = (matchCount / slugWords.length) * 100;

        if (matchPercentage > highestMatchPercentage) {
          highestMatchPercentage = matchPercentage;
          bestMatch = product;
        }
      });

      return highestMatchPercentage > 50 ? bestMatch : null;
    }
  }, [products, productId, location.pathname]);

  // const includes = useMemo(() => {
  //   if (!product?.metadata) return [];
  //   return parseIncludes(product.metadata);
  // }, [product]);

  const productDesc = useMemo(() => {
    if (!product?.metadata) return [];
    return parseProductDesc(product.metadata);
  }, [product]);

  const packageInCart = useMemo(() => {
    return cartItems.find((cartItem) => cartItem.metadata?.type === "package");
  }, [cartItems]);

  const addOnInCart = useMemo(() => {
    return cartItems.find((cartItem) => cartItem.metadata?.type === "addon" && cartItem.id === product.id);
  }, [cartItems, product]);

  useEffect(() => {
    if (product?.metadata?.type === "package" && packageInCart) {
      setAlertMessage("This item or another package is already added to your cart.");
      setShowAlert(true);
    } else if (product?.metadata?.type === "addon" && addOnInCart) {
      setAlertMessage("This add-on is already added to your cart.");
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [packageInCart, addOnInCart, product?.metadata?.type]);

  // const displayedItems = showMore ? includes : includes.slice(0, 3);

  // const toggleShowMore = () => {
  //   setShowMore((prevShowMore) => !prevShowMore);
  // };

  const handleAddToCart = () => {
    if (product.metadata?.type === "package" && packageInCart) {
      setAlertMessage("This item or another package is already added to your cart.");
      setShowAlert(true);
      addItemToCart(product);
      setIsCartOpen(true);
    } else if (product.metadata?.type === "addon" && addOnInCart) {
      setAlertMessage("This add-on is already added to your cart.");
      setShowAlert(true);
      addItemToCart(product);
      setIsCartOpen(true);
    } else {
      addItemToCart(product);
      addItemToCart(product);

      setIsCartOpen(true);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  if (!product)
    return (
      <StyledProductDetailsSectionContainer id="product-details-page-product-not-found">
        <Alert severity="warning">
          Product not found. <Link to="/ketamine-therapy/packages">Return to shop</Link>{" "}
        </Alert>
      </StyledProductDetailsSectionContainer>
    );

  return (
    <StyledProductDetailsSectionContainer id="product-details-page">
      <BreadCrumbs packageName={`${product?.name}`} />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <MKBox
            component="img"
            sx={{ width: { xs: "100%" }, marginTop: ".5rem !important" }}
            src={`${product?.images[0]}`}
            alt={`${product?.name} product image`}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKTypography
            component="h1"
            variant="h3"
            gutterBottom
            sx={{ display: "flex", alignItems: "center", color: `${theme.palette.primary.main} !important` }}
          >
            {product.name}
            {product.metadata.sessionCount && (
              <MKTypography
                component="span"
                sx={{ fontSize: "14px", fontWeight: "normal", marginLeft: ".5rem", color: `${theme.palette.accent.main} !important` }}
              >
                ({product.metadata.sessionCount} sessions included)
              </MKTypography>
            )}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
            {product.metadata.priceValue}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ marginBottom: "2rem" }}>
            {product.metadata.long_description}
          </MKTypography>
          <MKTypography component="h3" variant="h6" gutterBottom>
            {product.metadata.includesTitle}
          </MKTypography>
          <ProductAccordion titles={JSON.parse(product.metadata.product_details_bullet_point_titles)} descriptions={productDesc} />

          <MKTypography component="small" variant="body2" sx={{ marginBottom: "2rem", fontSize: "13px", color: theme.palette.grey[700] }}>
            * If you decide not to move forward after the consultation or are found to be an unsuitable candidate for ketamine therapy, any charges
            will be refunded minus $150 for the initial medical assessment billed at $150. Learn more about our refund policy{" "}
            <Link to="/refund-policy">here</Link>.
          </MKTypography>

          <StyledButtonContainer>
            {showAlert && (
              <Alert
                severity="info"
                onClose={() => setShowAlert(false)}
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  fontSize: "13px",
                  alignItems: { xs: "flex-start", md: "center" },
                }}
              >
                <Stack direction={isMd ? "row" : "column"} alignItems={isMd ? "center" : "flex-start"} spacing={isMd ? 1 : 0}>
                  <MKTypography component="span" sx={{ fontSize: "13px" }}>
                    {alertMessage}
                  </MKTypography>
                  <MKButton sx={{ padding: 0, textDecoration: "underline" }} variant="text" color="secondary" onClick={() => setIsCartOpen(true)}>
                    View Cart
                  </MKButton>
                </Stack>
              </Alert>
            )}
            <MKButton
              // disabled={showAlert || packageInCart || addOnInCart}
              disabled={showAlert}
              onClick={handleAddToCart}
              variant="contained"
              color="secondary"
              sx={{ margin: "auto" }}
            >
              Add To Cart
            </MKButton>
          </StyledButtonContainer>
        </Grid>
      </Grid>
    </StyledProductDetailsSectionContainer>
  );
};

export default ProductDetails;
