import React from "react";
import MKTypography from "components/MKTypography";
import { StyledHeaderContainer } from "./index.styles";
import theme from "assets/theme";
import { motion } from "framer-motion";
const HeaderContainer = ({
  title,
  textColor,
  highlightText = [],
  highlightColor,
  component = "h1",
  variant = "h1",
  cssProps,
  subtitle,
  subtitleTextColor,
  ...props
}) => {
  if (typeof highlightText === "string") {
    highlightText = [highlightText];
  }
  const processTitle = (text) => {
    return text?.split(/\\n/).map((part, index, array) => (
      <React.Fragment key={index}>
        {part}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const highlightOffColorText = () => {
    let result = [{ text: title, highlight: false }];

    highlightText?.forEach((word) => {
      result = result.flatMap((segment) => {
        if (segment.highlight) return [segment];

        // Escape special regex characters in the word
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const parts = segment.text.split(new RegExp(`(${escapedWord})`, "gi"));
        return parts.map((part) => ({
          text: part,
          highlight: part.toLowerCase() === word.toLowerCase(),
        }));
      });
    });

    return result.map((segment, index) => (
      <React.Fragment key={index}>
        {segment.highlight ? <span style={{ color: highlightColor }}>{processTitle(segment.text)}</span> : processTitle(segment.text)}
      </React.Fragment>
    ));
  };


  return (
    <StyledHeaderContainer
      cssProps={cssProps}
    >
      {subtitle && (
        <MKTypography
          component={props?.motionProps ? motion.h2 : "h2"}
          variant="h6"
          gutterBottom
          sx={{ color: subtitleTextColor ? `${subtitleTextColor} !important` : `${theme.palette.accent.main} !important` }}
          animate={props?.motionProps?.animate}
          initial={props?.motionProps?.initial}
          variants={props?.motionProps?.variants?.child}
        >
          {subtitle}
        </MKTypography>
      )}
      <MKTypography
        component={props?.motionProps ? motion[component] : component}
        animate={props?.motionProps?.animate}
        initial={props?.motionProps?.initial}
        variants={props?.motionProps?.variants?.child}
        variant={variant}
        sx={{ color: textColor ? `${textColor} !important` : "white !important" }}
      >
        {highlightOffColorText()}
      </MKTypography>
    </StyledHeaderContainer>
  );
};

export default HeaderContainer;
