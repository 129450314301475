import styled from "@emotion/styled";
import { Container, Stack, Grid } from "@mui/material";

export const StyledFormContainer = styled(Grid)(({ theme }) => ({
    opacity: 0.8, 
    // border: "1px solid white",
    padding: "1rem !important",
    marginBlock: "1rem",
    fontWeight: "bold",
    backgroundColor: theme.palette.white.main,
    borderRadius: "1rem",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
}));

export const StyledInputContainer = styled(Container)(({ theme }) => ({
    marginTop: "1rem",
}));