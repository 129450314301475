import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";
import useMediaQueries from "utils/mediaQueries.utils";
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";

const ParagraphSection = ({ content, imageURL, imageAlt, cssProps, lastItem, keywords }) => {
  const { isLg } = useMediaQueries();
  const updatedContent = replaceKeywordWithLink(content, keywords, null);

  return (
    <MKBox sx={{ ...cssProps }}>
      {imageURL ? <ImageContainer image={imageURL} alt={imageAlt} /> : null}
      <MKTypography variant={isLg ? "body1" : "body2"} component="p" sx={{ marginBottom: lastItem ? "2rem" : "1rem" }}>
        {updatedContent}
      </MKTypography>
    </MKBox>
  );
};

export default ParagraphSection;
