import { useState } from "react";
import axios from "axios";
import { generateEndpoint } from "utils/helpers/generateEndpoint";

const usePurchaseMade = () => {
  const [purchaseAutomationLoading, setPurchaseAutomationLoading] = useState(false);
  const [purchaseAutomationError, setPurchaseAutomationError] = useState(null);
  const [purchaseAutomationResponse, setPurchaseAutomationResponse] = useState(null);

  const purchaseMade = async (purchaseData) => {
    setPurchaseAutomationLoading(true);
    setPurchaseAutomationError(null);

    try {
      const endpoint = generateEndpoint(
        process.env.REACT_APP_AWS_DEV_POST_STRIPE_PURCHASE_AUTOMATION_ENDPOINT,
        process.env.REACT_APP_AWS_PROD_POST_STRIPE_PURCHASE_AUTOMATION_ENDPOINT
      );
      const response = await axios.post(
        endpoint,
        {
          data: purchaseData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setPurchaseAutomationResponse(response.data);
      return response.data;
    } catch (err) {
      setPurchaseAutomationError(err.message);
      console.error("Zapier Error:", err);
      return null;
    } finally {
      setPurchaseAutomationLoading(false);
    }
  };

  return { purchaseMade, purchaseAutomationLoading, purchaseAutomationError, purchaseAutomationResponse };
};

export default usePurchaseMade;
