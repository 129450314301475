import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import theme from "assets/theme";
import rgba from "assets/theme/functions/rgba";
// Framer Motion
import { motion, AnimatePresence } from "framer-motion";
// Styled components
import { Alert, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import HorizontalInfoCard from "styles/components/horizontalInfoCard";
// Styles
import { StyledButtonContainer } from "index.styles";
// Utilities

import useMediaQueries from "utils/mediaQueries.utils";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";
import ListComponent from "components/CBList";
import MKBox from "components/MKBox";
import { CartContext } from "utils/context/cart.context";

const cardAnimations = {
  whileHover: { scale: 1.05 },
  initial: { opacity: 0 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 1, scale: 1 },
  transition: { duration: 0.5 },
};

const expandedCardAnimations = {
  initial: { opacity: 0, scale: 1 },
  animate: {
    opacity: 1,
    // transform: "translate3d(206.5px, 115px, 0px)",
  },
  exit: { opacity: 0, scale: 1 },
  transition: { duration: 0.5 },
};

const infoCardCssProps = {
  minHeight: "250px",
  width: "100%",
  display: "flex",
  paddingBlock: "1rem",
  transform: "none",
  transformOrigin: "50% 50% 0",
  [theme.breakpoints.up("md")]: {
    width: "32%",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".horizontal-addon-info-card-stack": {
    textAlign: "center",
  },
};

const ExpandedInfoCard = styled(motion.div)(({ theme }) => ({
  borderRadius: "1rem",
  backgroundColor: "white",
  zIndex: 1001,
  position: "absolute",
  width: "100%",
  padding: "1rem",
  overflow: "auto",
  borderLeft: `10px solid ${theme.palette.accent.main} !important`,

  [theme.breakpoints.up("lg")]: {
    // maxHeight: "100%",
    height: "140%",
    width: "60%",
  },
}));

const Overlay = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: rgba(theme.palette.primary.main, 0.5),
  backdropFilter: "blur(2px)",
  zIndex: 1000,
});

const InfoAddonCard = ({ ...props }) => {
  const { images, index, name, price, id, isItemInCart, handleAddToCart, description, metadata, selectedId, setSelectedId } = props;
  // const [selectedId, setSelectedId] = useState(null);
  // const { addItemToCart, cartItems, setIsCartOpen } = useContext(CartContext);
  const isSelected = selectedId === id;
  const { isMd } = useMediaQueries();

  const handleToggleCard = (e) => {
    if (selectedId === e?.currentTarget?.id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
      //! scroll to card after clicking
      // const expandedCard = document.getElementById("test");
      // setTimeout(() => {
      //   if (isLg) {
      //     expandedCard.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest", offsetTop: 100 });
      //   } else {
      //     expandedCard.scrollIntoView({ behavior: "smooth" });
      //   }
      // }, 500);
    }
  };

  // ! prevent scroll when card is expanded
  // useEffect(() => {
  //   if (selectedId) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [selectedId]);

  const TopCardComponent = () => <img src={images[0]} alt={name} style={{ width: "100px", height: "100px", margin: "0 auto" }} />;

  const MiddleCardComponent = () => (
    <>
      <MKTypography component="h6" variant="h6" gutterBottom sx={{ minHeight: { md: 39 } }}>
        {name}
      </MKTypography>
      <MKTypography component="small" variant="body2" sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}>
        ${(price / 100).toFixed(2)}
      </MKTypography>
    </>
  );

  const BottomCardComponent = () => (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
      <MKButton
        disabled={isItemInCart}
        id={id}
        onClick={(e) => handleAddToCart(e, index)}
        size="small"
        color="secondary"
        variant="contained"
        sx={{ margin: "1rem", display: "flex", minWidth: "200px" }}
      >
        Add To Cart
      </MKButton>
    </Stack>
  );

  return (
    <>
      <HorizontalInfoCard
        // id={"test"}
        // isSelected={selectedId === id}
        key={id}
        layoutId={id}
        handleClick={handleToggleCard}
        {...cardAnimations}
        cssProps={infoCardCssProps}
        topComponent={<TopCardComponent />}
        midComponent={<MiddleCardComponent />}
        botComponent={<BottomCardComponent />}
      />
      <AnimatePresence>
        {selectedId && selectedId === id && (
          <>
            <Overlay onClick={() => setSelectedId(null)} />
            <ExpandedInfoCard layoutId={selectedId} {...expandedCardAnimations}>
              {/* <StyledButtonContainer
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0rem !important",
                }}
              >
                <MKButton id={id} onClick={handleToggleCard} endIcon={<CloseIcon />} sx={{ padding: 0 }}></MKButton>
              </StyledButtonContainer> */}
              <Stack spacing={2}>
                <img src={images[0]} alt={name} style={{ width: "150px", height: "150px", margin: "0 auto" }} />
                <Stack direction={"column"} alignItems={"center"} spacing={1} sx={{ marginBottom: "1rem" }}>
                  <MKTypography component="h6" variant="h6">
                    {name}
                  </MKTypography>
                  <MKTypography component="small" variant="body2" sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}>
                    ${(price / 100).toFixed(2)}
                  </MKTypography>
                </Stack>
                <MKTypography component="body2" variant="body2" textAlign={"center"}>
                  {metadata.long_description}
                </MKTypography>
                {/* expanded card bottom */}
                <StyledButtonContainer sx={{ display: "flex", gap: 2, justifyContent: "center", bottom: "1rem", flexDirection: "column" }}>
                  {isItemInCart && (
                    <Alert
                      severity="info"
                      // onClose={() => setShowAlert(false)}
                      sx={{
                        marginBottom: "1rem",
                        display: "flex",
                        fontSize: "13px",
                        alignItems: { xs: "flex-start", md: "center" },
                      }}
                    >
                      <Stack direction={isMd ? "row" : "column"} alignItems={isMd ? "center" : "flex-start"} spacing={isMd ? 1 : 0}>
                        <MKTypography component="span" sx={{ fontSize: "13px" }}>
                          This item is already added to your cart.
                        </MKTypography>
                      </Stack>
                    </Alert>
                  )}
                  <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                    <MKButton
                      disabled={isItemInCart}
                      id={id}
                      onClick={(e) => handleAddToCart(e, index)}
                      size="small"
                      color="secondary"
                      variant="contained"
                    >
                      Add To Cart
                    </MKButton>
                    <MKButton
                      size="small"
                      component={Link}
                      to={`/ketamine-therapy/packages/${formatTitleForURL(name)}`}
                      variant="outlined"
                      color="secondary"
                    >
                      View Addon Details
                    </MKButton>
                  </Stack>
                </StyledButtonContainer>
              </Stack>
            </ExpandedInfoCard>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default InfoAddonCard;
