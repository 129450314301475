export const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
      when: "beforeChildren",
      delay: 0.25,
    },
  },
};

export const listItemVariants = {
  hidden: { opacity: 0, y: 10 },
  show: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 260,
      damping: 20,
      delay: custom * 0.15,
    },
  }),
};

export const listItemShopBannerVariants = {
  hidden: { opacity: 0, x: -10 },
  show: (custom) => ({
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 280,
      damping: 10,
      delay: custom * 0.3,
      duration: 0.5,
    },
  }),
};
