import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "assets/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
// Components
import { Alert, Drawer, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import CouponsComponent from "./components/couponsComponent";
import CartItems from "./components/cartItems";
import CartOrderSummary from "./components/cartOrderSummary";
import EmptyCart from "./components/empty-cart";
import Addons from "../Addons";
// Styled components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
// Utilities
import { CartContext } from "utils/context/cart.context";
import { scrollToTop } from "utils/helpers/scrollToTop";
import useImagePreloader from "utils/hooks/useImagePreloader";
import { HashLink } from "react-router-hash-link";

const sectionContainerStyles = {
  paddingBlock: "2rem !important",
  paddingInline: "0rem !important",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "2rem !important",
  },
};

const styledHeaderStyles = {
  marginBottom: "1.5rem",
};

const CartNavigationOptions = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleContinueShopping = () => {
    if (location.pathname === "/ketamine-therapy/packages") {
      toggleDrawer(false)();
      scrollToTop();
    } else {
      navigate(`${location.pathname}`);
      toggleDrawer(false)();
      scrollToTop();
    }
  };

  useImagePreloader(["https://d3a0rs3velfrz.cloudfront.net/cart/9960436.jpg"]);
  return (
    <Stack
      sx={{
        boxShadow: `0px 0px 10px 0px ${theme.palette.grey[400]}`,
        width: "100%",
        padding: "1rem",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <MKTypography
        onClick={handleContinueShopping}
        sx={{ color: theme.palette.secondary.main, textDecoration: "none", display: "flex", alignItems: "center", cursor: "pointer", zIndex: 1000 }}
      >
        <ArrowBackIcon sx={{ marginRight: ".5rem", height: "18px", width: "18px" }} />
        <MKTypography sx={{ color: theme.palette.secondary.main, lineHeight: 1 }} component="p" variant="body2">
          Continue Shopping
        </MKTypography>
      </MKTypography>
      <MKButton sx={{ padding: "0px !important", display: "flex", justifyContent: "flex-end" }} onClick={toggleDrawer(false)}>
        <CloseIcon sx={{ color: theme.palette.secondary.main, height: "20px", width: "20px" }} />
      </MKButton>
    </Stack>
  );
};
const Cart = ({ addons }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [conflictingItems, setConflictingItems] = useState([]);
  const { isCartOpen, setIsCartOpen, cartItems, discount, originalSubtotal, cartTotal, isCartEmpty } = useContext(CartContext);

  const handleViewPackages = () => {
    setIsCartOpen(false);
    scrollToTop();
  };

  const toggleDrawer = (newOpen) => () => {
    setIsCartOpen(newOpen);
  };

  useEffect(() => {
    setIsCartOpen(false);
  }, []);

  useEffect(() => {
    if (isCartOpen) {
      checkForConflicts();
    }
  }, [isCartOpen, cartItems]);

  const checkForConflicts = () => {
    const packages = cartItems.filter((item) => item.metadata?.type === "package");
    const addons = cartItems.filter((item) => item.metadata?.type === "addon");

    let conflicts = [];
    let message = "";

    if (packages.length === 0) {
      conflicts = ["No packages"];
      message = (
        <>
          You must have a package in your cart to checkout.{" "}
          <HashLink
            style={{ color: theme.palette.secondary.main }}
            scroll={(el) => {
              const yOffset = -100;
              const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
              window.scrollTo({ top: y, behavior: "smooth" });
            }}
            onClick={handleViewPackages}
            to={"/ketamine-therapy/packages#product-details-section"}
          >
            Click here to view packages.
          </HashLink>
        </>
      );
    } else if (packages.length > 1) {
      conflicts = [...conflicts, ...packages];
      message = "Only one package is allowed in the cart. Please remove extra packages.";
    }

    const duplicateAddons = addons.filter((addon, index, self) => index !== self.findIndex((t) => t.id === addon.id));

    if (duplicateAddons.length > 0) {
      conflicts = [...conflicts, ...duplicateAddons];
      message += message ? " Also, d" : "D";
      message += "uplicate add-ons are not allowed. Please remove duplicate add-ons.";
    }

    setConflictingItems(conflicts);
    if (conflicts.length > 0) {
      setAlertMessage(message);
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };

  const DrawerList = (
    <>
      <CartNavigationOptions toggleDrawer={toggleDrawer} />
      <MKBox
        sx={{
          width: { xs: "90vw", md: "75vw", lg: "60vw" },
          maxWidth: "800px",
          height: "200vh",
          padding: { xs: "1.5rem 1rem", md: "1.5rem 2rem" },
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
        id="cart-container"
        role="presentation"
      >
        <StyledSectionContainer cssProps={sectionContainerStyles} id="coupons-component-container">
          <MKTypography sx={{ ...styledHeaderStyles }} component="h3" variant="h3">
            Enter Promo Code
          </MKTypography>
          <CouponsComponent />
        </StyledSectionContainer>
        <CBDivider />

        <StyledSectionContainer cssProps={sectionContainerStyles} id="cart-items-container">
          <Stack direction="column" justifyContent="space-between">
            <MKTypography sx={{ ...styledHeaderStyles }} component="h3" variant="h3">
              Items Added to Cart ({cartItems.length})
            </MKTypography>
            {cartItems.length === 0 && <EmptyCart toggleDrawer={toggleDrawer} />}
            {showAlert && (
              <Alert severity="warning" onClose={() => setShowAlert(false)} sx={{ marginBottom: "1rem" }}>
                <MKTypography component="span" sx={{ fontSize: "13px" }}>
                  {alertMessage}
                </MKTypography>
              </Alert>
            )}
          </Stack>
          <CartItems cartItems={cartItems} conflictingItems={conflictingItems} />
        </StyledSectionContainer>
        <CBDivider />
        <StyledSectionContainer cssProps={sectionContainerStyles} id="cart-order-summary-container">
          <MKTypography sx={{ ...styledHeaderStyles }} component="h3" variant="h3">
            Order Summary
          </MKTypography>
          <CartOrderSummary
            cartItems={cartItems}
            setIsCartOpen={setIsCartOpen}
            isCartEmpty={isCartEmpty}
            conflictingItems={conflictingItems}
            discount={discount}
            originalSubtotal={originalSubtotal}
            cartTotal={cartTotal}
          />
        </StyledSectionContainer>
        <CBDivider />

        <StyledSectionContainer cssProps={{ ...sectionContainerStyles }} id="cart-suggested-addons-container">
          <MKTypography sx={{ ...styledHeaderStyles }} component="h3" variant="h3">
            Suggested Add-ons
          </MKTypography>
          <Addons addons={addons} header="Suggested" headerAccent="Add-ons" />
        </StyledSectionContainer>
      </MKBox>
    </>
  );
  return (
    <Drawer sx={{ position: "relative" }} aria-label="Cart" anchor="right" open={isCartOpen} onClose={toggleDrawer(false)}>
      {DrawerList}
    </Drawer>
  );
};

export default Cart;
