import React from "react";
import { motion } from "framer-motion";
import useSectionAnimation from "../hooks/useSectionAnimation";

const withSectionAnimation = (Component, variants = {}, cssProps = {}) => {
  return (props) => {
    const { ref, controls } = useSectionAnimation();

    return (
      <motion.div ref={ref} initial="hidden" animate={controls} variants={variants} style={cssProps}>
        <Component {...props} />
      </motion.div>
    );
  };
};

export default withSectionAnimation;
