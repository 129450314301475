// Major
import React,{useState} from "react";

// Components
import MKTypography from "components/MKTypography";
import EditForm from "../EditForm";
import usePutTherapistByID from 'utils/hooks/usePutTherapistByID';
import CBLoading from "components/CBLoading";
import EditButton from "../EditButton";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";

// Styles
import { 
        StyledStackContainer, 
        StyledSpecialistItem,
        StyledTherapyServicesContainer
    } from "./index.styles";
import MKBox from "components/MKBox";
import theme from "assets/theme";


const TherapyServicesEdit = ({userData, handleUpdateUserData}) => {
    const { email, phone, state, city, name, tags, customFields } = userData || [];
    
    const visitTypes = findCustomFieldValue("Visit Types", customFields);
    const treatmentMethods = findCustomFieldValue("Treatment Methods", customFields);
    const specialties = findCustomFieldValue("Specialties", customFields);
    const psychedelicsYouWorkWith = findCustomFieldValue("Psychedelics You Work With", customFields);
    const typesOfTherapy = findCustomFieldValue("Types Of Therapy", customFields);
    const { updateContactInfo, data, isLoading, isError, error, } = usePutTherapistByID();
    const [showForm, setShowForm] = useState(false);

    const Specialties = () => {
        if(!specialties) return null;
        return (
            <StyledStackContainer sx={{display: "center", flexWrap: "wrap"}}>
                    {specialties?.map((item, index) => (
                            <StyledSpecialistItem key={index}>
                                {item}
                            </StyledSpecialistItem>
                    ))}
            </StyledStackContainer>
        )
    }

    const PsychedelicsYouWorkWith = () => {
        if(!psychedelicsYouWorkWith) return null;
        return (
            <StyledStackContainer sx={{ margin: "0.5rem" }}>
                <MKTypography component="h6" variant="h6" gutterBottom fontWeight="light">
                    <b>Types Of Psychedelics I Work With:</b> 
                </MKTypography>
                    {psychedelicsYouWorkWith?.map((item, index) => (
                            
                            <MKTypography key={index} component="p" variant={"body2"}>
                                {item}
                            </MKTypography>
                    ))}
            </StyledStackContainer>
        )    
    }

    const handleSubmit = async (values) => {
        const response = await updateContactInfo(values, userData.id);
        if(response){
            handleUpdateUserData(response);
        }
    }


    const ListItems = () => {
        let items = [];
        let itemTitle = ["Treatment Methods", "Visit Types", "Types Of Therapy"];
        if(treatmentMethods) {
            items.push(treatmentMethods);
        }
        if(visitTypes) {
            items.push(visitTypes);
        }
        if(typesOfTherapy) {
            items.push(typesOfTherapy);
        }
        return (
            <>
            {items?.map((item, index) => (
                <StyledStackContainer key={index} sx={{ margin: "0.5rem" }}>
                    <MKTypography component="p" variant="body2" gutterBottom fontWeight="light">
                        <b>{itemTitle[index]}:</b> {item?.join(", ")} 
                    </MKTypography>
                </StyledStackContainer>
            ))}
            </>
        )
    }

    const questions = [
        
        {
            name: "Specialties",
            answer: specialties ? specialties: "",
            placeholder: "Specialties",
            type: "select",
            variant: "outlined",
            rows: 1,
            options: [{value: "Anxiety", label: "Anxiety"}, {value: "PTSD", label: "PTSD"}, {value: "Depression", label: "Depression"}, {value: "OCD", label: "OCD"}, {value: "Bi-polar", label: "Bi-polar"}, {value: "Phobias", label: "Phobias"}, {value: "Panic Disorders", label: "Panic Disorders"}, {value: "Addictions", label: "Addictions"}, {value: "Eating Disorders", label: "Eating Disorders"}, {value: "Personality Disorders", label: "Personality Disorders"}, {value: "Sexual Disorders", label: "Sexual Disorders"} ],
            multi: true,
        },
        {
            label: "Treatment Methods",
            answer: treatmentMethods,
            name: "Treatment Methods",
            type: "select",
            multi: true,
            rows: 1,
            options: [
                {label: "ACT", value: "ACT"},
                {label: "CBT", value: "CBT"},
                {label: "DBT", value: "DBT"},
                {label: "Ego State/Parts Work", value: "Ego State/Parts Work"},
                {label: "Supportive Pscyhotherapy", value: "Supportive Pscyhotherapy"},
                {label: "Pschodynamic Pschotherapy", value: "Pschodynamic Pschotherapy"},
                {label: "IFS", value: "IFS"},
                {label: "Other", value: "Other"},
            ],

        },
        {
            label: "Visit Types",
            answer: visitTypes,
            name: "Visit Types",
            type: "select",
            multi: true,
            rows: 1,
            options: [
                {label: "In-Person", value: "In-Person"},
                {label: "Vitural", value: "Vitural"},
            ],
        },
        {
            label: "Types Of Therapy",
            answer: typesOfTherapy,
            name: "Types Of Therapy",
            type: "select",
            multi: true,
            rows: 1,
            options: [
                {label: "Clinical/Medical", value: "Clinical/Medical"},
                {label: "Pyschotherapeutic", value: "Pyschotherapeutic"},
                {label: "Naturalistic", value: "Naturalistic"},
                {label: "Group", value: "Group"},
                {label: "Solo", value: "Solo"},
                {label: "Spiritural/Religious - General", value: "Spiritural/Religious - General"},
                {label: "Spiritural-Shamanic", value: "Spiritural-Shamanic"},
                {label: "Spiritural-Entheogenic", value: "Spiritural-Entheogenic"},
            ],
        },

    ];

    
    return (
        <StyledTherapyServicesContainer id="therapist-bio-services">
            {isLoading ? <CBLoading/> : 
            <>
            <MKTypography component="h3" variant="h5" gutterBottom>
                Therapy Services <EditButton showForm={showForm} setShowForm={setShowForm}/>
            </MKTypography>
            <Specialties />
            <ListItems />
            <PsychedelicsYouWorkWith />
            {showForm ? <EditForm questions={questions} handleSubmit={handleSubmit} /> : null}
            </>
            }
        </StyledTherapyServicesContainer>
    );
}

export default TherapyServicesEdit;