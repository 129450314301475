// Major
import React, {useState} from "react";

// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Styles
import theme from "assets/theme";
import { Stack } from "@mui/material";
import { StyledProfileCardImage, StyledHeaderContainer, StyledEllipsisTypographyLink } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { convertState } from "utils/helpers/stateConverter";
import useMediaQueries from "utils/mediaQueries.utils";

// Icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TAIconImage from "components/TAIconImage";
import LanguageIcon from "@mui/icons-material/Language";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";
import { BorderColor } from "@mui/icons-material";

const Header = ({ currentUserData, handleEmailClick }) => {
  const { isLg, isSmUp, isMd } = useMediaQueries();
  const { email, phone, state, city, name,tags, customFields } = currentUserData || [];
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const yearsPracticed = findCustomFieldValue("Years Practiced", customFields);
  const linkedIn = findCustomFieldValue("LinkedIn", customFields);
  const specialties = findCustomFieldValue("Specialties", customFields);

  const isPartner = tags?.includes("partners education complete");
  
  const handlePhoneClick = () => {
    if (phone) {
      window.location.href = `tel:+${phone}`;
    }
  };
  const buttonInfo = [
    // {
    //   iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/telephone-handle-silhouette.webp",
    //   alt: "Phone Icon",
    //   size: "sm",
    // },
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/envelope.webp",
      alt: "Email Icon",
      size: "sm",
    },
  ];


  

  // Mappping through the buttonInfo array to render the buttons
  const ButtonOptions = () => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ width: "100%", maxWidth: isLg ? "200px" : "100%" }}>
        {buttonInfo.map((button, index) => {
          return (
            <MKButton
              startIcon={<TAIconImage alt={button.alt} size={button.size} iconURL={button.iconURL} />}
              onClick={handleEmailClick}
              disabled={index === 0 ? !phone : !email}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width: "100%" }}
            >
              Contact Me
            </MKButton>
          );
        })}
      </Stack>
    );
  };
  const PartnerTag = () => {
    return(
      <><img
            src={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
            style={{ height: "24px", width: "24px", margin:"0 0.5rem 0 0" }}
            height="0"
            width="0"
            alt="Almadelic butterfly logo"
          /><span style={{color: `${theme.palette.secondary.main}`}}>Partner</span></>
    )
  }
  const activeColor = tags.includes("partners education complete") ? theme.palette.secondary.main : theme.palette.accent.main;
  const iconStyles = {
    width: "24px",
    height: "24px",
    color: activeColor,
  };
  
  return (
    <StyledSectionBackgroundWrapper id="therapist-bio-header">
      <StyledHeaderContainer
        sx={{
          flexDirection: isSmUp ? "row" : "column",
          flexWrap: isMd ? "no-wrap" : "wrap",
          paddingLeft: isSmUp ? "4rem" : "2rem",
          paddingRight: isSmUp ? "4rem" : "2rem",
          position: "relative",
        }}
      >
        <StyledProfileCardImage
          component="img"
          src={profileURL || "https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/1-1_high_res_butterfly.svg"}
          alt={`${name} profile image`}
          activeColor={activeColor}
          sx={{ margin: isLg ? "0" : "auto",}}
        />
        
        <Stack sx={{ flexGrow: 1, maxWidth: isMd ? "50%" : "100%", alignItems: isLg || isMd ? "" : "center" }} spacing={1.0}>
          <MKTypography sx={{ color: `${theme.palette.light.main} !important`, alignItems: "center", display:"flex" }} component="h1" variant="h6" gutterBottom>
            {name} &nbsp;{tags?.includes("partners education complete") ? <PartnerTag /> : null}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important`, textAlign: isLg || isMd ? "" : "center" }} gutterBottom>
            {specialties?.join(", ")}
          </MKTypography>
          {yearsPracticed && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              {/* <TAIconImage alt="Star Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/star.webp"} /> */}
              <WorkIcon sx={iconStyles} />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {yearsPracticed} Years Exp
              </MKTypography>
            </Stack>
          )}
          <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
            {/* <TAIconImage alt="Pin Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/map-marker.webp"} /> */}
            <FmdGoodIcon sx={iconStyles} />
            <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
              {city && `${city.charAt(0).toUpperCase() + city.slice(1)}, `}
              {convertState(state)}
            </MKTypography>
          </Stack>
          {websiteURL && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              {/* <TAIconImage alt="World Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/earth-globe.webp"} /> */}
              <LanguageIcon sx={iconStyles} />
              <StyledEllipsisTypographyLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: activeColor }}>
                {websiteURL}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
          {linkedIn && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <LinkedInIcon
                style={iconStyles}
              />
              <StyledEllipsisTypographyLink target="_blank" href={linkedIn} sx={{ fontSize: "14px", color: activeColor }}>
                {linkedIn}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
          
        </Stack>
        <ButtonOptions />
      </StyledHeaderContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default Header;
