import React, {useContext, useState} from "react";
import { FormControl, MenuItem, OutlinedInput, Select, ListSubheader, InputLabel, Container } from "@mui/material";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { DirectorySearchBar } from "utils/context/directorySearchBar.context";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useMediaQueries from "utils/mediaQueries.utils";



const PrioritySelectOptions = ({disabled}) => {
    const { filterOptions, setFilterOptions, page, setPage } = useContext(DirectorySearchBar);
    const { isLg } = useMediaQueries();

    const filterMenuOptions = [
        {
          name: "Licensed To Practice In",
          label: "Sort by State",
          type: "select",
          options: [
            { value: "All", label: "All" },
            { value: "Alabama", label: "Alabama" },
            { value: "Alaska", label: "Alaska" },
            { value: "Arizona", label: "Arizona" },
            { value: "Arkansas", label: "Arkansas" },
            { value: "California", label: "California" },
            { value: "Colorado", label: "Colorado" },
            { value: "Connecticut", label: "Connecticut" },
            { value: "Delaware", label: "Delaware" },
            { value: "Florida", label: "Florida" },
            { value: "Georgia", label: "Georgia" },
            { value: "Hawaii", label: "Hawaii" },
            { value: "Idaho", label: "Idaho" },
            { value: "Illinois", label: "Illinois" },
            { value: "Indiana", label: "Indiana" },
            { value: "Iowa", label: "Iowa" },
            { value: "Kansas", label: "Kansas" },
            { value: "Kentucky", label: "Kentucky" },
            { value: "Louisiana", label: "Louisiana" },
            { value: "Maine", label: "Maine" },
            { value: "Maryland", label: "Maryland" },
            { value: "Massachusetts", label: "Massachusetts" },
            { value: "Michigan", label: "Michigan" },
            { value: "Minnesota", label: "Minnesota" },
            { value: "Mississippi", label: "Mississippi" },
            { value: "Missouri", label: "Missouri" },
            { value: "Montana", label: "Montana" },
            { value: "Nebraska", label: "Nebraska" },
            { value: "Nevada", label: "Nevada" },
            { value: "New Hampshire", label: "New Hampshire" },
            { value: "New Jersey", label: "New Jersey" },
            { value: "New Mexico", label: "New Mexico" },
            { value: "New York", label: "New York" },
            { value: "North Carolina", label: "North Carolina" },
            { value: "North Dakota", label: "North Dakota" },
            { value: "Ohio", label: "Ohio" },
            { value: "Oklahoma", label: "Oklahoma" },
            { value: "Oregon", label: "Oregon" },
            { value: "Pennsylvania", label: "Pennsylvania" },
            { value: "Rhode Island", label: "Rhode Island" },
            { value: "South Carolina", label: "South Carolina" },
            { value: "South Dakota", label: "South Dakota" },
            { value: "Tennessee", label: "Tennessee" },
            { value: "Texas", label: "Texas" },
            { value: "Utah", label: "Utah" },
            { value: "Vermont", label: "Vermont" },
            { value: "Virginia", label: "Virginia" },
            { value: "Washington", label: "Washington" },
            { value: "West Virginia", label: "West Virginia" },
            { value: "Wisconsin", label: "Wisconsin" },
            { value: "Wyoming", label: "Wyoming" },
          ],
          multi: false,
        },
        // {
        //   name: "Specialties",
        //   label: "Sort by Specialty",
        //   type: "select",
        //   options: [
        //     { value: "All", label: "All" },
        //     { value: "Anxiety", label: "Anxiety" },
        //     { value: "PTSD", label: "PTSD" },
        //     { value: "Depression", label: "Depression" },
        //     { value: "OCD", label: "OCD" },
        //     { value: "Bi-polar", label: "Bi-polar" },
        //     { value: "Phobias", label: "Phobias" },
        //     { value: "Panic Disorders", label: "Panic Disorders" },
        //     { value: "Addictions", label: "Addictions" },
        //     { value: "Eating Disorders", label: "Eating Disorders" },
        //     { value: "Personality Disorders", label: "Personality Disorders" },
        //     { value: "Sexual Disorders", label: "Sexual Disorders" },
        //   ],
        //   multi: false,
        // }
    ]

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
                marginTop: "1rem",
            },
        },
    };

    const handleMultiSelectChange = (event, option) => {
        const { value } = event.target;
        const { name } = option;
        const updatedFilterOption = filterOptions;
    
        if (value.includes("All") || value.length === 0) {
          delete updatedFilterOption[name];
          setFilterOptions({ ...updatedFilterOption });
        } else if (!filterOptions[name]) {
          setFilterOptions({ ...filterOptions, [name]: value });
        } else if (filterOptions[name] !== value) {
          updatedFilterOption[name] = value;
          setFilterOptions({ ...updatedFilterOption });
        }
        setPage(1);
      };

    const SortOptionSelect = (option, index) => {
        return (
          <FormControl variant="outline" fullWidth sx={{ maxHeight: "200px" }}>
            <Select
              variant=""
              disabled={disabled}
              label={option.label}
              onChange={(e) => handleMultiSelectChange(e, option)}
              input={<OutlinedInput label="State" />}
              value={filterOptions[option.name] || []}
              sx={{ 
                height: "44px", 
                justifyContent: "center",
                backgroundColor: theme.palette.white.main, 
                borderRadius: isLg ? "0" : "0.5rem",
                borderTopLeftRadius: "0.5rem", 
                borderBottomLeftRadius: "0.5rem",
              }}
              displayEmpty
              multiple={option.multi}
              renderValue={(selected) => {
                if (!selected.length) {
                  return (
                    <Grid container columnGap={0} sx={{justifyContent: "center"}}>
                      <Grid item xs={11} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <MKTypography component="p" sx={{ textAlign: "center", fontSize: "14px", color: theme.palette.primary.main ,fontWeight: "bold" }}>
                          {option.name === "Licensed To Practice In" ? "State" : option.name}
                        </MKTypography>
                      </Grid>
                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ArrowDropDownIcon sx={{height: "2rem", width: "2rem", color: theme.palette.primary.main }}/>
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid container  sx={{justifyContent: "center"}}>
                      <Grid item xs={11} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <MKTypography sx={{ color: theme.palette.primary.main, fontSize: "1.0rem", fontWeight: 400 }}>
                          {Array.isArray(selected) ? selected.join(", ") : selected}
                        </MKTypography>
                      </Grid>
                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ArrowDropDownIcon sx={{height: "2rem", width: "2rem", color: theme.palette.primary.main }}/>
                      </Grid>
                    </Grid>
                  
                );
              }}
              MenuProps={menuProps}
            >
              {option.options.map((selectOption, index) => {
                return (
                  <MenuItem key={index} value={selectOption.value} name={selectOption.label} sx={{ color: theme.palette.primary.main }}>
                    {selectOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      };


      
      
    return (
        <Grid container spacing={0} sx={{ padding: "0rem", display: "flex" }}>
            {filterMenuOptions.map((option, index) => {
              return (
                <Grid item xs={12} md={12} lg={12} xl={12} key={index}>
                    {option.type === "select" ? SortOptionSelect(option, index) : null}
                </Grid>
              );
            })}
        </Grid>
    );
};

export default PrioritySelectOptions;


