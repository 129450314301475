export const fadeInVariants = {
  hidden: {
    opacity: 0,
    x: "-75px",
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      type: "spring",
      stiffness: 200,
      damping: 15,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      easeInOut: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
      stiffness: 80,
      mass: 1.5,
      type: "spring",
    },
  },
};

export const fadeInReverseVariants = {
  hidden: {
    opacity: 0,
    x: "75px",
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      type: "spring",
      stiffness: 200,
      damping: 15,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      easeInOut: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
      stiffness: 80,
      mass: 1.5,
      type: "spring",
    },
  },
};
