import React from "react";
import theme from "assets/theme";
// Components
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HeaderContainer from "styles/components/headerContainer";
import ListComponent from "components/CBList";
// Styled Components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { StyledShopBannerContainer } from "./index.styles";
// Animations
import { listVariants } from "styles/animations/staggeredList.animations";
import { listItemShopBannerVariants } from "styles/animations/staggeredList.animations";
import { headerContainerTitleShopBannerVariants } from "styles/animations/headerContainer.animations";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const ShopBanner = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledShopBannerContainer>
      <StyledSectionContainer cssProps={{ paddingInline: "0 !important", [theme.breakpoints.up("lg")]: { paddingInline: "2rem !important" } }}>
        <HeaderContainer
          motionProps={{
            variants: {
              child: headerContainerTitleShopBannerVariants,
            },
            animate: "show",
            initial: "hidden",
          }}
          component="h1"
          variant="h1"
          title="Choose The Right \n Ketamine Treatment For You"
          textColor={theme.palette.white.main}
          highlightText={["Ketamine", "Treatment", "For You"]}
          highlightColor={theme.palette.accent.main}
          cssProps={{ margin: "0 0 2rem 0 !important", width: "100%" }}
        />
        <ListComponent
          motionProps={{
            variants: {
              listItem: listItemShopBannerVariants,
              list: listVariants,
              animate: "show",
              initial: "hidden",
            },
          }}
          listVariant={isLg ? "body1" : "body2"}
          icon={
            <CheckCircleOutlineIcon
              sx={{ color: theme.palette.accent.main, height: { xs: 18, md: 24, lg: 28 }, width: { xs: 18, md: 24, lg: 28 } }}
            />
          }
          listItems={["Affordable Monthly Payments", "Personalized Approach", "Flexible Scheduling"]}
          color={theme.palette.white.main}
        />
      </StyledSectionContainer>
    </StyledShopBannerContainer>
  );
};

export default ShopBanner;
