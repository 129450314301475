import styled from "@emotion/styled";
import { Alert, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";

export const StyledErrorAlertInvalidPromo = styled(Alert)(({ isCheckoutPage }) => ({
  marginBottom: "1rem",
  marginTop: isCheckoutPage ? "1rem" : "0",
  fontSize: "14px",
}));

export const StyledPromoCodeInputContainer = styled(Stack)(({ isCheckoutPage }) => ({
  width: "100%",
  marginTop: isCheckoutPage ? "1.5rem" : "0",
}));

export const StyledPromoCodeTypography = styled(MKTypography)(({ isCheckoutPage }) => ({
  marginTop: "1rem",
  textAlign: "center",
  fontWeight: "700 !important",
  marginBottom: "1rem",
  color: isCheckoutPage ? "white !important" : "inherit",
}));
export const inputStyles = {
  input: {
    color: "black !important",
  },
};
export const inputStylesCheckout = {
  input: {
    color: "white !important",
  },
};
