// Major
import React, { useState } from "react";

// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import EditForm from "../EditForm";
import CBLoading from "components/CBLoading";
import MKBox from "components/MKBox";

// Styles
import theme from "assets/theme";
import { Stack } from "@mui/material";
import { StyledProfileCardImage, StyledHeaderContainer, StyledEllipsisTypographyLink } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { convertState } from "utils/helpers/stateConverter";
import { formatPhoneNumber } from "utils/helpers/reformatPhone";
import useMediaQueries from "utils/mediaQueries.utils";
import usePutTherapistByID from "utils/hooks/usePutTherapistByID";
import useImageUpload from "pages/PartnerProspectSurvey/components/newSurvey/hooks/useUploadImage.hook";
import filterBadWords from "utils/helpers/filterBadWords";

// Icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TAIconImage from "components/TAIconImage";
import LanguageIcon from "@mui/icons-material/Language";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WorkIcon from "@mui/icons-material/Work";

const HeaderEdit = ({ userData, handleUpdateUserData, handleEmailClick }) => {
  const { isLg, isSmUp, isMd } = useMediaQueries();
  const { email, phone, state, city, name, customFields, tags, message } = userData || [];
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const yearsPracticed = findCustomFieldValue("Years Practiced", customFields);
  const linkedIn = findCustomFieldValue("LinkedIn", customFields);
  const specialties = findCustomFieldValue("Specialties", customFields);
  const [showForm, setShowForm] = useState(false);
  const { updateContactInfo, data, isLoading, isError, error } = usePutTherapistByID();
  const { uploadImage } = useImageUpload();
  const [imageUploading, setImageUploading] = useState(false);
  const [showWhatIsThis, setShowWhatIsThis] = useState(false);
  // console.log("userData", userData);

  
  const handlePhoneClick = () => {
    if (phone) {
      window.location.href = `tel:+${phone}`;
    }
  };
  const buttonInfo = [
    
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/envelope.webp",
      alt: "Email Icon",
      size: "sm",
    },
  ];

  // Mappping through the buttonInfo array to render the buttons
  const ButtonOptions = () => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ width: "100%", maxWidth: isLg ? "200px" : "100%" }}>
        {buttonInfo.map((button, index) => {
          return (
            <MKButton
              startIcon={<TAIconImage alt={button.alt} size={button.size} iconURL={button.iconURL} />}
              onClick={handleEmailClick}
              disabled={index === 0 ? !phone : !email}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width:"100%"}}
            >
              Contact Me
            </MKButton>
          );
        })}
      </Stack>
    );
  };


  const PartnerTag = () => {
    return(
      <><img
            src={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
            style={{ height: "24px", width: "24px", margin:"0 0.5rem 0 0" }}
            height="0"
            width="0"
            alt="Almadelic butterfly logo"
          /><span style={{color: `${theme.palette.secondary.main}`}}>Partner</span></>
    )
  }

  const iconStyles = {
    width: "24px",
    height: "24px",
    color: tags?.includes("partners education complete") ? theme.palette.secondary.main : theme.palette.accent.main,
  };

  const activeColor = tags?.includes("partners education complete") ? theme.palette.secondary.main : theme.palette.accent.main;

  const HeaderBody = () => {
    

    return (
      <StyledHeaderContainer
        sx={{
          flexDirection: isSmUp ? "row" : "column",
          flexWrap: isMd ? "no-wrap" : "wrap",
          paddingLeft: isSmUp ? "4rem" : "2rem",
          paddingRight: isSmUp ? "4rem" : "2rem",
          paddingBottom: isLg ? "2rem" : "3rem",
        }}
      >
        <StyledProfileCardImage
          component="img"
          src={profileURL || "https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparentNew.webp"}
          alt={`${name} profile image`}
          activeColor={activeColor}
          sx={{ margin: isLg ? "0" : "auto"}}
        />
        <Stack sx={{ flexGrow: 1, maxWidth: isMd ? "50%" : "100%", alignItems: isLg || isMd ? "" : "center" }} spacing={1.0}>
          <MKTypography sx={{ color: `${theme.palette.light.main} !important`,alignItems: "center", display:"flex" }} component="h1" variant="h6" gutterBottom>
            {name} &nbsp;{tags?.includes("partners education complete") ? <PartnerTag /> : null}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }} gutterBottom>
            {specialties?.join(", ")}
          </MKTypography>
          {yearsPracticed && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              {/* <TAIconImage alt="Star Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/star.webp"} /> */}
              <WorkIcon sx={iconStyles} />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {yearsPracticed} Years Exp
              </MKTypography>
            </Stack>
          )}
          <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
            {/* <TAIconImage alt="Pin Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/map-marker.webp"} /> */}
            <FmdGoodIcon sx={iconStyles} />
            <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
              {city && `${city.charAt(0).toUpperCase() + city.slice(1)}, `}

              {convertState(state)}
            </MKTypography>
          </Stack>
          {websiteURL && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              {/* <TAIconImage alt="World Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/earth-globe.webp"} /> */}
              <LanguageIcon sx={iconStyles} />
              <StyledEllipsisTypographyLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: activeColor }}>
                {websiteURL}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
          {linkedIn && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <LinkedInIcon
                style={iconStyles}
              />
              <StyledEllipsisTypographyLink target="_blank" href={linkedIn} sx={{ fontSize: "14px", color: activeColor }}>
                {linkedIn}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
        </Stack>
        <ButtonOptions />
        <EditButton showForm={showForm} setShowForm={setShowForm} />
      </StyledHeaderContainer>
    );
  };

  const questions = [
    {
      name: "name",
      answer: name ? name : "",
      placeholder: "Name",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "city",
      answer: city ? city : "",
      placeholder: "City",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "state",
      answer: state ? state : "",
      placeholder: "State",
      type: "select",
      variant: "outlined",
      rows: 1,
      options: [
        { value: "Alabama", label: "Alabama" },
        { value: "Alaska", label: "Alaska" },
        { value: "Arizona", label: "Arizona" },
        { value: "Arkansas", label: "Arkansas" },
        { value: "California", label: "California" },
        { value: "Colorado", label: "Colorado" },
        { value: "Connecticut", label: "Connecticut" },
        { value: "Delaware", label: "Delaware" },
        { value: "Florida", label: "Florida" },
        { value: "Georgia", label: "Georgia" },
        { value: "Hawaii", label: "Hawaii" },
        { value: "Idaho", label: "Idaho" },
        { value: "Illinois", label: "Illinois" },
        { value: "Indiana", label: "Indiana" },
        { value: "Iowa", label: "Iowa" },
        { value: "Kansas", label: "Kansas" },
        { value: "Kentucky", label: "Kentucky" },
        { value: "Louisiana", label: "Louisiana" },
        { value: "Maine", label: "Maine" },
        { value: "Maryland", label: "Maryland" },
        { value: "Massachusetts", label: "Massachusetts" },
        { value: "Michigan", label: "Michigan" },
        { value: "Minnesota", label: "Minnesota" },
        { value: "Mississippi", label: "Mississippi" },
        { value: "Missouri", label: "Missouri" },
        { value: "Montana", label: "Montana" },
        { value: "Nebraska", label: "Nebraska" },
        { value: "Nevada", label: "Nevada" },
        { value: "New Hampshire", label: "New Hampshire" },
        { value: "New Jersey", label: "New Jersey" },
        { value: "New Mexico", label: "New Mexico" },
        { value: "New York", label: "New York" },
        { value: "North Carolina", label: "North Carolina" },
        { value: "North Dakota", label: "North Dakota" },
        { value: "Ohio", label: "Ohio" },
        { value: "Oklahoma", label: "Oklahoma" },
        { value: "Oregon", label: "Oregon" },
        { value: "Pennsylvania", label: "Pennsylvania" },
        { value: "Rhode Island", label: "Rhode Island" },
        { value: "South Carolina", label: "South Carolina" },
        { value: "South Dakota", label: "South Dakota" },
        { value: "Tennessee", label: "Tennessee" },
        { value: "Texas", label: "Texas" },
        { value: "Utah", label: "Utah" },
        { value: "Vermont", label: "Vermont" },
        { value: "Virginia", label: "Virginia" },
        { value: "Washington", label: "Washington" },
        { value: "West Virginia", label: "West Virginia" },
        { value: "Wisconsin", label: "Wisconsin" },
        { value: "Wyoming", label: "Wyoming" },
      ],
    },
    {
      name: "phone",
      answer: phone,
      placeholder: "Phone",
      type: "tel",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "LinkedIn",
      answer: linkedIn ? linkedIn : "",
      placeholder: "LinkedIn",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Website",
      answer: websiteURL ? websiteURL : "",
      placeholder: "Website",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Years Practiced",
      answer: yearsPracticed,
      placeholder: "Years Practiced",
      type: "number",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Profile Image",
      answer: profileURL,
      placeholder: "Profile Image",
      type: "file",
      variant: "outlined",
      rows: 1,
    },
  ];

  const EditButton = ({ showForm, setShowForm }) => {
    return (
      <MKButton color="secondary" size="small" sx={{ position: "absolute", top: "1.5rem", right: "1.5rem" }} onClick={() => setShowForm(!showForm)}>
        {showForm ? <EditOffIcon sx={{ height: "24px", width: "24px" }} /> : <EditIcon sx={{ height: "24px", width: "24px" }} />}
      </MKButton>
    );
  };

  const handleSubmit = async (values) => {
    let updatedImage = false;
    let imageUrl = "";
    const fileName = values["name"].replace(/\s+/g, "_").toLowerCase() + "_profile_image.jpg";
    
    // uploading image if it exists
    if (values["profile cropped image"]) {
      setImageUploading(true);
      const uploadResponse = await uploadImage(values["profile cropped image"], fileName);
      if (!uploadResponse) {
        throw new Error("Image upload failed");
      }
      imageUrl = uploadResponse;
      updatedImage = true;
    } else {
      imageUrl = profileURL;
    }
    setImageUploading(false);

    const payload = {
      ...values,
      "Profile URL": imageUrl,
    };

    // filtering out bad words
    const filterPayload = (payload) => {
      const filteredPayload = { ...payload };
      const keysToFilter = ["city", "name"];

      Object.keys(filteredPayload).forEach((key) => {
        if (typeof filteredPayload[key] === "string" && keysToFilter.includes(key)) {
          filteredPayload[key] = filterBadWords(filteredPayload[key]);
        }
      });

      return filteredPayload;
    };

    const filteredPayload = filterPayload(payload);

    const response = await updateContactInfo(filteredPayload, userData.id);
    if (response) {
      handleUpdateUserData(response);
    }
    if (updatedImage) {
      alert("Profile Updated Successfully, Profile Image may take a few minutes to update");
    }
  };

  const CompleteProfileCheck = () => {
    
    const completed = tags?.includes("therapist profile completed");
    return (
        <MKBox onClick={() => handleWhatIsThis()} sx={{
            backgroundColor: completed ? theme.palette.accent.main : theme.palette.secondary.main,
            cursor: "pointer",
            padding: "1rem 2rem 0.5rem",
        }}>
            <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom >
                <b>Complete Profile:</b> {completed ? "Yes  " : "No  "}
                <span style={{color: theme.palette.primary.main, textDecoration: "underline"}} >(What's this?)</span>
                {message?.length > 0 ? <span style={{color: theme.palette.primary.main, textDecoration: "underline", marginLeft: "1rem"}}>  {message}</span> : null}
            </MKTypography>
            {showWhatIsThis ? <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{maxWidth:"80%"}} gutterBottom>
                This is a status showing how complete your profile is. IF it says "No" you may be missing some important information and you profile will not appear for clients to find. Please fill out all the fields to ensure your profile is complete.
            </MKTypography> : null}
        </MKBox>
    )
}

const handleWhatIsThis = () => {
    setShowWhatIsThis(!showWhatIsThis);
}

const BecomeAPartner = () => {
  return (
    <MKBox sx={{
      justifyContent: "center",
      display: "flex",
    }}>
    <MKTypography 
      component="p" 
      variant="body1"
      onClick={() => window.open("https://partners.almadelic.com/therapist-education-get-started", "_blank")} 
      sx={{
        width: "60%",
        color: theme.palette.secondary.main,
        textDecoration: "underline",
        cursor: "pointer",
        textAlign: "center",
      }} 
      gutterBottom>
      Become a Partner Today! Learn more here
    </MKTypography>
    </MKBox>
  )
}

  return (
    <>
      {isLoading || imageUploading ? (
        <CBLoading />
      ) : (
        <>
          <StyledSectionBackgroundWrapper id="therapist-bio-header">
            <CompleteProfileCheck />
            <HeaderBody />
            {!tags?.includes("partners education complete") && <BecomeAPartner />}
          </StyledSectionBackgroundWrapper>
          {showForm && <EditForm questions={questions} handleSubmit={handleSubmit} />}
        </>
      )}
    </>
  );
};

export default HeaderEdit;
