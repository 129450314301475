import React from "react";
import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import rgba from "assets/theme/functions/rgba";
import useMediaQueries from "utils/mediaQueries.utils";

const secondaryCartDetailsTagsStyles = {
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[600],
  paddingInline: ".5rem",
  paddingBlock: "0.25rem",
  borderRadius: "12px",
  fontSize: "12px",
};
const primaryCartDetailsTagsStyles = {
  backgroundColor: rgba(theme.palette.accent.main, 0.8),
  color: "#fff",
  paddingInline: "1rem",
  paddingBlock: "0.25rem",
  borderRadius: "12px",
};
const ItemizedCartItems = ({ cartItems, textColor }) => {
  const { isMd } = useMediaQueries();
  return (
    <>
      {cartItems.length !== 0 && (
        <>
          <MKTypography
            component="p"
            variant="h6"
            sx={{ fontSize: "16px !important", color: textColor === "accent" && `${theme.palette.accent.main} !important`, marginBottom: "1rem" }}
          >
            Cart Details
          </MKTypography>
          <Stack direction={"column"} justifyContent={"space-between"} alignItems="center" spacing={2} sx={{ marginBottom: "1rem" }}>
            {cartItems.map((item) => (
              <Stack
                direction={isMd ? "row" : "column"}
                alignItems={isMd ? "center" : "flex-start"}
                justifyContent="space-between"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Stack direction={"row"} alignItems={isMd ? "center" : "flex-start"} spacing={2}>
                  {/* sx={primaryCartDetailsTagsStyles} */}
                  <MKTypography
                    component="p"
                    variant="body2"
                    sx={{ fontWeight: "bold", overflow: "auto", whiteSpace: "nowrap", maxWidth: { xs: "170px", sm: "100%" } }}
                  >
                    {item.name}
                  </MKTypography>
                  <MKTypography component="p" variant="body2" sx={secondaryCartDetailsTagsStyles}>
                    {item.metadata?.type === "package" ? "Subscription Required" : "One Time Payment"}
                  </MKTypography>
                </Stack>
                <MKTypography component="small" variant="body2">
                  {item.metadata.priceValue}
                </MKTypography>
              </Stack>
            ))}
          </Stack>
          <CBDivider />
        </>
      )}
    </>
  );
};

export default ItemizedCartItems;
