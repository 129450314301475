import React from "react";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import {
  StyledBlogResultSectionContainer,
  StyledBlogResultInnerGrid,
  StyledBlogResultOuterCardBox,
  StyledBlogResultBottomOuterCardBox,
  StyledBlogResultBottomInnerCardBox,
  StyledBlogResultAvatarBox,
} from "./index.style";
import { Link } from "react-router-dom";
import { EllipsisHeaderTypography } from "pages/ScienceAndResearch/components/ClinicalResearch/index.styles";
import { EllipsisBodyTextTypography } from "pages/ScienceAndResearch/components/ClinicalResearch/index.styles";
import useMediaQueries from "utils/mediaQueries.utils";
import BlogSchema from "../blogSchema";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";

const Result = ({ filteredData }) => {
  const { isMd, isLg } = useMediaQueries();
  return (
    <StyledBlogResultSectionContainer component={"section"} id={"blog-result-section"}>
      <Grid container spacing={2}>
        {filteredData.map((item, i) => (
          <Grid item xs={12} md={6} lg={4} key={i}>
            <StyledBlogResultInnerGrid component={Link} to={`/blog/${formatTitleForURL(item.shortTitle)}`} state={{ blogId: item.id }}>
              <StyledBlogResultOuterCardBox component={Card} width={1} height={1}>
                <CardMedia
                  // image={item.image}
                  image={item.imageMobile ? item.imageMobile : item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 400, sm: 450, md: 350 },
                    position: "relative",
                    margin: ".75rem",
                    // backgroundSize: "contain",
                  }}
                ></CardMedia>
                <Box component={CardContent} position={"relative"} mt={4}>
                  <EllipsisHeaderTypography variant={"h6"} component="h2" gutterBottom>
                    {item.title}
                  </EllipsisHeaderTypography>
                  <EllipsisBodyTextTypography component="p" variant={isLg ? "body1" : "body2"} color="text.secondary">
                    {item.description}
                  </EllipsisBodyTextTypography>
                </Box>
                <Box flexGrow={1} />
                <StyledBlogResultBottomOuterCardBox>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <StyledBlogResultBottomInnerCardBox>
                    <StyledBlogResultAvatarBox>
                      <Avatar src={item.author.avatar} alt={"Image of Author"} sx={{ marginRight: 1 }} />
                      <MKTypography color={"text.secondary"}>{item.author.name}</MKTypography>
                    </StyledBlogResultAvatarBox>
                    <MKTypography color={"text.secondary"}>{item.date}</MKTypography>
                  </StyledBlogResultBottomInnerCardBox>
                </StyledBlogResultBottomOuterCardBox>
              </StyledBlogResultOuterCardBox>
            </StyledBlogResultInnerGrid>
            <BlogSchema
              title={item.title}
              authorName={item.author.name}
              datePublished={item.date}
              dateModified={item.date}
              imageUrl={item.image}
              articleBody={item.content.join(" ")}
              publisherName="Almadelic Medical Group"
              resources={item.resources}
              publisherLogo="https://d3a0rs3velfrz.cloudfront.net/logos/mainLogo.svg"
              breadcrumbs={[
                { name: "Home", url: "https://www.almadelic.com/" },
                { name: "Blog", url: "https://www.almadelic.com/blog" },
                { name: item.title, url: `/read-blog?title=${formatTitleForURL(item.title)}&id=${item.id}` },
              ]}
            />
          </Grid>
        ))}
      </Grid>
    </StyledBlogResultSectionContainer>
  );
};

export default Result;
/* Load more button */
/* <Grid item container justifyContent={"center"} xs={12}>
  <MKButton
    fullWidth
    variant={"contained"}
    color={"secondary"}
    sx={{ maxWidth: "200px", fontSize: "0.875rem" }}
    endIcon={
      <Box component={"svg"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={24} height={24}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </Box>
    }
  >
    Load more
  </MKButton>
</Grid> */
